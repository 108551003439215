class FeatureFlags {
    static REACT_APP_INDUSTRIES_FEATURE_FLAG_DISABLED =
        "REACT_APP_INDUSTRIES_FEATURE_FLAG_DISABLED";

    static getFeatureFlag(flag) {
        const featureFlag = process.env[flag];
        return featureFlag === "true" ? true : false;
    }

    static getIndustriesFF() {
        return this.getFeatureFlag(
            this.REACT_APP_INDUSTRIES_FEATURE_FLAG_DISABLED
        );
    }
}

export { FeatureFlags };
