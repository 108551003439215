import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const companiesSearchFiltersDataSlice = createSlice({
    name: "companiesSearchFilters",
    initialState,
    reducers: {
        addCompaniesSearchFilters: (_state, action) => {
            return {
                ...action.payload,
            };
        },
        clearCompaniesSearchFilters: () => {
            return {};
        },
    },
});

export const { addCompaniesSearchFilters, clearCompaniesSearchFilters } =
    companiesSearchFiltersDataSlice.actions;
export default companiesSearchFiltersDataSlice.reducer;
