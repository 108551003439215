import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GeneralUtilityService from "services/GeneralUtilityService";
import { Stack, Typography } from "@mui/material";

export default function AccessToTalentCreditsTable({ rows }) {
    const creditHistoryRows = rows;

    return (
        <TableContainer component={Paper} sx={{ width: "50%" }}>
            <Stack
                p={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%", height: "75px" }}
            >
                <Typography variant="h6" fontWeight="bold" component="div">
                    Access To Talent Credit History
                </Typography>
            </Stack>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell>Create date</TableCell>
                        <TableCell>Actor</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {creditHistoryRows?.map((row, index) => (
                        <TableRow
                            key={`${row.id}/${index}`}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row?.value}
                            </TableCell>
                            <TableCell>
                                {GeneralUtilityService.formattedDate(
                                    row?.createdAt
                                )}
                            </TableCell>
                            <TableCell>{row?.actor?.email || "n/a"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
