import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
    Typography,
    Button,
    Stack,
    Autocomplete,
    TextField,
} from "@mui/material";
import { addCompaniesSearchFilters } from "reducers/company/companiesSearchFilters";

export const CompaniesFilters = () => {
    const { control, handleSubmit, register, reset } = useForm();

    const dispatch = useDispatch();

    const onSubmit = (filterData) => {
        dispatch(addCompaniesSearchFilters(filterData));
    };

    const handleDynamicSubmitForm = (event) => {
        event.preventDefault();
        handleSubmit(onSubmit)();
        event.target.blur();
        if (document.activeElement) document.activeElement.blur();
    };

    const subscriptionOptions = [
        { name: "Active", id: true },
        { name: "Inactive", id: false },
    ];

    return (
        <form onSubmit={handleDynamicSubmitForm}>
            <Stack
                direction="column"
                px={3}
                py={3}
                spacing={2}
                sx={{ position: "relative" }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={6}
                >
                    <Typography variant="header1" component="h3">
                        Filters
                    </Typography>
                    <Button
                        sx={{
                            textTransform: "none",
                            padding: 0,
                        }}
                        variant="text"
                        onClick={(event) => {
                            reset();
                            handleDynamicSubmitForm(event);
                        }}
                    >
                        Clear all
                    </Button>
                    <Button sx={{ display: "none" }} type="submit"></Button>
                </Stack>

                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <TextField
                            {...register("name")}
                            name="name"
                            id="name"
                            label="Company name"
                            onBlur={(event) => handleDynamicSubmitForm(event)}
                            variant="standard"
                        />
                        <Controller
                            name="subscription"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    id="subscription"
                                    filterSelectedOptions
                                    options={subscriptionOptions}
                                    getOptionLabel={(option) =>
                                        option.name || ""
                                    }
                                    value={field.value}
                                    isOptionEqualToValue={(option, value) =>
                                        option?.id === value?.id
                                    }
                                    {...field}
                                    onChange={(event, newValue) => {
                                        field.onChange(newValue);
                                        handleDynamicSubmitForm(event);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Subscription"
                                            variant="standard"
                                        />
                                    )}
                                />
                            )}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </form>
    );
};

export default CompaniesFilters;
