import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isHiddenInfoVisible: false,
};

const hiddenInfoSlice = createSlice({
    name: "hiddenInfoVisivility",
    initialState,
    reducers: {
        toggleHiddenInfo: (state) => {
            state.isHiddenInfoVisible = !state.isHiddenInfoVisible;
        },
    },
});

export const { toggleHiddenInfo } = hiddenInfoSlice.actions;
export default hiddenInfoSlice.reducer;
