import React from "react";
import { Provider } from "react-redux";
import "./assets/scss/main.scss";
import { AppRouter } from "./router";
import store from "./store/store";
import { SnackbarProvider } from "notistack";

const App = () => {
    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <Provider store={store}>
                <AppRouter />
            </Provider>
        </SnackbarProvider>
    );
};

export default App;
