import SvgIcon from "@mui/material/SvgIcon";

const PlugOutlineIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 20 20">
            <path
                d="M3.25004 16.75C2.0074 15.5073 2.0074 13.4926 3.25004 12.25L5.50002 10L9.99999 14.5L7.75001 16.75C6.50738 17.9926 4.49267 17.9926 3.25004 16.75ZM3.25004 16.75L1 19M16.75 3.25004C17.9926 4.49268 17.9926 6.50738 16.75 7.75001L14.5 10L10 5.50003L12.25 3.25004C13.4926 2.00741 15.5073 2.00741 16.75 3.25004ZM16.75 3.25004L19 1M11.125 11.1249L8.87498 13.3749M8.87503 8.87513L6.62504 11.1251"
                stroke="currentColor"
                fill="none"
                strokeWidth="1.3"
            />
        </SvgIcon>
    );
};

export default PlugOutlineIcon;
