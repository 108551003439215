import { createSlice } from "@reduxjs/toolkit";
import { UUIDService } from "infra/utils";
const currentCandidateSlice = createSlice({
    name: "currentCandidate",
    initialState: null,
    reducers: {
        currentCandidate: (state, action) => {
            return { ...state, ...action.payload, version: UUIDService.create() };
        },
        setCurrentCandidateHistory: (state, action) => {
            return { ...state, history: [...action.payload] };
        },
        updateCandidateVersion: (state) => {
            return { ...state, version: UUIDService.create() };
        },
    },
});

export const { currentCandidate, setCurrentCandidateHistory, updateCandidateVersion } = currentCandidateSlice.actions;
export default currentCandidateSlice.reducer;
