import { useEffect } from "react";
import { Stack } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import View from "../candidate-view/View";
import { useCandidate } from "hooks/useCandidate";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconButton, Box } from "@mui/material/";
import ClearIcon from "@mui/icons-material/Clear";

const CandidateDrawer = ({ drawerHandler }) => {
    const { isDrawerOpen, setIsDrawerOpen, mountCandidateIdToDrawer } =
        drawerHandler;
    const { setCandidateToState, isLoading } = useCandidate();
    const navigate = useNavigate();

    useEffect(() => {
        if (mountCandidateIdToDrawer)
            setCandidateToState(mountCandidateIdToDrawer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mountCandidateIdToDrawer]);

    return (
        <div>
            <Drawer
                anchor="right"
                ModalProps={{
                    disableEscapeKeyDown: true,
                }}
                open={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                    navigate({
                        hash: "",
                    });
                }}
                PaperProps={{
                    sx: {
                        width: "clamp(70vw, 1500px, 80vw)",
                    },
                }}
            >
                <Box sx={{ position: "absolute", right: "0" }}>
                    <IconButton
                        aria-label="Clear"
                        onClick={() => {
                            setIsDrawerOpen(false);
                        }}
                    >
                        <ClearIcon color="grey" fontSize="14px" />
                    </IconButton>
                </Box>
                {isLoading ? (
                    <Stack
                        sx={{
                            height: "100%",
                        }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress
                            color="gray"
                            size={100}
                            thickness={2}
                        />
                    </Stack>
                ) : (
                    <View candidateId={mountCandidateIdToDrawer} isInDrawer />
                )}
            </Drawer>
        </div>
    );
};

export default CandidateDrawer;
