import axios from "utils/axiosConfig";
import { EnvService } from "infra/envs";

class ActionsApi {
    async tokenAuthRequest() {
        let tokenAuthURL = EnvService.getBackEndBaseUrl() + "/api/user";
        try {
            let response = await axios.get(tokenAuthURL);
            return response;
        } catch (error) {
            console.log(error);
        }
    }
}

const actionsApiInstance = new ActionsApi();

export default actionsApiInstance;
