import axios from "utils/axiosConfig";
import { useState } from "react";
import { EnvService } from "infra/envs";
import useNotifications from "./useNotifications";

export const useInsertDepartment = () => {
    const [isLoadingInsertDepartment, setIsLoadingInsertDepartment] =
        useState(false);

    const [
        insertDepartmentRequestSuccessful,
        setInsertDepartmentRequestSuccessful,
    ] = useState(false);

    const { renderNotification, renderErrorNotification } = useNotifications();

    const insertDepartment = async (data) => {
        const endpoint = EnvService.getBackEndBaseUrl() + "/api/departments";
        const departmentData = {
            name: data?.new_department,
        };
        try {
            const response = await axios.post(endpoint, departmentData);
            const newDepartmentId = response?.data?.id;
            setInsertDepartmentRequestSuccessful(true);
            renderNotification("Department inserted successfully");
            setIsLoadingInsertDepartment(false);
            return newDepartmentId;
        } catch (error) {
            setInsertDepartmentRequestSuccessful(false);
            renderErrorNotification(`Error updating department: ${error}`);
            console.error("Error updating department:", error);
            setIsLoadingInsertDepartment(false);
            throw new Error("Error updating department");
        }
    };
    return {
        insertDepartment,
        isLoadingInsertDepartment,
        insertDepartmentRequestSuccessful,
    };
};
export default useInsertDepartment;
