import { createSlice } from "@reduxjs/toolkit";

const companySlice = createSlice({
    name: "company",
    initialState: null,
    reducers: {
        currentCompany: (_state, action) => {
            return action.payload;
        },
    },
});

export const { currentCompany } = companySlice.actions;
export default companySlice.reducer;
