import axios from "utils/axiosConfig";
import { useState } from "react";
import urlService from "infra/envs/URL.service";

const getCompaniesByNamePath = urlService.GET_COMPANIES_PATH;

export const useFetchCompanies = () => {
    const [fetching, setFetching] = useState(false);
    const [companiesOptions, setCompaniesOptions] = useState([]);

    const fetchCompaniesByName = async (name) => {
        setFetching(true);
        try {
            const config = {
                params: {
                    name,
                },
            };
            const response = await axios.get(getCompaniesByNamePath, config);
            const data = response.data;
            const options = Array.isArray(response.data) ? data : [];
            setCompaniesOptions(options);
            return data;
        } catch (error) {
            console.error(error);
        } finally {
            setFetching(false);
        }
    };

    return {
        fetching,
        companiesOptions,
        fetchCompaniesByName,
    };
};

export default useFetchCompanies;
