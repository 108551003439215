import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export const ParserHiddenInput = ({
    value,
    fieldName,
    disabled = true,
    formControls: { control }
}) => {
    return (
        <Controller
            name={fieldName}
            control={control}
            defaultValue={value}
            render={({ field }) => (
                <TextField
                    {...field}
                    autoFocus
                    fullWidth
                    disabled={disabled}
                    type="hidden"
                    style={{ display: "none" }}
                />
            )}
        />
    );
};

export default ParserHiddenInput;
