import IconBtn from "../../components/lib-ui/IconBtn";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

/**
 * A reusable dialog box component.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.isDialogOpen - Whether the dialog box is open.
 * @param {Function} props.setIsDialogOpen - Function to set the dialog box open state.
 * @param {boolean} [props.floatCancel=false] - Whether to show a floating cancel button.
 * @param {boolean} [props.showActionButtons=true] - Whether to show action buttons (cancel and save changes).
 * @param {string} props.title - Dialog box title.
 * @param {string} props.contentText - Content text to display in the dialog box.
 * @param {Function} props.onSubmit - Function to handle form submission.
 * @param {React.ReactNode} props.children - Dialog box content.
 * @param {Function} [props.onClose] - Optional function to handle closing the dialog box.
 */
export const DialogBox = ({
    isDialogOpen,
    setIsDialogOpen,
    floatCancel = false,
    showActionButtons = true,
    title,
    contentText,
    onSubmit: submitForm,
    children,
    onClose: parentOnClose,
}) => {
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") return;
        if (parentOnClose) parentOnClose();
        setIsDialogOpen(false);
    };

    const handleSubmit = (event) => {
        submitForm(event);
        setIsDialogOpen(false);
    };

    return (
        <Dialog
            fullWidth
            disableEscapeKeyDown
            open={isDialogOpen || false}
            onClose={handleClose}
        >
            {title ? (
                <DialogTitle
                    sx={{ border: "1px solid", borderColor: "gray.soft" }}
                >
                    <Typography variant="header2">{title} </Typography>
                </DialogTitle>
            ) : null}

            <DialogContent>
                {contentText ? (
                    <DialogContentText>{contentText}</DialogContentText>
                ) : null}
                {children}
            </DialogContent>

            {showActionButtons ? (
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Save changes</Button>
                </DialogActions>
            ) : null}
            {floatCancel ? (
                <IconBtn
                    defaulted={true}
                    sx={{
                        position: "absolute",
                        top: 3,
                        right: 3,
                        border: "unset",
                    }}
                    icon={CloseOutlinedIcon}
                    onClick={handleClose}
                    iconStyles={{ fontSize: "1.4rem" }}
                />
            ) : null}
        </Dialog>
    );
};

export default DialogBox;
