import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import getErrorMessage from "assets/data/errors.json";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import {
    Box,
    Typography,
    Autocomplete,
    MenuItem,
    Switch,
    InputAdornment,
} from "@mui/material";
import HorizontalFlex from "../../../components/lib-ui/HorizontalFlex";
import { useForm, Controller, useWatch } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import { GlobalAppDataStorageService } from "infra/storage";
import { useUpdateCandidateById } from "hooks/useUpdateCandidateById";
import SocialMediaURL from "services/SocialMediaURL.service";
import GeneralUtilityService from "services/GeneralUtilityService";
import useFetchCities from "hooks/useFetchCities";
import { useSelector, useDispatch } from "react-redux";
import { resetCitiesOptions } from "reducers/candidate/citiesSlice";
import AccessToTalentLabel from "components/lib-ui/AccessToTalentLabel";

export const TopSectionForm = ({ title, onSubmit }) => {
    const candidate = useSelector((state) => state.currentCandidate);
    const cityOptions = useSelector((state) => state.citiesSlice);
    const dispatch = useDispatch();
    const defermentDate = candidate?.military?.deferment || null;

    const defermentToMonth = defermentDate
        ? GeneralUtilityService.convertDateToMonthInput(new Date(defermentDate))
        : "";

    const {
        handleSubmit,
        control,
        register,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            defer_until: defermentToMonth,
            access_to_talent_availability:
                candidate?.accessToTalent.availability,
        },
    });

    const startAdornment = (
        <InputAdornment position="start">
            <AccessToTalentLabel />
        </InputAdornment>
    );

    const obligation = [
        { id: 0, name: "Fulfilled" },
        { id: 1, name: "Pending" },
        { id: "", name: "Not available" }, // Empty option
    ];

    const hasMilitaryObligations = useWatch({
        control,
        name: "military_obligations",
        defaultValue: candidate?.militaryObligation,
    });

    const countryOptions = GlobalAppDataStorageService.getCountries();
    const callingCodeOptions = GlobalAppDataStorageService.getCallingCodes();

    const { updateCandidateById, isLoading } = useUpdateCandidateById();
    const { fetchCities, citiesFetching } = useFetchCities();

    const selectedCountry = watch("country_id");

    const hasCountry =
        !GeneralUtilityService.isObjectEmpty(selectedCountry) ||
        candidate?.country;

    const submitForm = async (data) => {
        const defer_until_to_month =
            GeneralUtilityService.convertMonthInputToDate(data.defer_until);

        const updateData = {
            ...data,
            linkedin: SocialMediaURL.extractLinkedinId(data?.linkedin),
            collegelink_id: SocialMediaURL.extractCollegelinkId(
                data?.collegelink_id
            ),
            country_id: data?.country_id?.id,
            city_id: data?.city_id?.id,
            calling_code_id: data?.calling_code_id?.id,
            defer_until: defer_until_to_month,
        };

        await updateCandidateById(candidate.id, updateData);
        onSubmit();
    };

    const composeLinkedInURL = `${SocialMediaURL.LINKEDIN_PROFILE_BASE_URL}/${candidate?.linkedin}`;
    const composeCollegeLinkURL = `${SocialMediaURL.COLLEGELINK_PROFILE_BASE_URL}${candidate?.collegelink}`;

    const debouncedFetchCitiesOptions = GeneralUtilityService.debounce(
        async (countryID, q) => {
            await fetchCities(countryID, q);
        },
        1000
    );

    const handleInputChange = (event, value, reason) => {
        if (reason === "input") {
            const selectedCountryId = selectedCountry?.id;
            debouncedFetchCitiesOptions(selectedCountryId, value);
        }
    };

    const defaultMilitaryStatus = () => {
        if (candidate?.military?.obligation === true) {
            return 1;
        } else if (candidate?.military?.obligation === false) {
            return 0;
        } else {
            return "";
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={2}>
                <Typography variant="header2">{title} </Typography>
            </Box>
            {/* FirstName - Last Name */}
            <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                <TextField
                    fullWidth
                    required
                    name="first_name"
                    {...register("first_name", {})}
                    defaultValue={candidate?.firstName || undefined}
                    error={Boolean(errors.position)}
                    helperText={errors.position ? errors.position.message : ""}
                    label="First name"
                    InputProps={{ startAdornment }}
                />
                <TextField
                    fullWidth
                    required
                    name="last_name"
                    {...register("last_name", {})}
                    defaultValue={candidate?.lastName || undefined}
                    error={Boolean(errors.position)}
                    helperText={errors.position ? errors.position.message : ""}
                    label="Last name"
                    InputProps={{ startAdornment }}
                />
            </HorizontalFlex>
            {/* Headline */}
            <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                <TextField
                    fullWidth
                    name="headline"
                    {...register("headline", {})}
                    defaultValue={candidate?.headline || undefined}
                    error={Boolean(errors.position)}
                    helperText={errors.position ? errors.position.message : ""}
                    label="Headline"
                />
            </HorizontalFlex>
            <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                <TextField
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    name="birthdate"
                    {...register("birthdate", {})}
                    defaultValue={candidate?.birthdate || undefined}
                    error={Boolean(errors.position)}
                    helperText={errors.position ? errors.position.message : ""}
                    label="Date of birth"
                    type="date"
                />
                <TextField
                    fullWidth
                    name="email"
                    {...register("email", {})}
                    defaultValue={candidate?.email || undefined}
                    error={Boolean(errors.position)}
                    helperText={errors.position ? errors.position.message : ""}
                    label="Email"
                    InputProps={{ startAdornment }}
                />
            </HorizontalFlex>
            <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                <Controller
                    name="country_id"
                    control={control}
                    defaultValue={candidate?.country || {}}
                    render={({ field }) => (
                        <Autocomplete
                            id="country_id"
                            fullWidth
                            autoComplete
                            limitTags={1}
                            filterSelectedOptions
                            options={countryOptions || []}
                            getOptionLabel={(option) =>
                                option && option.hasOwnProperty("name")
                                    ? option.name
                                    : ""
                            }
                            value={field.value || undefined}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(_, newValue) => {
                                field.onChange(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment,
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <Controller
                    name="city_id"
                    control={control}
                    defaultValue={candidate?.city || {}}
                    render={({ field }) => (
                        <Autocomplete
                            id="city_id"
                            fullWidth
                            autoComplete
                            options={cityOptions}
                            loading={citiesFetching}
                            disabled={!hasCountry}
                            onClose={() => dispatch(resetCitiesOptions())}
                            onInputChange={handleInputChange}
                            getOptionLabel={(option) =>
                                option && option.hasOwnProperty("name")
                                    ? option.name
                                    : ""
                            }
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(_, newValue) => {
                                field.onChange(newValue);
                                dispatch(resetCitiesOptions());
                            }}
                            renderInput={(params) => (
                                <div>
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="City"
                                        placeholder="Start typing..."
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment,
                                        }}
                                    />
                                </div>
                            )}
                        />
                    )}
                />
            </HorizontalFlex>
            {/* Country - City */}
            {/* Email - Phone */}
            <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                <Controller
                    name="calling_code_id"
                    control={control}
                    defaultValue={candidate?.phoneCode || {}}
                    render={({ field }) => (
                        <Autocomplete
                            id="calling_code_id"
                            autoComplete
                            limitTags={1}
                            fullWidth
                            options={callingCodeOptions}
                            getOptionLabel={(option) => option?.name || ""}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name || {}
                            }
                            {...field}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country code"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <TextField
                    fullWidth
                    name="phone"
                    {...register("phone", {})}
                    defaultValue={candidate?.phone || undefined}
                    error={Boolean(errors.position)}
                    helperText={errors.position ? errors.position.message : ""}
                    label="Phone"
                    InputProps={{ startAdornment }}
                />
            </HorizontalFlex>
            {/* Linkedin - Collegelink */}
            <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                <TextField
                    fullWidth
                    name="linkedin"
                    {...register("linkedin", {
                        validate: {
                            isLinkedinDomain: (value) => {
                                if (!value) return true;

                                return (
                                    SocialMediaURL.isLinkedInDomain(value) ||
                                    getErrorMessage.social.linkedin.invalid
                                );
                            },
                            isRecruiterAccount: (value) => {
                                if (!value) return true;

                                return (
                                    !SocialMediaURL.isRecruiterAccount(value) ||
                                    getErrorMessage.social.linkedin.recruiter
                                );
                            },
                            containsLinkedinId: (value) => {
                                if (!value) return true;

                                return (
                                    SocialMediaURL.containsLinkedinId(value) ||
                                    getErrorMessage.social.linkedin.badUrl
                                );
                            },
                        },
                    })}
                    defaultValue={candidate?.linkedin && composeLinkedInURL}
                    error={Boolean(errors.linkedin)}
                    helperText={errors.linkedin ? errors.linkedin.message : ""}
                    label="Linkedin profile"
                    InputProps={{ startAdornment }}
                />
                <TextField
                    fullWidth
                    name="collegelink_id"
                    {...register("collegelink_id", {
                        validate: {
                            isCollegelinkDomain: (value) => {
                                if (!value) return true;

                                return (
                                    SocialMediaURL.isCollegelinkDomain(value) ||
                                    getErrorMessage.collegelink.invalid
                                );
                            },
                            containsStudent: (value) => {
                                if (!value) return true;

                                return (
                                    SocialMediaURL.hasCollegelinkStudentParam(
                                        value
                                    ) || getErrorMessage.collegelink.badUrl
                                );
                            },
                        },
                    })}
                    defaultValue={
                        candidate?.collegelink && composeCollegeLinkURL
                    }
                    error={Boolean(errors.collegelink_id)}
                    helperText={
                        errors.collegelink_id
                            ? errors.collegelink_id.message
                            : ""
                    }
                    label="CollegeLink profile"
                />
            </HorizontalFlex>
            {/* Military - Defferment */}
            <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                <Controller
                    name="military_obligations"
                    control={control}
                    defaultValue={defaultMilitaryStatus()}
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <InputLabel id="military-obligation">
                                Military Obligation
                            </InputLabel>
                            <Select
                                {...field}
                                labelId="military-obligation"
                                label="Military Obligation"
                            >
                                {obligation.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />

                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!hasMilitaryObligations}
                    fullWidth
                    name="defer_until"
                    {...register("defer_until", {})}
                    error={Boolean(errors.position)}
                    helperText={errors.position ? errors.position.message : ""}
                    label="Defferment of military service"
                    type="month"
                />
            </HorizontalFlex>

            {/* Availability */}
            <HorizontalFlex sx={{ mt: "3rem", mb: "2rem" }}>
                <Stack sx={{ width: "100%" }}>
                    <Typography variant="subtitle2">
                        Access to Talent Availability
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="subtitle2">No</Typography>
                        <Controller
                            name="access_to_talent_availability"
                            control={control}
                            render={({ field }) => (
                                <Switch
                                    {...field}
                                    checked={field.value}
                                    inputProps={{
                                        "aria-label":
                                            "Access to Talent Availability",
                                    }}
                                />
                            )}
                        />
                        <Typography variant="subtitle2">Yes</Typography>
                    </Stack>
                </Stack>
            </HorizontalFlex>

            {/* Anonymous Report */}
            <HorizontalFlex sx={{ mb: "2rem" }}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    multiline
                    minRows={5}
                    name="anonymous_report"
                    {...register("anonymous_report")}
                    error={Boolean(errors.anonymous_report)}
                    helperText={errors.anonymous_report?.message || ""}
                    defaultValue={candidate?.accessToTalent.anonymousReport}
                    label="Access to Talent Anonymous Report"
                    type="textarea"
                />
            </HorizontalFlex>
            <HorizontalFlex sx={{ marginBottom: "1rem" }}></HorizontalFlex>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                >
                    Save changes
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default TopSectionForm;
