import { combineReducers } from "redux";
import userValues from "reducers/userValuesSlice";
import parserCheckboxGroupSlice from "./candidate/parserCheckboxGroupSlice";
import searchFiltersData from "reducers/candidate/searchFiltersDataSlice";
import appLoader from "reducers/appLoaderSlice";
import candidatesList from "reducers/candidate/candidatesSlice";
import currentCandidate from "reducers/candidate/currentCandidateSlice";
import recruitersAccounts from "reducers/candidate/recruitersAccountsSlice";
import recruitmentProcesses from "reducers/candidate/recruitmentProcessesSlice";
import educations from "reducers/candidate/educationsSlice";
import experiences from "reducers/candidate/experiencesSlice";
import parsedProfileDataSlice from "reducers/candidate/parsedProfileDataSlice";
import userIdSlice from "reducers/userIdSlice";
import citiesSlice from "reducers/candidate/citiesSlice";
import authModal from "reducers/authModal";
import hiddenInfoSlice from "./hiddenInfoSlice";
import companiesSearchFilters from "./company/companiesSearchFilters";
import companySlice from "./company/companySlice";
import linqRequestsSlice from "./linq-requests/linqRequestsSlice";

const allReducer = combineReducers({
    userValues,
    searchFiltersData,
    appLoader,
    candidatesList,
    currentCandidate,
    recruitersAccounts,
    parserCheckboxGroupSlice,
    recruitmentProcesses,
    educations,
    experiences,
    userIdSlice,
    parsedProfileDataSlice,
    authModal,
    citiesSlice,
    hiddenInfoSlice,
    companiesSearchFilters,
    companySlice,
    linqRequestsSlice
});

const rootReducer = (state, action) => {
    if (action.type === "userValues/clearStore") {
        // Reset each individual slice to its initial state
        return allReducer(undefined, action);
    }

    return allReducer(state, action);
};

export default rootReducer;
