import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const Loader = () => {
  const message = 'The App Is Loading ...';
  return (
    <Box px={3} sx={{ borderBottom: "1px solid lightgray" }}>
      <Typography variant="h5" component="h2" my={3} sx={{ fontWeight: 500 }}>
        {message}
      </Typography>
    </Box>
  );
};

export default Loader;
