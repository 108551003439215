import { useEffect, useState } from "react";
import {
    Box,
    Stack,
    Button,
    Alert,
    Switch,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useCompanySubscriptions } from "hooks/useCompanySubscriptions";
import { format, addMonths, toDate } from "date-fns";
import { useSelector } from "react-redux";
import { GlobalAppDataStorageService } from "infra/storage";

export const SubscriptionForm = ({ setIsDialogOpen, subscriptionDetails }) => {
    const companyId = useSelector((state) => state.companySlice.id);
    const [terminateEarly, setTerminateEarly] = useState(false);
    const { addSubscription, editSubscription, isLoading, requestSuccessful } =
        useCompanySubscriptions();

    const isEditMode = Boolean(subscriptionDetails?.id);

    const formatDate = (date) =>
        date ? format(new Date(date), "yyyy-MM-dd") : "";

    const defaultStartDate = isEditMode
        ? formatDate(subscriptionDetails?.startAt)
        : format(new Date(), "yyyy-MM-dd");
    const defaultEndDate = isEditMode
        ? formatDate(subscriptionDetails?.endAt)
        : format(addMonths(new Date(), 1), "yyyy-MM-dd");

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            subscriptionId: subscriptionDetails?.subscriptionId || "",
            planId: subscriptionDetails?.planId || "",
        },
    });

    const subscriptionPlanOptions =
        GlobalAppDataStorageService.getSubscriptionPlans();

    useEffect(() => {
        if (requestSuccessful) {
            setIsDialogOpen(false);
        }
    }, [requestSuccessful, setIsDialogOpen]);

    const handleSwitchChange = (event) => {
        setTerminateEarly(event.target.checked);
    };

    const submitForm = async (data) => {
        const params = {
            ...data,
            companyId,
        };
        if (terminateEarly) {
            params.endDate = toDate(new Date());
        }
        if (isEditMode) {
            await editSubscription(params);
        } else {
            await addSubscription(params);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Box gap={6} pt={3}>
                <input
                    {...register("subscriptionId")}
                    name="subscriptionId"
                    type="hidden"
                />

                <Stack gap={4}>
                    {isEditMode && (
                        <Alert
                            severity="warning"
                            action={
                                <Switch
                                    checked={terminateEarly}
                                    onChange={handleSwitchChange}
                                />
                            }
                        >
                            Terminate this subscription early
                        </Alert>
                    )}

                    <Stack direction="row" gap={2}>
                        <TextField
                            {...register("startDate")}
                            disabled={isEditMode}
                            label="Start Date"
                            type="date"
                            variant="standard"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            {...register("endDate")}
                            label="End Date"
                            type="date"
                            variant="standard"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Stack>

                    <FormControl variant="standard" error={errors.planId}>
                        <InputLabel id="select-plan-label">
                            Select plan
                        </InputLabel>
                        <Controller
                            name="planId"
                            control={control}
                            rules={{
                                required:
                                    "We cannot have a subscription without a plan!",
                            }}
                            render={({ field }) => (
                                <Select
                                    labelId="select-plan-label"
                                    {...field}
                                    helperText="t"
                                >
                                    {subscriptionPlanOptions.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        {errors.planId && (
                            <FormHelperText>
                                {errors.planId.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Stack>
            </Box>

            <Stack mt={6} direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    color="gray"
                    variant="text"
                    onClick={() => setIsDialogOpen(false)}
                >
                    Cancel
                </Button>
                <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                >
                    Save changes
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default SubscriptionForm;
