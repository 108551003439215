import axios from "utils/axiosConfig";
import { useState, useEffect } from "react";
import { urlService } from "infra/envs/";
import { useDispatch } from "react-redux";
import { recruitersAccounts } from "reducers/candidate/recruitersAccountsSlice";

export const useRecruitersAccounts = () => {
    const recruitersPath = urlService.RECRUITERS_ACCOUNTS_PATH;
    const [, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchRecruitersAccounts = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(recruitersPath);
                // sort recruitersOptions alphabetically
                response?.data?.sort((a, b) => {
                    const nameA = `${a.name} ${a.surname}`.toLowerCase();
                    const nameB = `${b.name} ${b.surname}`.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                dispatch(recruitersAccounts(response.data));
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchRecruitersAccounts();
    }, [dispatch, recruitersPath]);
};

export default useRecruitersAccounts;
