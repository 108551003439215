import { EnvService } from "../envs";

const baseApiPath = EnvService.getBackEndBaseUrl();

class URLService {
    GLOBAL_APP_DATA_PATH = `${baseApiPath}/api/global-application-data`;

    VALIDATE_CANDIDATE_PATH = `${baseApiPath}/api/validator/candidate`;
    RECRUITERS_ACCOUNTS_PATH = `${baseApiPath}/api/recruiters`;
    HFR_JOBS_PATH = `${baseApiPath}/api/hfr-jobs`;

    CANDIDATES_API_PATH = `${baseApiPath}/api/candidates`;
    CANDIDATE_CV_UPLOAD_PATH = `${baseApiPath}/api/storage/cv`;
    CANDIDATE_CV_PATH = `${baseApiPath}/api/storage/cv?candidateId=`;
    CANDIDATE_PROFILE_PATH = "/candidate/view";
    CANDIDATE_COLLEGELINK_PATH = EnvService.getCollegeLinkBaseUrl();
    CANDIDATE_ADD_RECRUITMENT_PROCESS_PATH = `${baseApiPath}/api/recruitment-processes/new`;
    CANDIDATE_RECRUITMENT_PROCESS_PATH = `${baseApiPath}/api/recruitment-processes`;
    CANDIDATE_HISTORY_PATH = `${baseApiPath}/api/history/candidates`;

    JOBS_COMPANIES_PATH = `${baseApiPath}/api/flow-jobs-companies`;

    PARSE_CV_OR_PDF_PATH = `${baseApiPath}/api/cv-parse`;

    CV_PARSE_PDF_PATH = `${baseApiPath}/api/cv-parse-pdf`;
    CV_PARSE_LINKEDIN_PATH = `${baseApiPath}/api/cv-parse-linkedin`;
    CV_PARSE_SUBMIT_PATH = `${baseApiPath}/api/cv-save`;
    CV_PARSE_SUBMIT_AND_UPDATE_PATH = "/cv-update";

    EXISTING_CANDIDATE_PARSE_PATH = `${baseApiPath}/api/cv-parse-user`;

    COMPANIES_LISTING_PATH = `${baseApiPath}/api/company-services/search`;
    GET_COMPANIES_PATH = `${baseApiPath}/api/companies`;
    COMPANY_PROFILE_PATH = "/company/view";

    COMPANY_SUBSCRIPTIONS_PATH = `${baseApiPath}/api/company-services/view?companyId=`;
    MODIFY_ACCESS_TO_TALENT_CREDITS_PATH = `${baseApiPath}/api/company-services/modify-access-to-talent-credits`;
    MODIFY_JOB_POST_CREDITS_PATH = `${baseApiPath}/api/company-services/modify-job-post-credits`;
    CREATE_ATT_SUBSCRIPTION_PATH = `${baseApiPath}/api/subscriptions`;
    EDIT_ATT_SUBSCRIPTION_PATH = `${baseApiPath}/api/company-services/edit-access-to-talent-subscription`;

    LINQ_REQUESTS_UPDATE_PATH = `${baseApiPath}/api/linq-requests/update`;
    LINQ_REQUESTS_SEARCH_PATH = `${baseApiPath}/api/linq-requests/search`;
}

const service = new URLService();
export default service;
