import { Skeleton, Stack } from "@mui/material";

export default function TopSectionSkeleton() {
    return (
        <Stack direction="row" gap={2}>
            <Stack direction="row" gap={2}>
                <Skeleton variant="rounded" height={40} width={40} />
                <Skeleton variant="rounded" height={60} width={140} />
            </Stack>
            <Stack direction="row" gap={2}>
                <Skeleton variant="rounded" height={40} width={40} />
                <Skeleton variant="rounded" height={60} width={140} />
            </Stack>
            <Stack direction="row" gap={2}>
                <Skeleton variant="rounded" height={40} width={40} />
                <Skeleton variant="rounded" height={60} width={140} />
            </Stack>
        </Stack>
    );
}
