import Chip from "@mui/material/Chip";

const LinqRequestStatus = {
    PENDING: 1,
    ACCEPTED: 2,
    REJECT: 3,
};

export const LinqRequestStatusChip = ({ status }) => {
    let color = null;
    let label = null;
    switch (status) {
        case LinqRequestStatus.ACCEPTED:
            color = "success";
            label = "Interested";
            break;
        case LinqRequestStatus.REJECT:
            color = "error";
            label = "Not Interested";
            break;
        case LinqRequestStatus.PENDING:
            color = "warning";
            label = "Pending";
            break;
        default:
            color = "info";
            label = "DEFAULT";
    }

    return <Chip label={label} color={color} />;
};
