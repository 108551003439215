import axios from "utils/axiosConfig";
import { useState } from "react";
import { urlService } from "infra/envs/";
import useNotifications from "hooks/useNotifications";
import Utils from "services/GeneralUtilityService";
import { setLinqRequests } from "reducers/linq-requests/linqRequestsSlice";
import { useDispatch } from "react-redux";

export const useLinqRequests = () => {
    const [isLinqRequestsFetchLoading, setIsLinqRequestsFetchLoading] =
        useState(false);
    const [isUpdateLinqRequestLoading, setIsUpdateLinqRequestLoading] =
        useState(false);
    const [isUpdateAndGetLoading, setIsUpdateAndGetLoading] = useState(false);

    const { renderErrorNotification, renderSuccessNotification } =
        useNotifications();
    const dispatch = useDispatch();

    const getLinqRequests = async (params) => {
        setIsLinqRequestsFetchLoading(true);
        try {
            const response = await axios.get(
                `${urlService.LINQ_REQUESTS_SEARCH_PATH}`,
                { params: params }
            );
            const linqRequests = response.data;
            dispatch(setLinqRequests(linqRequests));
            return linqRequests;
        } catch (error) {
            const errorMessage = Utils.extractErrorMessage(error.response.data);
            renderErrorNotification(errorMessage);
        } finally {
            setIsLinqRequestsFetchLoading(false);
        }
    };

    const updateLinqRequest = async (params) => {
        setIsUpdateLinqRequestLoading(true);
        try {
            const response = await axios.post(
                `${urlService.LINQ_REQUESTS_UPDATE_PATH}`,
                params
            );
            const linqRequests = response.data;
            if (linqRequests?.message)
                renderSuccessNotification(linqRequests.message);
            return linqRequests;
        } catch (error) {
            const errorMessage = Utils.extractErrorMessage(error.response.data);
            renderErrorNotification(errorMessage);
        } finally {
            setIsUpdateLinqRequestLoading(false);
        }
    };

    const updateLinqRequestAndGetList = async (updateParams, getParams) => {
        if (!updateParams || !getParams) return null;
        setIsUpdateAndGetLoading(true);
        try {
            const updateResponse = await updateLinqRequest(updateParams);
            if (!updateResponse)
                throw new Error("Failed to update Linq request");

            const getResponse = await getLinqRequests(getParams);
            if (!getResponse)
                throw new Error("Failed to get Linq requests list");

            return {
                updateResponse,
                getResponse,
            };
        } catch (error) {
            console.error("Error in updateLinqRequestAndGetList:", error);
            return null;
        } finally {
            setIsUpdateAndGetLoading(false);
        }
    };

    return {
        getLinqRequests,
        updateLinqRequest,
        isLinqRequestsFetchLoading,
        isUpdateLinqRequestLoading,
        isUpdateAndGetLoading,
        updateLinqRequestAndGetList,
    };
};
