import CandidateService from "services/CandidateService";
import axios from "utils/axiosConfig";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { currentCandidate } from "reducers/candidate/currentCandidateSlice";
import { urlService } from "infra/envs";
import GeneralUtilityService from "services/GeneralUtilityService";
import useNotifications from "hooks/useNotifications";

const VALIDATE_CANDIDATE_PATH = urlService.VALIDATE_CANDIDATE_PATH;

export const useCandidate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const setCandidateToState = async (candidateId) => {
        setIsLoading(true);
        try {
            const fetchedCandidate = await CandidateService.fetchCandidateById(
                candidateId
            );
            const candidateWithId = {
                ...fetchedCandidate,
                id: candidateId,
            };
            dispatch(currentCandidate(candidateWithId));
            return fetchedCandidate;
        } catch (error) {
            const errorMessage = "Error fetching candidate";
            setError(new Error(errorMessage));
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        setCandidateToState,
        isLoading,
        error,
    };
};

export const useCandidateValidation = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const [error, setError] = useState(null);
    const { renderErrorNotification } = useNotifications();

    const validateCandidate = async (candidateData) => {
        setIsLoading(true);
        try {
            await axios.post(VALIDATE_CANDIDATE_PATH, candidateData);
            setRequestSuccessful(true);
            return true;
        } catch (error) {
            const errorMessage = GeneralUtilityService.extractErrorMessage(
                error.response?.data
            );
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            setError(error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        validateCandidate,
        requestSuccessful,
        isLoading,
        error,
    };
};
