import { createSlice } from "@reduxjs/toolkit";

const recruitmentProcessesSlice = createSlice({
    name: "processes",
    initialState: { loading: false, data: [] },
    reducers: {
        setRecruitmentProcessesStart: (state) => {
            state.loading = true;
        },
        setCandidateRecruitmentProcesses: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        addCandidateRecruitmentProcess: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        updateCandidateRecruitmentProcess: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
    },
});

export const {
    setRecruitmentProcessesStart,
    addCandidateRecruitmentProcess,
    setCandidateRecruitmentProcesses,
    updateCandidateRecruitmentProcess,
} = recruitmentProcessesSlice.actions;

export default recruitmentProcessesSlice.reducer;
