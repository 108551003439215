import SectionCard from "../../SectionCard";
import SectionRow from "../../SectionRow";
import InlineFormSelect from "components/lib-ui/inline-forms/InlineFormSelect";
import { GlobalAppDataStorageService } from "infra/storage";

export const ContactPreferences = ({ candidate }) => {
    const meansOfContactOptions =
        GlobalAppDataStorageService.getMeansOfContact();
    const contactFrequencyOptions =
        GlobalAppDataStorageService.getContactFrequencies();

    const { contactFrequency, meansOfContact } = candidate;

    return (
        <SectionCard title="Contact Preferences">
            <SectionRow title="Contact Frequency">
                <InlineFormSelect
                    fieldName="contact_frequency_id"
                    options={contactFrequencyOptions}
                    selected={contactFrequency}
                />
            </SectionRow>
            <SectionRow title="Means of contact">
                <InlineFormSelect
                    multiple
                    fieldName="contact_types"
                    options={meansOfContactOptions}
                    selected={meansOfContact}
                />
            </SectionRow>
        </SectionCard>
    );
};

export default ContactPreferences;
