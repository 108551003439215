import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { MenuItem, ListItemText, ListItemIcon, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const MainNavigationItem = ({
    to,
    text,
    icon,
    activeIcon = icon,
    isSidebarOpen,
}) => {
    const theme = useTheme();
    const currentLocation = useLocation().pathname;
    const isActive = to === currentLocation;
    const styles = {
        listIcon: {
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "unset",
        },
        listIconActive: {
            color: isActive && theme.palette.primary.main,
            "&::after": isActive && {
                content: '""',
                width: "4px",
                height: "60%",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: 0,
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                left: 0,
                background: theme.palette.primary.main,
            },
        },
    };

    return (
        <Link to={to} key={to}>
            <Tooltip title={!isSidebarOpen && text} placement="right" arrow>
                <MenuItem
                    sx={{
                        boxSizing: "border-box",
                        borderRadius: "6px",
                        overflow: "hidden",
                        padding: "5px",
                        "& .MuiListItemIcon-root": {
                            minWidth: "30px",
                        },
                    }}
                    style={isActive ? styles.active : null}
                >
                    <ListItemIcon
                        sx={{
                            width: isSidebarOpen ? "auto" : "100%",
                            ...styles.listIcon,
                            ...styles.listIconActive,
                        }}
                    >
                        {isActive ? activeIcon : icon}
                    </ListItemIcon>

                    <ListItemText
                        primaryTypographyProps={{
                            sx: {
                                color: isActive && "primary.main",
                                opacity: isSidebarOpen ? "inherit" : "0",
                                transition: "all .3s",
                            },
                        }}
                    >
                        {text}
                    </ListItemText>
                </MenuItem>
            </Tooltip>
        </Link>
    );
};

export default MainNavigationItem;
