import { enqueueSnackbar, closeSnackbar } from "notistack";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const useNotifications = () => {
    const navigate = useNavigate();

    const addCandidateAction = (candidateId) => (
        <>
            <Button
                variant="text"
                onClick={() => {
                    navigate("/candidate/view/" + candidateId);
                }}
            >
                Candidate Profile
            </Button>
        </>
    );

    const defaultOptions = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
    };

    return {
        renderNotification: (message, options) =>
            enqueueSnackbar(message, { ...defaultOptions, ...options }),
        renderInfoNotification: (message, options) =>
            enqueueSnackbar(message, {
                ...defaultOptions,
                ...options,
                variant: "info",
            }),
        renderSuccessNotification: (message, options) =>
            enqueueSnackbar(message, {
                ...defaultOptions,
                ...options,
                variant: "success",
            }),
        renderErrorNotification: (message, options) =>
            enqueueSnackbar(message, {
                ...defaultOptions,
                ...options,
                variant: "error",
            }),
        closeNotification: (key = null) => closeSnackbar(key),
        renderAddCandidateNotification: (candidateId) =>
            enqueueSnackbar("Candidate Profile Created", {
                ...defaultOptions,
                action: () => addCandidateAction(candidateId),
            }),
        renderUpdateCandidateNotification: (candidateId) =>
            enqueueSnackbar("Candidate Profile Updated", {
                ...defaultOptions,
                action: () => addCandidateAction(candidateId),
            }),
    };
};

export default useNotifications;
