import { useState } from "react";
import { Box, Button } from "@mui/material";
import DialogBox from "components/lib-ui/DialogBox";
import BlankState from "../BlankState";
import NoteCard from "../NoteCard";
import NoteForm from "features/candidate/candidate-form/NoteForm";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

export const Notes = () => {
    const candidate = useSelector((state) => state?.currentCandidate || {});
    const notes = candidate.notes;
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <Box
            sx={{
                maxWidth: "1200px",
            }}
        >
            {notes?.length > 0 ? (
                <Box
                    sx={{
                        display: "flex",
                        gap: 3,
                        flexDirection: "column",
                        whiteSpace: "pre-line",
                    }}
                >
                    {notes?.map((note, index) => (
                        <NoteCard key={index} note={note} />
                    ))}

                    <Box
                        sx={{
                            position: "fixed",
                            right: "30px",
                            bottom: "30px",
                        }}
                    >
                        {!isDialogOpen && (
                            <Button
                                sx={{
                                    borderRadius: "50px",
                                    padding: "10px 30px",
                                }}
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    setIsDialogOpen(true);
                                }}
                            >
                                <AddIcon sx={{ marginRight: "5px" }} />
                                Create note
                            </Button>
                        )}
                    </Box>
                </Box>
            ) : (
                <BlankState
                    text="No notes"
                    showButton={true}
                    buttonText="Create note"
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                />
            )}

            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title="Create note"
                floatCancel={true}
                showActionButtons={false}
            >
                <NoteForm setIsDialogOpen={setIsDialogOpen} />
            </DialogBox>
        </Box>
    );
};

export default Notes;
