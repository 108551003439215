import { Stack, Typography } from "@mui/material";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import ReProService from "services/RecruitmentProccessCard.service";

export const RecruitmentProcessNotesReport = ({ data }) => {
    const { score } = data;

    return (
        <Stack
            px={2}
            spacing={2}
            sx={{ borderLeft: "3px solid", borderColor: "#0297CB" }}
        >
            <Stack spacing={1} direction="row" alignItems="center">
                <Typography
                    fontSize=".9rem"
                    color="gray.dark"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    <PushPinOutlinedIcon fontSize="1rem" />
                    {`Report `}
                    {score &&
                        `${score}/10 ${ReProService.getRecruitmentProcessScoreIcon(
                            score
                        )}`}
                </Typography>
            </Stack>
            <Typography
                sx={{
                    width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "pre-wrap",
                }}
            >
                {data.body}
            </Typography>
        </Stack>
    );
};

export default RecruitmentProcessNotesReport;
