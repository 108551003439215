import React from "react";
import Box from "@mui/material/Box";

/**
 * Container for the logo at the top section.
 */

export const TopLogoContainer = ({ children }) => {
    return (
        <Box
            sx={{
                display: "flex",
                minWidth: "16rem",
            }}
        >
            {children}
        </Box>
    );
};

export default TopLogoContainer;
