import { useState } from "react";
import { Stack, Paper, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import GeneralUtilityService from "services/GeneralUtilityService";
import IconBtn from "components/lib-ui/IconBtn";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DialogBox from "components/lib-ui/DialogBox";
import NoteForm from "../candidate-form/NoteForm";
import { useSelector } from "react-redux";

export const NoteCard = ({ note }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { recruiter_email, updated_at, body } = note;
    const composeName = recruiter_email;
    const image = null;
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const loggedUserId = useSelector((state) => state.userIdSlice?.data);
    return (
        <Paper
            elevation={3}
            sx={{
                borderRadius: "10px",
                width: "100%",
                maxWidth: "900px",
                height: "fit-content",
                display: "flex",
                flexDirection: "column",
                padding: 2,
            }}
        >
            <Stack
                direction="row"
                display="flex"
                spacing={1}
                alignItems="center"
            >
                <Avatar
                    alt="candidate picture"
                    src={image}
                    loading="lazy"
                    sx={{
                        width: 35,
                        height: 35,
                        backgroundColor: image
                            ? "gray"
                            : GeneralUtilityService.stringToColor(
                                  recruiter_email
                              ),
                    }}
                >
                    {GeneralUtilityService.getStringInitals(composeName)}
                </Avatar>
                <Typography>{composeName}</Typography>
                <Typography color="gray.medium">{updated_at}</Typography>
                {note.recruiter_id === loggedUserId ? (
                    <IconBtn
                        defaulted={true}
                        sx={{
                            top: 0,
                            right: 0,
                            border: "unset",
                        }}
                        icon={EditOutlinedIcon}
                        iconStyles={{ fontSize: "1.2rem" }}
                        onClick={handleDialogOpen}
                    ></IconBtn>
                ) : null}
            </Stack>

            <Divider sx={{ width: "100%", marginTop: 2, marginBottom: 2 }} />

            <Typography variant="paragraph2" color="gray.dark">
                {body}
            </Typography>
            <DialogBox
                isDialogOpen={dialogOpen}
                setIsDialogOpen={setDialogOpen}
                title="Edit note"
                floatCancel={true}
                showActionButtons={false}
            >
                <NoteForm edit setIsDialogOpen={setDialogOpen} note={note} />
            </DialogBox>
        </Paper>
    );
};

export default NoteCard;
