import SvgIcon from "@mui/material/SvgIcon";

const RappIcon = ({ fill = "#231F20", color = "#fff", ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 78.8 78.8">
            <path
                fill={fill}
                d="M78.8 20.189v-.01a19.289 19.289 0 0 0-5.668-13.685A19.289 19.289 0 0 0 59.447.824h-.008a315.192 315.192 0 0 0-39.168 0h-.008A19.291 19.291 0 0 0 6.58 6.495 19.293 19.293 0 0 0 .911 20.178a315.427 315.427 0 0 0 0 39.183A19.299 19.299 0 0 0 6.58 73.046a19.296 19.296 0 0 0 13.684 5.668h.003c13.047.812 26.132.812 39.178 0h.003a19.293 19.293 0 0 0 13.684-5.668A19.29 19.29 0 0 0 78.8 59.361l.002-.01c.812-13.042.812-26.12 0-39.162Z"
            />
            <path
                fill={color}
                d="M49.242 21.497c-.125 5.114-4.165 9.398-9.386 9.386-5.224-.012-9.26-4.25-9.387-9.386-.108-4.413-6.976-4.428-6.868 0 .217 8.834 7.252 16.254 16.255 16.254s16.038-7.42 16.254-16.254c.108-4.422-6.76-4.42-6.868 0Z"
            />
            <path
                fill={color}
                d="M46.355 20.987a6.5 6.5 0 1 1-12.998-.002 6.5 6.5 0 0 1 12.998.002Z"
            />
            <path
                fill={color}
                d="M50.695 59.145c-3.528-3.706-3.7-9.592 0-13.275 3.702-3.685 9.553-3.543 13.274 0 3.196 3.044 8.064-1.801 4.856-4.856-6.4-6.093-16.621-6.367-22.986 0-6.367 6.367-6.095 16.587 0 22.987 3.049 3.204 7.904-1.655 4.856-4.856Z"
            />
            <path
                fill={color}
                d="M53.096 57.463a6.5 6.5 0 1 1 9.192-9.19 6.5 6.5 0 0 1-9.192 9.19Z"
            />
            <path
                fill={color}
                d="M15.741 45.87c3.706-3.528 9.592-3.7 13.275 0 3.685 3.703 3.543 9.553 0 13.275-3.044 3.195 1.801 8.063 4.856 4.855 6.093-6.4 6.367-16.62 0-22.986-6.367-6.367-16.587-6.095-22.987 0-3.204 3.05 1.655 7.904 4.856 4.856Z"
            />
            <path
                fill={color}
                d="M17.423 48.271a6.499 6.499 0 1 1 9.191 9.192 6.499 6.499 0 0 1-9.191-9.192Z"
            />
        </SvgIcon>
    );
};

export default RappIcon;
