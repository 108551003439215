import { Box, Typography, Tooltip } from "@mui/material";

export const IconContent = ({
    text,
    children,
    icon: Icon,
    iconStyles,
    typographyStyles,
    sx: parentStyles,
    tooltipText,
    tooltipPlacement = "left",
}) => {
    return (
        <Box
            component="li"
            sx={{
                display: "flex",
                gap: 1,
                position: "relative",
                alignItems: "center",
                listStyle: "none",
                ...parentStyles,
            }}
        >
            {Icon ? (
                <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    <Icon
                        sx={{
                            color: "gray.dark",
                            fontSize: "1.2rem",
                            ...iconStyles,
                        }}
                    />
                </Tooltip>
            ) : null}
            {text ? (
                <Typography
                    color="gray.dark"
                    fontSize=".9rem"
                    sx={{ ...typographyStyles }}
                >
                    {text}
                </Typography>
            ) : null}

            {children}
        </Box>
    );
};

export default IconContent;
