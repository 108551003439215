import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Autocomplete,
    TextField,
    Stack,
    IconButton,
    Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import HorizontalFlex from "components/lib-ui/HorizontalFlex";
import { GlobalAppDataStorageService } from "infra/storage";
import { useUpdateCandidateById } from "hooks/useUpdateCandidateById";
import { useSelector } from "react-redux";

export const LanguagesForm = ({ title, setIsDialogOpen }) => {
    const candidateId = useSelector((state) => state.currentCandidate.id);
    const candidateLanguages = useSelector(
        (state) =>
            state.currentCandidate.personalDetailsAndPreferences
                .personalInformation.languages
    );

    const languageOptions = GlobalAppDataStorageService.getLanguages();
    const languageLevelOptions =
        GlobalAppDataStorageService.getLanguageLevels();

    const { updateLanguages, isLanguagesLoading, isLanguagesSuccessfull } =
        useUpdateCandidateById();

    const { handleSubmit } = useForm();

    const [languagePairs, setLanguagePairs] = useState(candidateLanguages);

    const addLanguagePair = () => {
        setLanguagePairs((oldLanguagePairs) => [
            ...oldLanguagePairs,
            { language: null, language_level: null },
        ]);
    };

    const deleteLanguagePair = (index) => {
        const updatedPairs = [...languagePairs];
        updatedPairs.splice(index, 1);
        setLanguagePairs(updatedPairs);
    };

    const submitForm = () => {
        // Filter out pairs where either language or language_level is null
        const filteredPairs = languagePairs.filter(
            (pair) => pair.language !== null && pair.language_level !== null
        );

        const languageIds = filteredPairs?.map((item) => {
            return {
                language_id: item.language.id,
                language_level_id: item.language_level.id,
            };
        });

        updateLanguages(candidateId, { languages: languageIds });
    };

    const handleLanguageChange = (index, newValue) => {
        const updatedPairs = [...languagePairs];
        updatedPairs[index].language = newValue;
        setLanguagePairs(updatedPairs);
    };

    const handleLevelChange = (index, newValue) => {
        const updatedPairs = [...languagePairs];
        updatedPairs[index].language_level = newValue;
        setLanguagePairs(updatedPairs);
    };

    useEffect(() => {
        if (isLanguagesSuccessfull) {
            setIsDialogOpen(false);
        }
    }, [isLanguagesSuccessfull, setIsDialogOpen]);

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={2}>
                <Typography variant="header2">{title} </Typography>
            </Box>

            {languagePairs?.map((pair, index) => (
                <HorizontalFlex key={index} sx={{ marginBottom: "1rem" }}>
                    <Autocomplete
                        fullWidth
                        autoComplete
                        defaultValue={pair.language || null}
                        options={languageOptions || []}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        value={pair.language}
                        onChange={(_, newValue) =>
                            handleLanguageChange(index, newValue)
                        }
                        renderInput={(params) => (
                            <TextField
                                variant="standard"
                                {...params}
                                label="Language"
                            />
                        )}
                    />
                    <Autocomplete
                        fullWidth
                        autoComplete
                        defaultValue={pair.language_level || null}
                        options={languageLevelOptions || []}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        value={pair.language_level}
                        onChange={(_, newValue) =>
                            handleLevelChange(index, newValue)
                        }
                        renderInput={(params) => (
                            <TextField
                                variant="standard"
                                {...params}
                                label="Level"
                            />
                        )}
                    />
                    <IconButton
                        aria-label="Delete"
                        onClick={() => deleteLanguagePair(index)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </HorizontalFlex>
            ))}

            <Stack direction="row" spacing={2} justifyContent="space-between">
                <Button
                    sx={{
                        borderRadius: "50px",
                        padding: "10px 30px",
                    }}
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={addLanguagePair}
                >
                    + Add Language
                </Button>
                <LoadingButton
                    sx={{
                        borderRadius: "50px",
                        padding: "10px 30px",
                    }}
                    size="small"
                    color="primary"
                    type="submit"
                    loadingPosition="start"
                    loading={isLanguagesLoading}
                    startIcon={<SaveIcon />}
                >
                    Save changes
                </LoadingButton>
            </Stack>
        </Box>
    );
};
export default LanguagesForm;
