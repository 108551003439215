import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import useFetchCities from "hooks/useFetchCities";
import { Controller } from "react-hook-form";
import { debounce } from "lodash";

const debouncedFetch = debounce(async (inputValue, fetchCities, setOptions) => {
    const fetchedCities = await fetchCities(inputValue);
    setOptions(fetchedCities);
}, 600);

export default function CityAutoComplete({
    disabled,
    value,
    fieldName,
    formControls,
}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const { control } = formControls;
    const { fetchCitiesWithoutCountry, allCitiesFetching: loading } =
        useFetchCities();

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length < 2) return;
        debouncedFetch(inputValue, fetchCitiesWithoutCountry, setOptions);
    };

    return (
        <Controller
            name={fieldName}
            control={control}
            defaultValue={value || null}
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    fullWidth
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    disabled={disabled}
                    options={options}
                    loading={loading}
                    getOptionLabel={(option) =>
                        option?.name || option?.value || ""
                    }
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    onChange={(_, newValue) => field.onChange(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            fullWidth
                            color="focus"
                            variant="standard"
                            disabled={disabled}
                            onChange={handleInputChange}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading && (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        )}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            )}
        />
    );
}
