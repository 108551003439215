import React, { useState, useEffect } from "react";
import { Box, Snackbar, Alert } from "@mui/material";

const SnackbarNotification = ({
    messages = [],
    YPosition = "top",
    XPosition = "right",
    autoHideDuration = 100000,
    severity,
    containerStyle,
    alertStyles,
}) => {
    const [open, setOpen] = useState(false);
    const [messageQueue, setMessageQueue] = useState([...messages]);
    const [, setCurrentMessage] = useState(null);

    useEffect(() => {
        if (messages.length > 0) {
            setMessageQueue([...messages]);
            setCurrentMessage(...messages);
            setOpen(true);
        }
    }, [messages]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
        setMessageQueue((prevQueue) => prevQueue.slice(1));
    };

    useEffect(() => {
        if (!open && messageQueue.length > 0) {
            setCurrentMessage(messageQueue[0]);
            setOpen(true);
        }
    }, [open, messageQueue]);

    return (
        <Box sx={{ display: "flex" }} style={containerStyle}>
            {messageQueue?.map((message, key) => {
                return (
                    <Snackbar
                        key={key}
                        open={open}
                        autoHideDuration={autoHideDuration}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{
                            vertical: YPosition,
                            horizontal: XPosition,
                        }}
                    >
                        <Alert
                            onClose={handleCloseSnackbar}
                            severity={severity}
                            sx={{ width: "100%", ...alertStyles }}
                        >
                            {message}
                        </Alert>
                    </Snackbar>
                );
            })}
        </Box>
    );
};

export default SnackbarNotification;
