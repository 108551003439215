import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

/**
 * Wrapper component for the main content of the page.
 */
export const Main = ({ children }) => {
    const theme = useTheme();
    return (
        <Box
            component="main"
            sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: "1",
                background: theme.palette.gray.light,
            }}
        >
            {children}
        </Box>
    );
};

export default Main;
