import { useState } from "react";
import { Typography, Stack, IconButton, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const ToggleContent = ({
    title = "Filters",
    icon = <KeyboardArrowUpIcon />,
    children,
}) => {
    const [isVisible, setIsVisible] = useState(true);
    return (
        <Stack spacing={1}>
            <Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                            textOverflow: "ellipsis",
                            maxWidth: "85%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {icon}
                        <Typography component="h4" fontWeight="500" sx={{}}>
                            {title}
                        </Typography>
                    </Stack>

                    <IconButton
                        sx={{ position: "absolute", right: "8px" }}
                        aria-label="Collapse or expand list"
                        onClick={() => {
                            setIsVisible((visibility) => !visibility);
                        }}
                    >
                        {isVisible ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </Stack>
            </Stack>
            <Collapse in={isVisible}>{children}</Collapse>
        </Stack>
    );
};

export default ToggleContent;
