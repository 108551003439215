import { useState } from "react";
import SectionCard from "../../SectionCard";
import SectionRow from "../../SectionRow";
import InlineFormSelect from "components/lib-ui/inline-forms/InlineFormSelect";
import { GlobalAppDataStorageService } from "infra/storage";
import IconBtn from "components/lib-ui/IconBtn";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DialogBox from "components/lib-ui/DialogBox";
import LanguagesForm from "features/candidate/candidate-form/LanguagesForm";
import { Chip } from "@mui/material";

export const PersonalInformation = ({ candidate }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const vehicleOptions = GlobalAppDataStorageService.getVehicles();
    const genderOptions = GlobalAppDataStorageService.getGenders();
    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    return (
        <SectionCard title="Personal information">
            <SectionRow title="Languages">
                {candidate.languages
                    ? candidate.languages.map((item) => (
                          <Chip
                              key={item.language.id}
                              label={`${item.language.name} - ${item.language_level.name}`}
                          ></Chip>
                      ))
                    : null}
                <IconBtn
                    sx={{
                        opacity: 0,
                        pointerEvents: "none",
                        marginLeft: "auto",
                        border: "unset",
                        background: "white",
                        position: "absolute",
                        right: 0,
                        top: 1,
                        transform: " translateY('-50')",
                    }}
                    icon={EditOutlinedIcon}
                    onClick={handleDialogOpen}
                ></IconBtn>
            </SectionRow>
            <SectionRow title="Vehicle">
                <InlineFormSelect
                    fieldName="vehicles"
                    multiple
                    options={vehicleOptions}
                    selected={candidate.vehicles}
                />
            </SectionRow>
            <SectionRow title="Gender">
                <InlineFormSelect
                    fieldName="gender"
                    options={genderOptions}
                    selected={candidate.gender}
                />
            </SectionRow>
            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title="Add languages"
                floatCancel={true}
                showActionButtons={false}
            >
                <LanguagesForm edit setIsDialogOpen={setIsDialogOpen} />
            </DialogBox>
        </SectionCard>
    );
};

export default PersonalInformation;
