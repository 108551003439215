import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

export const BlankState = ({
    text,
    buttonRef,
    buttonText,
    showButton,
    onClick: buttonClick,
    dataTestId,
    children,
}) => {
    return (
        <Box
            p={3}
            sx={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                maxWidth: "550px",
                border: "2px dashed",
                borderColor: "gray",
                borderRadius: "10px",
                alignItems: "center",
            }}
        >
            <Typography variant="header2" color="gray.dark">
                {text}
            </Typography>

            {showButton ? (
                <Box ref={buttonRef}>
                    <Button
                        sx={{ borderRadius: "50px", padding: "10px 30px" }}
                        size="medium"
                        color="primary"
                        variant="contained"
                        onClick={buttonClick}
                        {...(dataTestId ? { "data-testid": dataTestId } : {})}
                    >
                        <AddIcon sx={{ marginRight: "5px" }} />
                        {buttonText}
                    </Button>
                </Box>
            ) : null}
            {children ? <Box>{children}</Box> : null}
        </Box>
    );
};

export default BlankState;
