import { useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import IconBtn from "components/lib-ui/IconBtn";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    validateParsedCandidate,
    setSubmittedDataToUnflatten,
} from "reducers/candidate/parsedProfileDataSlice";
import DifferenceGroup from "./DifferenceGroup";
import useFetchUniversitiesAndDepartments from "hooks/useFetchUniversitiesAndDepartments";
import useFetchCompanies from "hooks/useFetchCompanies";
import { GlobalAppDataStorageService } from "infra/storage";
import GenerateUtilitySevice from "services/GeneralUtilityService";
import Utils from "services/GeneralUtilityService";
import ParserService from "services/Parser.service";
import useParser from "hooks/useParser";
import { useNavigate } from "react-router";

export const SecondaryData = ({ data }) => {
    const { submitParsedData, submitAndUpdateParsedData, submitLoading } =
        useParser();

    const rappUserExists = Boolean(data?.existingUserData?.generalInfo?.id);

    // State variable to handle the submission triggering after Redux dispatch
    const [submitKey, setSubmitKey] = useState(0);

    const parsedSubmitData = useSelector(
        (state) => state.parsedProfileDataSlice.submittedDataToUnflatten
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { universitiesOptions, departmentsOptions } =
        useFetchUniversitiesAndDepartments();
    const jobFunctionsOptions = GlobalAppDataStorageService.getJobFunctions();
    const occupationsOptions = GlobalAppDataStorageService.getOccupations();

    const { hard: hardSkills, soft: softSkills } =
        GlobalAppDataStorageService.getSkills();

    const { companiesOptions } = useFetchCompanies();
    const educationLevelOptions =
        GlobalAppDataStorageService.getEducationLevels();
    const yearOptions = Utils.getYearOptions(50);

    const experienceFields = [
        {
            title: "id",
            fieldName: "id",
            type: "hidden",
            origin: "experiences",
        },
        {
            title: "Job title",
            fieldName: "job_title",
            type: "text",
            origin: "experiences",
        },
        {
            title: "Company",
            fieldName: "company",
            type: "company",
            origin: "experiences",
            options: companiesOptions,
            required: true,
            freeSolo: true,
        },
        {
            title: "Start Date",
            fieldName: "start_at",
            type: "date",
            origin: "experiences",
        },
        {
            title: "End Date",
            fieldName: "end_at",
            type: "date",
            origin: "experiences",
        },
        {
            title: "City",
            fieldName: "city",
            type: "city",
            origin: "experiences",
            options: [],
            freeSolo: false,
        },
        {
            title: "Job Description",
            fieldName: "job_description",
            type: "textarea",
            origin: "experiences",
        },
        {
            title: "Job Functions",
            dataSourceType: "algorithm",
            fieldName: "jobFunctions",
            type: "jobFunctions",
            origin: "experiences",
            freeSolo: false,
            options: jobFunctionsOptions,
        },
        {
            title: "Occupations",
            dataSourceType: "algorithm",
            fieldName: "occupations",
            type: "occupations",
            freeSolo: false,
            origin: "experiences",
            options: occupationsOptions,
        },
        {
            title: "Skills",
            dataSourceType: "algorithm",
            fieldName: "skills",
            type: "skills",
            freeSolo: false,
            origin: "experiences",
            options: [...hardSkills, ...softSkills],
        },
    ];

    const educationFields = [
        {
            title: "id",
            fieldName: "id",
            type: "hidden",
            origin: "educations",
        },
        {
            title: "University",
            fieldName: "university",
            type: "university",
            origin: "educations",
            options: universitiesOptions,
        },
        {
            title: "Department",
            fieldName: "department",
            type: "department",
            origin: "educations",
            options: departmentsOptions,
        },
        {
            title: "Education level",
            fieldName: "educationLevel",
            type: "autocomplete",
            origin: "educations",
            options: educationLevelOptions,
        },
        {
            title: "Grade",
            fieldName: "grade",
            type: "number",
            origin: "educations",
        },
        {
            title: "Start Date",
            fieldName: "start_at",
            type: "year",
            origin: "educations",
            options: yearOptions,
        },
        {
            title: "End Date",
            fieldName: "end_at",
            type: "year",
            origin: "educations",
            options: yearOptions,
        },
    ];

    const { handleSubmit, setValue, getValues, unregister, control, watch } =
        useForm();

    const formControls = {
        handleSubmit,
        setValue,
        getValues,
        unregister,
        control,
        watch,
    };

    const onSubmit = async (formData) => {
        const dynamicFieldUpdatedFormData =
            ParserService.applyDynamicFieldUpdates(formData);
        const unflattenData = ParserService.unflattenData(
            dynamicFieldUpdatedFormData
        );
        const transformedData =
            ParserService.transformDataForBackendCompatibility(unflattenData);

        dispatch(
            setSubmittedDataToUnflatten({
                data: transformedData,
            })
        );
        setSubmitKey((key) => key + 1);
    };

    useEffect(() => {
        if (!submitKey) return;

        const submitData = async () => {
            const parsedData = Utils.clearDeepEmptyKeys(parsedSubmitData);
            // Align data schema with backend specifications
            // Remove keys added and used solely on the frontend
            const keysToDelete = [
                "isValidated",
                "curatedEducations",
                "curatedExperiences",
            ];
            keysToDelete.forEach((key) => delete parsedData?.[key]);

            const isSuccessful = rappUserExists
                ? await submitAndUpdateParsedData(parsedData)
                : await submitParsedData(parsedData);

            if (isSuccessful) {
                navigate("/");
                GenerateUtilitySevice.scrollToTop();
            }
        };
        submitData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitKey]);

    const { curatedExperiences, curatedEducations } = data;

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            return false;
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={handleKeyPress}
        >
            <DifferenceGroup
                extractFields={experienceFields}
                data={curatedExperiences}
                groupName="Experience"
                formControls={formControls}
            />

            <DifferenceGroup
                extractFields={educationFields}
                data={curatedEducations}
                groupName="Educations"
                formControls={formControls}
            />

            <Stack spacing={2} pt={6} direction="row" justifyContent="flex-end">
                <IconBtn
                    onClick={() => {
                        dispatch(validateParsedCandidate(data));
                    }}
                    icon={ArrowBackOutlinedIcon}
                />
                <LoadingButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    loading={submitLoading}
                    py={2}
                    sx={{
                        borderRadius: "100px",
                        textTransform: "none",
                        fontWeight: "400",
                        paddingLeft: 4,
                        paddingRight: 4,
                    }}
                >
                    Import Data to R.APP
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default SecondaryData;
