import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import BlankState from "../BlankState";
import { CircularProgress } from "@mui/material";
import DialogBox from "components/lib-ui/DialogBox";
import ExperienceCard from "../ExperienceCard";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import ExperienceForm from "../../candidate-form/ExperienceForm";

export const Experience = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const experiences = useSelector((state) => state.experiences);

    return (
        <Box sx={{}}>
            {experiences?.loading && <CircularProgress />}
            {experiences?.data?.length === 0 && !experiences?.loading && (
                <Box>
                    <BlankState
                        text="The candidate has no experience data yet"
                        showButton={true}
                        buttonText="Add experience"
                        onClick={() => {
                            setIsDialogOpen(true);
                        }}
                    />
                </Box>
            )}
            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title="Add candidate experience"
                floatCancel={true}
                showActionButtons={false}
            >
                <ExperienceForm
                    edit={false}
                    setIsDialogOpen={setIsDialogOpen}
                />
            </DialogBox>
            {!experiences?.loading && experiences?.data?.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        gap: 3,
                        flexDirection: "row",
                        whiteSpace: "pre-line",
                    }}
                >
                    {experiences.data.map((data, index) => (
                        <ExperienceCard key={index} experience={data} />
                    ))}
                    <Box
                        sx={{
                            position: "fixed",
                            right: "30px",
                            bottom: "30px",
                        }}
                    >
                        {!isDialogOpen && (
                            <Button
                                sx={{
                                    borderRadius: "50px",
                                    padding: "10px 30px",
                                }}
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    setIsDialogOpen(true);
                                }}
                            >
                                <AddIcon sx={{ marginRight: "5px" }} />
                                Add experience
                            </Button>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Experience;
