import axios from "axios";
import { openModal } from "reducers/authModal";
import { EnvService } from "infra/envs";
import store from "store/store";

const axiosInstance = axios.create({
    baseURL: EnvService.getBackEndBaseUrl(),
});

const handle401 = () => {
    store.dispatch(openModal());
};

axiosInstance.interceptors.request.use(
    (request) => {
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log(error);
        if (error?.response?.status === 401) {
            handle401();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
