import { createSlice } from "@reduxjs/toolkit";
import { StorageService } from "infra/storage";

const createInitialState = () => {
    if (StorageService.getAuthToken()) {
        return {
            status: true,
            user: true,
        };
    } else {
        return {
            status: false,
            user: false,
        };
    }
};

const userValuesSlice = createSlice({
    name: "userValues",
    initialState: createInitialState(),
    reducers: {
        login: (state, _action) => {
            state.status = true;
            state.user = {
                username: true,
                email: true,
                token: StorageService.getAuthToken(),
            };
        },
        logout: (state) => {
            state.status = false;
            state.user = null;
        },
        clearStore: (_state) => {
            // Reset the state to initial values when clearing the store
        },
    },
});

export const { login, logout, clearStore } = userValuesSlice.actions;
export default userValuesSlice.reducer;
