import { createSlice } from "@reduxjs/toolkit";

const RESULT_LENGTH_OPTIONS = [10, 15, 25, 50, 75, 100];

const initialState = {
    filters: null,
    isDialogOpen: false,
    resultLength: RESULT_LENGTH_OPTIONS[0],
    resultLengthOptions: RESULT_LENGTH_OPTIONS,
    selectedLinqApplication: null,
};

const linqRequestsSlice = createSlice({
    name: "linqRequests",
    initialState,
    reducers: {
        filters: (state, action) => {
            state.filters = action.payload;
        },
        setIsDialogOpen: (state, action) => {
            state.isDialogOpen = action.payload;
        },
        selectedLinqApplication: (state, action) => {
            state.selectedLinqApplication = action.payload;
        },
        setLinqRequests: (state, action) => {
            state.linqRequests = action.payload;
        },
        setResultLength: (state, action) => {
            state.resultLength = action.payload;
        },
    },
});

export const {
    filters,
    setIsDialogOpen,
    selectedLinqApplication,
    setLinqRequests,
    setResultLength,
} = linqRequestsSlice.actions;
export default linqRequestsSlice.reducer;
