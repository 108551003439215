export const CollegeLinkIcon = () => {
    return (
        <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.1429 7.85596L10.0446 4.8571L13.0125 1.98438L11.0843 0.118033L6.05783 4.98323L11.0843 9.84843L13.1429 7.85596Z"
                fill="#4F4F4F"
            />
            <path
                d="M9.02553 1.67681C8.07936 0.64801 6.70075 0 5.16577 0C2.31279 0 0 2.23858 0 5C0 7.76142 2.31279 10 5.16577 10C6.70503 10 8.08705 9.34837 9.03343 8.31458L6.95458 6.30244C6.5085 6.8619 5.80886 7.22226 5.02226 7.22226C3.67501 7.22226 2.58286 6.16515 2.58286 4.86115C2.58286 3.55714 3.67501 2.50003 5.02226 2.50003C5.87836 2.50003 6.63146 2.92689 7.06678 3.57271L9.02553 1.67681Z"
                fill="#4F4F4F"
            />
        </svg>
    );
};

export default CollegeLinkIcon;
