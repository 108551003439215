import sha512 from "js-sha512";
import LoginApi from "../features/login/Login.api";
import { AuthTokenService } from "infra/api/httpClient";
import { StorageService } from "infra/storage";

class LoginService {
    /**
     *
     * @param {JSON} credentials
     */
    async loginUser(credentials) {
        credentials.password = sha512(credentials.password);
        let token = await LoginApi.loginRequest(credentials);
        StorageService.setAuthToken(token);
        AuthTokenService.set(token);
    }
}

const loginServiceInstance = new LoginService();
export default loginServiceInstance;
