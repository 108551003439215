import { createSlice } from "@reduxjs/toolkit";

const userId = createSlice({
    name: "userId",
    initialState: { data: null },
    reducers: {
        setUserId: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setUserId } = userId.actions;

export default userId.reducer;
