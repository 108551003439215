import React from "react";
import CandidatesList from "pages/CandidatesList";
import { Login } from "../features/login";
import CandidateAdd from "pages/CandidateAdd";
import CandidateView from "pages/CandidateView";
import CandidateParse from "pages/CandidateParse";
import CompaniesList from "pages/CompaniesList";
import CompanyView from "pages/CompanyView";
import LinqRequests from "features/linq-requests/LinqRequests";

export const routes = [
    {
        path: "/login",
        withLayout: false,
        withAuth: false,
        element: <Login />,
    },
    {
        path: "/",
        withLayout: true,
        withAuth: true,
        element: <CandidatesList />,
    },
    {
        path: "/companies",
        withLayout: true,
        withAuth: true,
        element: <CompaniesList />,
    },
    {
        path: "/requests",
        withLayout: true,
        withAuth: true,
        element: <LinqRequests />,
    },
    {
        path: "/candidate/add/manual-fill",
        withLayout: true,
        withAuth: true,
        element: <CandidateAdd />,
    },
    {
        path: "/candidate/add/parse-linkedin",
        withLayout: true,
        withAuth: true,
        element: <CandidateParse />,
    },
    {
        path: "/candidate/add/parse-cv",
        withLayout: true,
        withAuth: true,
        element: <CandidateParse />,
    },
    {
        path: "/candidate/view/:id",
        withLayout: true,
        withAuth: true,
        element: <CandidateView />,
    },
    {
        path: "/company/view/:id",
        withLayout: true,
        withAuth: true,
        element: <CompanyView />,
    },
];
