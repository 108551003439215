import { useState, useEffect } from "react";
import { debounce } from "lodash";
import TextField from "@mui/material/TextField";
import {
    Box,
    Typography,
    Autocomplete,
    Button,
    Switch,
    CircularProgress,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import HorizontalFlex from "components/lib-ui/HorizontalFlex";
import { useForm, Controller } from "react-hook-form";
import Stack from "@mui/material/Stack";
import { GlobalAppDataStorageService } from "infra/storage";
import { useSelector, useDispatch } from "react-redux";
import { useExperiences } from "hooks/useExperiences";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import useInsertCompany from "hooks/useInsertCompany";
import useFetchCompanies from "hooks/useFetchCompanies";
import MeteredButton from "components/lib-ui/MeteredButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import useFetchCities from "hooks/useFetchCities";
import GeneralUtilityService from "services/GeneralUtilityService";
import { resetCitiesOptions } from "reducers/candidate/citiesSlice";

export const ExperienceForm = ({
    experience,
    title,
    edit,
    setIsDialogOpen,
}) => {
    const dispatch = useDispatch();
    const candidateId = useSelector((state) => state?.currentCandidate?.id);
    const experienceId = experience?.id;
    const experienceEndDate = experience?.endAt;

    const startAtToMonth = GeneralUtilityService.convertDateToMonthInput(
        experience?.startAt
    );
    const endAtToMonth = GeneralUtilityService.convertDateToMonthInput(
        experience?.endAt
    );

    const { handleSubmit, control, register, watch, setValue, getValues } =
        useForm({
            defaultValues: {
                startAt: edit ? startAtToMonth : null,
                endAt: edit ? endAtToMonth : null,
            },
        });
    const [showExtraOptions, setShowExtraOptions] = useState(false);
    const currentlyWorkingStatus = watch("currentlyWorking");
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(
        experienceEndDate === null
    );

    useEffect(() => {
        if (currentlyWorkingStatus) {
            setValue("endAt", null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentlyWorkingStatus]);

    const { insertCompany } = useInsertCompany();
    const { fetching, fetchCompaniesByName, companiesOptions } =
        useFetchCompanies();
    const {
        addNewExperience,
        editExperience,
        deleteExperience,
        isLoading,
        isDeleteLoading,
        addRequestSuccessful,
        editRequestSuccessful,
        deleteRequestSuccessful,
    } = useExperiences();

    const jobTypeOptions = GlobalAppDataStorageService.getJobTypes();
    const cityOptions = useSelector((state) => state.citiesSlice);
    const hardSkillsOptions = GlobalAppDataStorageService.getHardSkills();
    const companySizeOptions = GlobalAppDataStorageService.getCompanySizes();
    const companyTypesOptions = GlobalAppDataStorageService.getCompanyTypes();
    const industriesOptions = GlobalAppDataStorageService.getIndustries();
    const workModelOptions = GlobalAppDataStorageService.getWorkingModels();
    const jobChangeReasonsOptions =
        GlobalAppDataStorageService.getJobChangeReasons();
    const responsibilitiesOptions =
        GlobalAppDataStorageService.getResponsibilitiesOptions();

    const occupationsOptions = GlobalAppDataStorageService.getOccupations();
    const jobFunctionsOptions = GlobalAppDataStorageService.getJobFunctions();

    const handleClick = (event) => {
        setShowExtraOptions(!showExtraOptions);
        // Focusing on the button causes the search field to blur
        event.target.focus();
    };

    const submitForm = async (data) => {
        const endAtToMonth =
            GeneralUtilityService.convertMonthInputToDate(data.endAt) || null;
        const startAtToMonth =
            GeneralUtilityService.convertMonthInputToDate(data.startAt) || null;
        data.endAt === true
            ? setIsCurrentlyWorking(true)
            : setIsCurrentlyWorking(false);
        const newCompanyId = showExtraOptions
            ? await insertCompany(data)
            : null;
        const dataIds = {
            companyId: newCompanyId ? newCompanyId : data?.companyId?.id,
            jobTitle: data?.jobTitle,
            jobDescription: data?.jobDescription,
            startAt: startAtToMonth,
            endAt: endAtToMonth,
            cityId: data?.cityId?.id,
            skills: data?.skills?.map((item) => item.id),
            responsibilities: data?.responsibilities?.map((item) => item.id),
            industries: data?.industries?.map((item) => item.id),
            occupations: data?.occupations.map((item) => item.id),
            jobFunctions: data?.jobFunctions.map((item) => item.id),
            jobTypeId: data?.jobType?.id,
            workingModelId: data?.workingModelId?.id,
            jobChangeReasonId: data?.jobChangeReason?.id,
            teamSize: data?.teamSize,
        };

        if (!edit) {
            await addNewExperience(candidateId, dataIds);
        }
        if (edit) {
            const endpointParams = {
                candidateId: candidateId,
                experienceId: experienceId,
            };

            await editExperience(endpointParams, dataIds);
        }
    };

    const debouncedFetchCompanyByName = debounce((value) => {
        fetchCompaniesByName(value);
    }, 500);

    const handleCompanyFetch = (event) => {
        debouncedFetchCompanyByName(event.target.value);
    };

    useEffect(() => {
        const companyNameValue = getValues("companyId")?.name;
        fetchCompaniesByName(companyNameValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            addRequestSuccessful ||
            editRequestSuccessful ||
            deleteRequestSuccessful
        ) {
            setIsDialogOpen(false);
        }
    }, [
        addRequestSuccessful,
        editRequestSuccessful,
        deleteRequestSuccessful,
        setIsDialogOpen,
    ]);

    const handleDelete = async () => {
        const endpointParams = {
            candidateId: candidateId,
            experienceId: experienceId,
        };
        await deleteExperience(endpointParams);
    };

    const countryOptions = GlobalAppDataStorageService.getCountries();

    const { fetchCities, citiesFetching } = useFetchCities();

    const selectedCountry = watch("country_id");

    const hasCountry =
        !GeneralUtilityService.isObjectEmpty(selectedCountry) ||
        experience?.city?.country;

    const debouncedFetchCitiesOptions = GeneralUtilityService.debounce(
        async (countryID, q) => {
            await fetchCities(countryID, q);
        },
        1000
    );

    const handleInputChange = (event, value, reason) => {
        if (reason === "input") {
            const selectedCountryId = selectedCountry?.id;
            debouncedFetchCitiesOptions(selectedCountryId, value);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Typography variant="header2">{title} </Typography>
            <Stack mt={3} spacing={3}>
                {showExtraOptions ? (
                    <>
                        <Typography variant="header2">
                            Add a new company
                        </Typography>
                        <HorizontalFlex>
                            <TextField
                                variant="standard"
                                fullWidth
                                required
                                name="name"
                                {...register("name")}
                                defaultValue={""}
                                label="Company name"
                            />
                            <Controller
                                name="companySizeId"
                                control={control}
                                defaultValue={null}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="companySizeId"
                                        fullWidth
                                        autoComplete
                                        options={companySizeOptions || []}
                                        getOptionLabel={(option) =>
                                            option?.name || ""
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="standard"
                                                {...params}
                                                label="Company size"
                                            />
                                        )}
                                    />
                                )}
                            />
                        </HorizontalFlex>
                        <HorizontalFlex>
                            <Controller
                                name="industries"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="industries"
                                        fullWidth
                                        multiple
                                        filterSelectedOptions
                                        autoComplete
                                        options={industriesOptions || []}
                                        getOptionLabel={(option) =>
                                            option.name || ""
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="standard"
                                                {...params}
                                                label="Industry"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="companyTypeId"
                                control={control}
                                defaultValue={null}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="companyTypeId"
                                        fullWidth
                                        autoComplete
                                        options={companyTypesOptions || []}
                                        getOptionLabel={(option) =>
                                            option?.name || ""
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="standard"
                                                {...params}
                                                label="Company type"
                                            />
                                        )}
                                    />
                                )}
                            />
                        </HorizontalFlex>
                        <HorizontalFlex>
                            <TextField
                                variant="standard"
                                fullWidth
                                name="websiteUrl"
                                {...register("websiteUrl", {})}
                                defaultValue={""}
                                label="Company url"
                            />
                            <TextField
                                variant="standard"
                                fullWidth
                                name="linkedinUrl"
                                {...register("linkedinUrl", {})}
                                defaultValue={""}
                                label="Company linkedin"
                            />
                        </HorizontalFlex>
                        <Button onClick={handleClick}>
                            Go back to existing companies
                        </Button>
                    </>
                ) : (
                    <></>
                )}

                {/* Company - Position */}
                <HorizontalFlex>
                    {!showExtraOptions ? (
                        <Controller
                            name="companyId"
                            control={control}
                            defaultValue={edit ? experience?.company : null}
                            render={({ field }) => (
                                <Autocomplete
                                    id="companyId"
                                    fullWidth
                                    autoComplete
                                    options={companiesOptions}
                                    loading={fetching}
                                    loadingText={"Loading companies"}
                                    getOptionLabel={(option) =>
                                        option?.name || ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    {...field}
                                    onChange={(event, newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    noOptionsText={
                                        <Button onClick={handleClick}>
                                            No match? Click to add company
                                        </Button>
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            variant="standard"
                                            onInput={handleCompanyFetch}
                                            {...params}
                                            label="Company"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {fetching && (
                                                            <CircularProgress
                                                                color="inherit"
                                                                size={20}
                                                            />
                                                        )}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    ) : (
                        <></>
                    )}

                    <TextField
                        variant="standard"
                        fullWidth
                        name="jobTitle"
                        {...register("jobTitle", {})}
                        defaultValue={edit ? experience?.jobTitle : ""}
                        required
                        label="Position"
                    />
                </HorizontalFlex>
                {/* Start - End date */}
                <HorizontalFlex>
                    <TextField
                        variant="standard"
                        {...register("startAt")}
                        fullWidth
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="startAt"
                        label="Start Date"
                        type="month"
                    />
                    <TextField
                        variant="standard"
                        {...register("endAt")}
                        name="endAt"
                        disabled={currentlyWorkingStatus}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label="End Date"
                        type="month"
                    />
                </HorizontalFlex>
                <HorizontalFlex>
                    <Controller
                        name="currentlyWorking"
                        control={control}
                        defaultValue={isCurrentlyWorking} // Set the default value to false
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={field.value}
                                        color="primary"
                                    />
                                }
                                label="Currently working there"
                            />
                        )}
                    />
                </HorizontalFlex>
                <HorizontalFlex>
                    <Controller
                        name="country_id"
                        control={control}
                        defaultValue={experience?.city?.country || null}
                        render={({ field }) => (
                            <Autocomplete
                                id="country_id"
                                fullWidth
                                autoComplete
                                limitTags={1}
                                filterSelectedOptions
                                options={countryOptions || []}
                                getOptionLabel={(option) =>
                                    option && option.hasOwnProperty("name")
                                        ? option.name
                                        : ""
                                }
                                value={field.value}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value.name
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Country"
                                        variant="standard"
                                    />
                                )}
                            />
                        )}
                    />
                    <Controller
                        name="cityId"
                        control={control}
                        defaultValue={experience?.city || null}
                        render={({ field }) => (
                            <Autocomplete
                                id="cityId"
                                fullWidth
                                autoComplete
                                options={cityOptions}
                                loading={citiesFetching}
                                disabled={!hasCountry}
                                onClose={() => dispatch(resetCitiesOptions())}
                                onInputChange={handleInputChange}
                                getOptionLabel={(option) =>
                                    option && option.hasOwnProperty("name")
                                        ? option.name
                                        : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value.name
                                }
                                {...field}
                                onChange={(_, newValue) => {
                                    field.onChange(newValue);
                                    dispatch(resetCitiesOptions());
                                }}
                                renderInput={(params) => (
                                    <div>
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="City"
                                            placeholder="Start typing..."
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        )}
                    />
                </HorizontalFlex>
                {/* Experience skills - Responsibilities */}
                <HorizontalFlex>
                    <Controller
                        name="skills"
                        control={control}
                        fullWidth
                        defaultValue={edit ? experience?.skills : []}
                        render={({ field }) => (
                            <Autocomplete
                                id="skills"
                                fullWidth
                                multiple
                                filterSelectedOptions
                                autoComplete
                                limitTags={1}
                                options={hardSkillsOptions || []}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                        label="Skills"
                                    />
                                )}
                            />
                        )}
                    />
                </HorizontalFlex>
                <HorizontalFlex>
                    <Controller
                        name="responsibilities"
                        control={control}
                        defaultValue={edit ? experience?.responsibilities : []}
                        render={({ field }) => (
                            <Autocomplete
                                id="responsibilities"
                                fullWidth
                                multiple
                                autoComplete
                                limitTags={1}
                                filterSelectedOptions
                                options={responsibilitiesOptions || []}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value.name
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                        label="Responsibilities"
                                    />
                                )}
                            />
                        )}
                    />
                </HorizontalFlex>
                {/* Job description */}
                <HorizontalFlex>
                    <TextField
                        variant="standard"
                        defaultValue={edit ? experience?.jobDescription : ""}
                        {...register("jobDescription")}
                        fullWidth
                        multiline
                        name="jobDescription"
                        label="Job description"
                    />
                </HorizontalFlex>

                <HorizontalFlex>
                    <Controller
                        name="occupations"
                        control={control}
                        defaultValue={edit ? experience.occupations : []}
                        render={({ field }) => (
                            <Autocomplete
                                id="occupations"
                                fullWidth
                                multiple
                                filterSelectedOptions
                                autoComplete
                                options={occupationsOptions || []}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                        label="Occupations"
                                    />
                                )}
                            />
                        )}
                    />
                </HorizontalFlex>
                <HorizontalFlex>
                    <Controller
                        name="jobFunctions"
                        control={control}
                        defaultValue={edit ? experience.job_functions : []}
                        render={({ field }) => (
                            <Autocomplete
                                id="jobFunctions"
                                fullWidth
                                multiple
                                filterSelectedOptions
                                autoComplete
                                options={jobFunctionsOptions || []}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                        label="Job Functions"
                                    />
                                )}
                            />
                        )}
                    />
                </HorizontalFlex>
                <HorizontalFlex>
                    <Controller
                        name="industries"
                        control={control}
                        defaultValue={edit ? experience.industries : []}
                        render={({ field }) => (
                            <Autocomplete
                                id="industries"
                                fullWidth
                                multiple
                                filterSelectedOptions
                                autoComplete
                                options={industriesOptions || []}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                        label="Experience Industries"
                                    />
                                )}
                            />
                        )}
                    />
                </HorizontalFlex>
                {/* Seniority - Years of xp */}
                <HorizontalFlex>
                    <Controller
                        name="workingModelId"
                        control={control}
                        defaultValue={edit ? experience?.workingModel : null}
                        render={({ field }) => (
                            <Autocomplete
                                id="workingModelId"
                                fullWidth
                                autoComplete
                                options={workModelOptions || []}
                                getOptionLabel={(option) => option?.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                        label="Working model"
                                    />
                                )}
                            />
                        )}
                    />
                    <Controller
                        name="jobType"
                        control={control}
                        defaultValue={edit ? experience?.jobType : null}
                        render={({ field }) => (
                            <Autocomplete
                                id="jobType"
                                fullWidth
                                autoComplete
                                options={jobTypeOptions || []}
                                getOptionLabel={(option) => option?.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                        label="Job type"
                                    />
                                )}
                            />
                        )}
                    />
                </HorizontalFlex>
                <HorizontalFlex>
                    <Controller
                        name="jobChangeReason"
                        control={control}
                        defaultValue={edit ? experience?.jobChangeReason : null}
                        render={({ field }) => (
                            <Autocomplete
                                id="jobChangeReason"
                                fullWidth
                                autoComplete
                                options={jobChangeReasonsOptions || []}
                                getOptionLabel={(option) => option.name}
                                value={field?.value || undefined}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value.name
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                        label="Reason for job change"
                                    />
                                )}
                            />
                        )}
                    />

                    <TextField
                        variant="standard"
                        {...register("teamSize")}
                        fullWidth
                        name="teamSize"
                        defaultValue={edit ? experience?.teamSize : null}
                        label="Team size"
                        type="number"
                    />
                </HorizontalFlex>
                <Stack direction="row" justifyContent="space-between">
                    {edit && (
                        <MeteredButton
                            onActionComplete={handleDelete}
                            loading={isDeleteLoading}
                            buttonProps={{
                                color: "gray",
                                startIcon: <DeleteOutlinedIcon />,
                            }}
                        >
                            Delete
                        </MeteredButton>
                    )}

                    <LoadingButton
                        sx={{ marginLeft: "auto" }}
                        color="primary"
                        type="submit"
                        loading={isLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                    >
                        Save changes
                    </LoadingButton>
                </Stack>
            </Stack>
        </Box>
    );
};

export default ExperienceForm;
