import React, { createContext, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, TextField, Autocomplete, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@emotion/react";
import SaveIcon from "@mui/icons-material/Save";

const FormContext = createContext();

export const Form = ({ defaultValues, onSubmit, children, containerProps }) => {
    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm({
        defaultValues,
    });
    const theme = useTheme();

    return (
        <FormContext.Provider value={{ control, register, errors, theme }}>
            <Stack
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                {...containerProps}
            >
                {children}
            </Stack>
        </FormContext.Provider>
    );
};

export const CustomAutocomplete = ({
    name,
    label,
    options,
    autoCompleteProps,
    textFieldProps,
    disabled,
    freeSolo,
    variant,
    getOptionLabel,
    required,
    isOptionEqualToValue,
    ...props
}) => {
    const { control } = useContext(FormContext);
    const defaultGetOptionLabel = (option) => option?.name || "";
    const defaultIsOptionEqualToValue = (option, value) => {
        return option.id === value.id;
    };

    return (
        <Controller
            name={name}
            control={control}
            {...props}
            render={({ field }) => (
                <Autocomplete
                    id={name}
                    options={options || []}
                    disabled={disabled}
                    fullWidth
                    freeSolo={freeSolo || false}
                    getOptionLabel={getOptionLabel || defaultGetOptionLabel}
                    isOptionEqualToValue={
                        isOptionEqualToValue || defaultIsOptionEqualToValue
                    }
                    {...field}
                    onChange={(_, newValue) => field.onChange(newValue)}
                    {...autoCompleteProps}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            variant={variant || "standard"}
                            InputProps={{
                                ...params.InputProps,
                                ...textFieldProps?.InputProps,
                            }}
                            required={required}
                        />
                    )}
                />
            )}
        />
    );
};

export const CustomTextField = ({
    name,
    label,
    textFieldProps,
    type,
    variant,
    inputProps,
    onChange,
    ...props
}) => {
    const { register, errors } = useContext(FormContext);
    return (
        <TextField
            name={name}
            label={label}
            type={type}
            variant={variant || "standard"}
            fullWidth
            {...register(name, { required: "This field is required" })}
            error={Boolean(errors[name])}
            inputProps={inputProps}
            onChange={onChange}
            helperText={errors[name]?.message}
            {...textFieldProps}
            {...props}
        />
    );
};

export const Section = ({ title, children, wrapperStyles }) => {
    const { theme } = useContext(FormContext);
    return (
        <Stack
            sx={{
                borderRadius: (theme) => theme.shape.borderRadius,
                overflow: "hidden",
                ...wrapperStyles,
            }}
        >
            {title && (
                <Box
                    sx={{
                        p: 2,
                        backgroundColor: theme.palette.gray.light,
                        borderBottom: "1px solid",
                        borderColor: theme.palette.gray.medium,
                    }}
                >
                    <Typography variant="title">{title}</Typography>
                </Box>
            )}

            <Box
                sx={{
                    p: 2,
                    backgroundColor: theme.palette.gray.light,
                }}
            >
                {children}
            </Box>
        </Stack>
    );
};

export const CustomLoadingButton = ({
    loading,
    type,
    color,
    startIcon,
    children,
    styles,
    ...props
}) => {
    return (
        <LoadingButton
            sx={{ marginLeft: "auto", ...styles }}
            color={color || "primary"}
            type={type || "button"}
            loading={loading}
            loadingPosition="start"
            startIcon={startIcon || <SaveIcon />}
            {...props}
        >
            {children}
        </LoadingButton>
    );
};

Form.Autocomplete = CustomAutocomplete;
Form.TextField = CustomTextField;
Form.LoadingButton = CustomLoadingButton;
Form.Section = Section;

export default Form;
