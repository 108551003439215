import React, { useState } from "react";
import { Button, Stack } from "@mui/material";
import PropTypes from "prop-types";

function ExpandableButton({
    text,
    onClick: buttonClick,
    icon: IconComponent,
    buttonStyles,
    iconStyles,
    dataTestId,
    ...otherProps
}) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Button
            sx={{
                borderRadius: "50px",
                padding: "10px 30px",
                position: "relative",
                overflow: "hidden",
                width: isHovered ? "auto" : "fit-content",
                ...buttonStyles,
            }}
            size="small"
            color="primary"
            variant="contained"
            onClick={buttonClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...(dataTestId ? { "data-testid": dataTestId } : {})}
            {...otherProps}
        >
            {isHovered ? (
                <Stack direction="row" alignItems="center">
                    {IconComponent && (
                        <IconComponent
                            sx={{ marginRight: "5px", ...iconStyles }}
                        />
                    )}
                    {text}
                </Stack>
            ) : (
                IconComponent && <IconComponent {...iconStyles} />
            )}
        </Button>
    );
}

ExpandableButton.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    onClick: PropTypes.func,
    buttonStyles: PropTypes.object,
    iconStyles: PropTypes.object,
    dataTestId: PropTypes.string,
};

export default ExpandableButton;
