import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    checkboxGroups: {},
};

const parserCheckboxGroupSlice = createSlice({
    name: "checkboxes",
    initialState,
    reducers: {
        createGroup: (state, action) => {
            const { groupId } = action.payload;
            // Initialize the group with an empty object
            state.checkboxGroups[groupId] = {};
        },
        addToGroup: (state, action) => {
            const { groupId, checkboxKey, checked } = action.payload;
            if (!state.checkboxGroups[groupId]) {
                state.checkboxGroups[groupId] = {};
            }
            // Construct the key and update the checkbox state
            state.checkboxGroups[groupId][checkboxKey] = checked;
        },
        toggleGroup: (state, action) => {
            const { groupId } = action.payload;
            if (!state.checkboxGroups[groupId]) return;
            // Check if all checkboxes are currently checked
            const allChecked = Object.values(
                state.checkboxGroups[groupId]
            ).every(Boolean);

            // Toggle the state based on allChecked
            Object.keys(state.checkboxGroups[groupId]).forEach((key) => {
                state.checkboxGroups[groupId][key] = !allChecked;
            });
        },

        toggleCheckbox: (state, action) => {
            const { groupId, checkboxIndex } = action.payload;

            // Check if the group exists, create if not
            if (!state.checkboxGroups[groupId]) {
                state.checkboxGroups[groupId] = {};
            }

            // Toggle the state of the individual checkbox
            const currentState = state.checkboxGroups[groupId][checkboxIndex];
            state.checkboxGroups[groupId][checkboxIndex] = !currentState;
        },
    },
});

// Selectors
export const getIsGroupChecked = (state, groupId) => {
    const group = state.checkboxGroups[groupId];
    if (!group || Object.keys(group).length === 0) {
        return "noneChecked"; // Or false, if the group is not found or empty
    }

    const allChecked = Object.values(group).every(Boolean);
    const anyChecked = Object.values(group).some(Boolean);

    if (allChecked) {
        return "allChecked"; // Or true
    } else if (anyChecked) {
        return "indeterminate";
    } else {
        return "noneChecked"; // Or false
    }
};

export const getCheckboxState = (state, groupId, checkboxId) => {
    const group = state.checkboxGroups[groupId];
    if (group) {
        return group[checkboxId] || false;
    }
    return false;
};

export const { createGroup, addToGroup, toggleGroup, toggleCheckbox } =
    parserCheckboxGroupSlice.actions;
export default parserCheckboxGroupSlice.reducer;
