import { Stack, Tooltip, Typography } from "@mui/material";
import { GlobalAppDataStorageService } from "infra/storage";
import { useTheme } from "@mui/material/styles";

export const AccessToTalentLabel = ({ label }) => {
    const theme = useTheme();
    return (
        <Tooltip
            title={
                <>
                    {GlobalAppDataStorageService?.getATTRequiredFields().map(
                        (field, index) => {
                            return (
                                <Typography
                                    key={`${field}-${index}`}
                                    sx={{ fontSize: ".8rem" }}
                                >
                                    {field}
                                </Typography>
                            );
                        }
                    )}
                </>
            }
        >
            <Stack direction="row" alignItems="center" gap={1}>
                <Typography
                    fontWeight="bold"
                    sx={{ fontSize: ".65rem", color: theme.palette.cyan.main }}
                >
                    ATT
                </Typography>
                {label && <Typography>{label}</Typography>}
            </Stack>
        </Tooltip>
    );
};

export default AccessToTalentLabel;
