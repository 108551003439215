import { configureStore } from "@reduxjs/toolkit";
import allReducer from "reducers";

const store = configureStore(
    { reducer: allReducer },
    // chrome redux devtools extension
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
