export const SUBSCRIPTION_PLANS = {
    STANDARD: {
        id: 1,
        name: "Standard plan",
    },
    PRO: {
        id: 2,
        name: "Pro plan",
    },
    ENTERPRICE: {
        id: 3,
        name: "Enterprise plan",
    },
};

export const COMPANY_SERVICES = {
    ACCESS_TO_TALENT: {
        id: 1,
        name: "Access to talent",
    },
    JOB_BOARD: {
        id: 2,
        name: "Job board",
    },
}
