import axios from "utils/axiosConfig";
import { useState, useEffect } from "react";
import { urlService } from "infra/envs/";

export const useFetchHfrJobs = () => {
    const jobsPath = urlService.HFR_JOBS_PATH;
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const [jobOptions, setJobOptions] = useState([]);

    useEffect(() => {
        const fetchHfrJobs = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(jobsPath);
                setJobOptions(response.data);
                setRequestSuccessful(true);
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchHfrJobs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isLoading, requestSuccessful, jobOptions };
};

export default useFetchHfrJobs;
