import { Stack, Typography, Avatar } from "@mui/material";
import Utils from "services/GeneralUtilityService";
import ReProCardService from "services/RecruitmentProccessCard.service";
import RpNoteForm from "../../../candidate-form/recruitment-processes/RpNoteForm";
import DialogBox from "components/lib-ui/DialogBox";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IconBtn from "components/lib-ui/IconBtn";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const RecruitmentProcessNotes = ({
    noteData,
    recruitmentProcessData,
}) => {
    const authorImageUrl = null;
    const authorName = `${noteData.createdBy.firstName} ${noteData.createdBy.lastName}`;
    const authorInitials = Utils.getStringInitals(authorName);
    const avatarBgColor = authorImageUrl
        ? "gray"
        : Utils.stringToColor(authorName);

    const currentUser = useSelector((state) => state.userIdSlice?.data);
    const createdBy = noteData.createdBy.id;

    const internalTabValue = "internal";
    const externalTabValue = "external";

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [selectedDialog, setSelectedDialog] = useState(null);

    const computeHasExternal = (obj) => !Utils.isObjectEmpty(obj);
    const computeActiveTab = (hasExternal) =>
        hasExternal ? externalTabValue : internalTabValue;

    const [hasExternal, setHasExternal] = useState(
        computeHasExternal(recruitmentProcessData?.external)
    );
    const [activeTab, setActiveTab] = useState(computeActiveTab(hasExternal));
    const [activeFlowTab, setActiveFlowTab] = useState(activeTab);

    useEffect(() => {
        const currentHasExternal = computeHasExternal(
            recruitmentProcessData?.external
        );
        setHasExternal(currentHasExternal);
        setActiveTab(computeActiveTab(currentHasExternal));
        setActiveFlowTab(computeActiveTab(hasExternal));
    }, [recruitmentProcessData, hasExternal]);

    const renderModalContent = (modalName) => {
        switch (modalName) {
            case "editNote":
                return (
                    <RpNoteForm
                        cardData={{
                            activeFlowTab: activeFlowTab,
                            ...recruitmentProcessData,
                            ...ReProCardService.getRecruitmentProcessStepOptions(
                                recruitmentProcessData
                            ),
                            noteData: noteData,
                        }}
                        setIsDialogOpen={setIsDialogOpen}
                        isEditMode={true}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Stack px={2} spacing={2}>
            <Stack spacing={1} direction="row" alignItems="center">
                <Avatar
                    alt="Candidate picture"
                    src={authorImageUrl}
                    loading="lazy"
                    sx={{
                        width: 30,
                        height: 30,
                        fontSize: ".8rem",
                        backgroundColor: avatarBgColor,
                    }}
                >
                    {authorInitials}
                </Avatar>
                <Typography>{authorName}</Typography>
                <Typography
                    fontSize=".9rem"
                    color="gray.dark"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    {`noted on `}
                    {ReProCardService.getRecruitmentProcessStateIcon(
                        noteData?.rpStepState?.name
                    )}
                    {`${noteData?.rpStepState?.name} / ${noteData?.category?.name} • `}
                    {Utils.getTimeDifferenceString(noteData?.createdAt)}
                </Typography>
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    sx={{
                        overflow: "hidden",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {noteData.body}
                </Typography>
                {currentUser === createdBy && (
                    <IconBtn
                        icon={EditOutlinedIcon}
                        sx={{ border: "unset" }}
                        onClick={() => {
                            setIsDialogOpen(true);
                            setSelectedDialog(renderModalContent("editNote"));
                            setDialogTitle("Edit note");
                        }}
                    ></IconBtn>
                )}
            </Stack>
            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title={dialogTitle}
                floatCancel={true}
                showActionButtons={false}
            >
                {selectedDialog}
            </DialogBox>
        </Stack>
    );
};

export default RecruitmentProcessNotes;
