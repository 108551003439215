import SectionCard from "../../SectionCard";
import SectionRow from "../../SectionRow";
import InlineFormText from "components/lib-ui/inline-forms/InlineFormText";
import InlineFormSelect from "components/lib-ui/inline-forms/InlineFormSelect";
import { GlobalAppDataStorageService } from "infra/storage";
import GeneralUtilityService from "services/GeneralUtilityService";
import AccessToTalentLabel from "components/lib-ui/AccessToTalentLabel";

export const Compensation = ({ candidate }) => {
    const {
        availability,
        grossAnnualSalary,
        netMontlySalary,
        noticePeriod,
        compensationPreferences,
    } = candidate;

    const availabilityToMonth =
        GeneralUtilityService.convertDateToMonthInput(availability);
    const compensationPreferencesOptions =
        GlobalAppDataStorageService.getCompensationPreferences();

    return (
        <>
            <SectionCard title="Compensation Preferences">
                <SectionRow
                    title={<AccessToTalentLabel label="Net monthly salary" />}
                >
                    <InlineFormText
                        fieldName="min_monthly_net_salary"
                        before="€"
                        type="number"
                        defaultValue={netMontlySalary}
                    />
                </SectionRow>
                <SectionRow
                    title={<AccessToTalentLabel label="Gross annual salary" />}
                >
                    <InlineFormText
                        fieldName="min_annual_gross_salary"
                        before="€"
                        type="number"
                        defaultValue={grossAnnualSalary}
                    />
                </SectionRow>
                <SectionRow title="Payment method">
                    <InlineFormSelect
                        multiple
                        fieldName="compensation_preferences"
                        options={compensationPreferencesOptions || []}
                        selected={compensationPreferences}
                    />
                </SectionRow>
                <SectionRow
                    title={
                        <AccessToTalentLabel label="Notice Period (weeks)" />
                    }
                >
                    <InlineFormText
                        fieldName="notice_period"
                        type="number"
                        defaultValue={noticePeriod}
                    />
                </SectionRow>
                <SectionRow title="Availability">
                    <InlineFormText
                        fieldName="availability"
                        type="month"
                        defaultValue={availabilityToMonth}
                    />
                </SectionRow>
            </SectionCard>
        </>
    );
};

export default Compensation;
