import { useState, useEffect, useRef } from "react";
import { Box, Button, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { keyframes } from "@emotion/react";

const hoverEffect = keyframes`
  0% { width: 0%; }
  50% { width: 30%; }
  100% { width: 0%; }
`;

export const MeteredButton = ({
    secondsToAction = 2,
    meterColor,
    onActionComplete = () => {},
    isDisabled = false,
    loading = false,
    children,
    buttonProps,
    buttonSx,
    meterSx,
}) => {
    const [progress, setProgress] = useState(0);
    const [isProgressRunning, setIsProgressRunning] = useState(false);
    const theme = useTheme();
    let animationFrameId;

    const buttonRef = useRef(null);

    // If meterColor is not defined, default to primary color from theme
    const getMeterColor = meterColor || theme.palette.danger.main;

    // Starts progress tracking
    const startProgress = (
        totalTimeInSeconds,
        startTime = performance.now()
    ) => {
        if (!isProgressRunning) return;
        animationFrameId = requestAnimationFrame((currentTimestamp) => {
            const elapsedTimeInSeconds = (currentTimestamp - startTime) / 1000;
            const newProgress =
                (elapsedTimeInSeconds / totalTimeInSeconds) * 100;

            if (elapsedTimeInSeconds > totalTimeInSeconds) {
                onActionComplete();
                return;
            }

            setProgress(newProgress);
            startProgress(totalTimeInSeconds, startTime);
        });
    };

    // Stops progress tracking
    const stopProgress = (
        remainingTime = 0.5,
        startTime = performance.now()
    ) => {
        cancelAnimationFrame(animationFrameId);
        requestAnimationFrame((currentTimestamp) => {
            const elapsedTimeInSeconds = (currentTimestamp - startTime) / 1000;
            const newProgress =
                progress - (progress / remainingTime) * elapsedTimeInSeconds;

            if (newProgress <= 0) {
                setProgress(0);
                return;
            }

            setProgress(newProgress);
            stopProgress(remainingTime, startTime);
        });
    };

    const handleMouseDown = () => setIsProgressRunning(true);
    const handleMouseUpOrLeave = () => setIsProgressRunning(false);

    useEffect(() => {
        const buttonElement = buttonRef.current;
        if (buttonElement) {
            buttonElement.addEventListener("mousedown", handleMouseDown);
            buttonElement.addEventListener("mouseup", handleMouseUpOrLeave);
            buttonElement.addEventListener("mouseleave", handleMouseUpOrLeave);
        }

        return () => {
            if (buttonElement) {
                buttonElement.removeEventListener("mousedown", handleMouseDown);
                buttonElement.removeEventListener(
                    "mouseup",
                    handleMouseUpOrLeave
                );
                buttonElement.removeEventListener(
                    "mouseleave",
                    handleMouseUpOrLeave
                );
            }
        };
    }, []);

    // Handle the start and stop progress based on user interactions
    useEffect(() => {
        if (isProgressRunning) startProgress(secondsToAction);
        else stopProgress();
        return () => cancelAnimationFrame(animationFrameId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProgressRunning]);

    const startIcon = loading ? (
        <CircularProgress size={16} />
    ) : (
        buttonProps.startIcon
    );

    return (
        <Button
            ref={buttonRef}
            onMouseLeave={() => setIsProgressRunning(false)}
            onMouseDown={() => setIsProgressRunning(true)}
            onMouseUp={() => setIsProgressRunning(false)}
            disabled={isDisabled || loading}
            sx={{
                position: "relative",
                zIndex: "1",
                "&:hover .meter-bar::after": {
                    animation: `${hoverEffect} 1.3s cubic-bezier(0.34, 1.56, 0.64, 1) forwards`,
                },
                "& .MuiCircularProgress-root": {
                    color: theme.palette.gray.medium,
                },
                ...buttonSx,
            }}
            disableRipple
            {...buttonProps}
            startIcon={startIcon}
        >
            <Box sx={{ position: "relative", zIndex: 1 }}>{children}</Box>
            {/* Meter-bar */}
            <Box
                className="meter-bar"
                sx={{
                    borderRadius: "4px",
                    position: "absolute",
                    zIndex: "-1",
                    overflow: "hidden",
                    height: "100%",
                    width: "100%",
                    left: "0",
                    top: "0",
                    "&::after": {
                        content: '""',
                        display: "block",
                        background: getMeterColor,
                        width: "0%",
                        height: "100%",
                        position: "absolute",
                        left: 0,
                    },
                    "&::before": {
                        content: '""',
                        display: "block",
                        background: getMeterColor,
                        width: `${Math.ceil(progress)}%`,
                        height: "100%",
                        position: "absolute",
                        left: 0,
                    },
                    ...meterSx,
                }}
            ></Box>
        </Button>
    );
};

export default MeteredButton;
