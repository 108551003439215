import { useTheme } from "@mui/material/styles";
import { Box, Typography, Stack, Tooltip } from "@mui/material";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";

const renderAlgorithmicIcon = (iconColor) => (
    <Tooltip
        followCursor
        title="Data displayed is generated by an algorithm 🤖"
    >
        <SmartToyOutlinedIcon sx={{ color: iconColor }} fontSize="small" />
    </Tooltip>
);

export const ParsedDataRow = ({
    title,
    children,
    titleStyles,
    isHidden,
    dataSourceType,
}) => {
    const theme = useTheme();
    const isAlgorithmicSource = dataSourceType === "algorithm";

    const renderChildren = () => {
        if (isAlgorithmicSource) {
            return (
                <Tooltip
                    placement="left"
                    title="Data displayed is generated by an algorithm 🤖"
                >
                    {children}
                </Tooltip>
            );
        }
        return children;
    };

    return (
        <Box
            sx={{
                display: isHidden ? "none" : "block",
                "& .parsed-data-row": {
                    display: "grid",
                    gridTemplateColumns: "180px 1fr",
                    alignItems: "center",
                },
                "&:not(:last-child) .parsed-data-row": {
                    borderBottom: "1px solid",
                    borderColor: theme.palette.gray.soft,
                },
                "& .parsed-data-title-column": {
                    width: "300px",
                    borderRight: "1px solid",
                    borderColor: theme.palette.gray.soft,
                },
            }}
        >
            <Stack
                direction="row"
                alignItems="flex-start"
                className="parsed-data-row"
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        boxSizing: "border-box",
                        py: 2,
                        pr: 2,
                        borderRight: "1px solid lightgray",
                        height: "100%",
                    }}
                >
                    <Typography
                        align="left"
                        color="black"
                        variant="subtitle2"
                        sx={titleStyles}
                    >
                        {title}
                    </Typography>
                    {isAlgorithmicSource &&
                        renderAlgorithmicIcon(theme.palette.cyan.main)}
                </Stack>
                {renderChildren()}
            </Stack>
        </Box>
    );
};

export default ParsedDataRow;
