import { useEffect } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAttachJobs } from "hooks/useRecruitmentProcess";
import SaveIcon from "@mui/icons-material/Save";
import HorizontalFlex from "components/lib-ui/HorizontalFlex";
import Autocomplete from "@mui/material/Autocomplete";
import useFetchHfrJobs from "hooks/useFetchHfrJobs";

export const AttachJob = ({ title, setIsDialogOpen, cardData }) => {
    const candidate = useSelector((state) => state.currentCandidate);

    const { jobOptions } = useFetchHfrJobs();
    const { attachJobs, isLoading, requestSuccessful } = useAttachJobs();
    const { handleSubmit, control } = useForm();

    useEffect(() => {
        if (requestSuccessful) {
            setIsDialogOpen(false);
        }
    }, [requestSuccessful, setIsDialogOpen]);

    const submitForm = async (data) => {
        if (!isLoading) {
            const endpointparams = {
                candidateId: candidate.id,
                recruitmentProccessId: cardData.id,
            };
            const jobsIds = data.job?.map((item) => item.id);
            await attachJobs(endpointparams, { jobs: jobsIds });
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={2}>
                <Typography variant="header2">{title}</Typography>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
                <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                    <Controller
                        name="job"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <Autocomplete
                                id="job"
                                fullWidth
                                multiple
                                autoComplete
                                loading
                                filterSelectedOptions
                                options={jobOptions || []}
                                getOptionLabel={(option) =>
                                    option && option.company_name !== null
                                        ? `${option.id}. ${option.headline} @ ${option.company_name}`
                                        : `${option.id}. ${option.headline}`
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Job" />
                                )}
                            />
                        )}
                    />
                </HorizontalFlex>
            </Box>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    color="gray"
                    variant="text"
                    onClick={() => {
                        setIsDialogOpen(false);
                    }}
                >
                    Cancel
                </Button>

                <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                >
                    Save changes
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default AttachJob;
