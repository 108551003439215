import CollegeLinkIcon from "assets/images/CollegeLinkIcon";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PersonAddDisabledOutlinedIcon from "@mui/icons-material/PersonAddDisabledOutlined";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Utils from "services/GeneralUtilityService";

export const RP_CATEGORIES = {
    INTERNAL_GENERAL_SCREENING_ID: 1,
    INTERNAL_SOURCING_ID: 2,
    CLIENT_SCREENING_ID: 3,
    INTERNAL_SPECIFIC_SCREENING_ID: 4,
};

class RecruitmentProccessCardService {
    constructor() {
        this.RP_FLOW_INTERNAL_CATEGORY_ID = 1; // "Internal General Screening"
        this.RP_FLOW_JOB_ATTACHED_CATEGORY_ID = 4; // "Internal Specific Screening"
        this.RP_FLOW_SHORTLISTED_CATEGORY_ID = 3; // "Client Screening"

        this.STATUS_SHORTLIST_ID = 13;
        this.STATUS_HIRED_ID = 14;
        this.STATE_OFFER = 24;

        this.STEP_STATUS_HIRED = 14;
    }

    /**
     * Filters out options with the specified status ID.
     * @param {Array} options - The array of options to be filtered.
     * @param {number} statusId - The status ID to be removed from the options.
     * @returns {Array} - The array of options with elements that have the specified status ID removed.
     */
    filterStatus(options, statusId) {
        return options.filter((option) => option.id !== statusId);
    }

    /**
     * Filters out options based on the specified conditions.
     * @param {Array} options - The array of options to be filtered.
     * @param {boolean} isInternalTabActive - Whether the internal tab is active.
     * @param {number} watchState - The current watch state.
     * @param {number} categoryId - The current recruitment process id.
     * @returns {Array} - The filtered array of options according to the conditions.
     */
    filterStatuses(options, isInternalTabActive, watchState, categoryId) {
        let filteredOptions = options;

        if (isInternalTabActive) {
            filteredOptions = this.filterStatus(
                filteredOptions,
                this.STATUS_SHORTLIST_ID
            );
        }

        if (watchState !== this.STATE_OFFER) {
            filteredOptions = this.filterStatus(
                filteredOptions,
                this.STATUS_HIRED_ID
            );
        }

        return this.filterOptionsByCategoryId(filteredOptions, categoryId);
    }

    iconStyle = {
        fontSize: "1.1rem",
    };

    stateIconsMap = {
        check: <CollegeLinkIcon {...this.iconStyle} />,
        assessment: <PersonSearchOutlinedIcon {...this.iconStyle} />,
        shortCall: <LocalPhoneOutlinedIcon {...this.iconStyle} />,
        messageSent: <SendOutlinedIcon {...this.iconStyle} />,
        scheduledInterview: <MarkChatUnreadOutlinedIcon {...this.iconStyle} />,
        interview: <MarkChatReadOutlinedIcon {...this.iconStyle} />,
        firstInterview: <SupervisorAccountOutlinedIcon {...this.iconStyle} />,
        secondInterview: <SupervisorAccountOutlinedIcon {...this.iconStyle} />,
        thirdInterview: <SupervisorAccountOutlinedIcon {...this.iconStyle} />,
        offer: <AttachMoneyOutlinedIcon {...this.iconStyle} />,
        view: <VisibilityIcon {...this.iconStyle} />,
    };

    statusIconsMap = {
        rejected: <PersonOffOutlinedIcon {...this.iconStyle} />,
        dropped: <PersonAddDisabledOutlinedIcon {...this.iconStyle} />,
        shortlisted: <HowToRegIcon {...this.iconStyle} />,
        hired: <HowToRegIcon {...this.iconStyle} />,
    };

    getRecruitmentProcessStateIcon(stateKey) {
        switch (stateKey) {
            case "1st Interview":
                stateKey = "first interview";
                break;
            case "2nd Interview":
                stateKey = "second interview";
                break;
            case "3rd Interview":
                stateKey = "third interview";
                break;
            default:
                break;
        }
        const state = Utils.formatToCamelCase(stateKey);
        return (
            this.stateIconsMap[state] || (
                <AdjustOutlinedIcon {...this.iconStyle} />
            )
        );
    }

    getRecruitmentProcessStatusIcon(statusKey) {
        const status = Utils.formatToCamelCase(statusKey);

        return this.statusIconsMap[status] || null;
    }

    getRecruitmentProcessScoreIcon(score) {
        if (score >= 9) return "🥰";
        if (score >= 7) return "😁";
        if (score >= 5) return "🙂";
        if (score >= 3) return "😰";
        return "😱";
    }

    /**
     * This function extracts all notes & a report/score pair from a recruitment process.
     * Each note will be returned with the corresponding step state.
     * @param {Object} recruitmentProcessData - The data of the recruitment process. It must include 'internal' and 'external' properties.
     * @property {Object} recruitmentProcessData.internal - The internal part of the recruitment process.
     * @property {Object} recruitmentProcessData.external - The external part of the recruitment process.
     * @returns {Array|null} An array of objects containing notes with their respective step state. If no notes are found, null is returned.
     */
    extractNotesFromRPSteps(recruitmentProcessData) {
        const { internal, external } = recruitmentProcessData;
        const rpFlowSteps = [
            ...(internal?.rpFlowSteps || []),
            ...(external?.rpFlowSteps || []),
        ];

        const report = () => {
            if (external?.report) {
                return {
                    body: external?.report,
                    score: external?.score,
                };
            }
        };

        if (!rpFlowSteps?.length) return null;

        const notesWithStepState = rpFlowSteps.flatMap(
            ({ rpNotes, rpStepState }) => {
                if (!rpNotes?.length) return [];
                return rpNotes.map((note) => ({ ...note, rpStepState }));
            }
        );

        // Sort by id
        notesWithStepState.sort((a, b) => {
            return b.id - a.id;
        });

        return {
            externalReport: report(),
            notes: notesWithStepState,
        };
    }

    /**
     * Get the current ID of the recruitment process based on the status of the recruitment.
     * @param {Object} recruitmentProcessData - The data of the recruitment process.
     * @param {boolean} recruitmentProcessData.shortlisted - Indicates whether the recruitment process is shortlisted.
     * @param {Object} recruitmentProcessData.external - Represents external properties of the recruitment process.
     * @returns {number} Returns ID of the recruitment process category based on the status.
     */
    getRecruitmentProccessCurrentId(recruitmentProcessData) {
        // * Rules to activate specific IDs based on user status:
        // * - ID 1: Active when the user has an 'internal' status (default condition).
        // * - ID 4: Active when the user is associated with a job.
        // * - ID 3: Active when the user has a job shortlisted.0
        const { shortlisted, external } = recruitmentProcessData;
        const isShortlisted = Boolean(shortlisted);
        const hasJobAttached = Boolean(
            external && Object.keys(external).length
        );
        if (isShortlisted) return this.RP_FLOW_SHORTLISTED_CATEGORY_ID;
        if (hasJobAttached) return this.RP_FLOW_JOB_ATTACHED_CATEGORY_ID;
        return this.RP_FLOW_INTERNAL_CATEGORY_ID;
    }

    filterOptionsByCategoryId(options, categoryId) {
        switch (categoryId) {
            case 1: // "Internal General Screening"
                return options.filter(
                    (option) =>
                        option.category.id === this.RP_FLOW_INTERNAL_CATEGORY_ID
                );
            case 4: // "Internal Specific Screening"
                return options.filter(
                    (option) =>
                        option.category.id ===
                        this.RP_FLOW_JOB_ATTACHED_CATEGORY_ID
                );
            case 3: // "Client Screening"
                return options.filter(
                    (option) =>
                        option.category.id ===
                        this.RP_FLOW_SHORTLISTED_CATEGORY_ID
                );
            default:
                throw new Error("No category ID matched");
        }
    }

    getRecruitmentProcessStepOptions(recruitmentProcessData) {
        const internalSteps =
            recruitmentProcessData?.internal?.rpFlowSteps?.map((each) => {
                return {
                    flowId: recruitmentProcessData.internal.id,
                    stepId: each.id,
                    id: each.rpStepState.id,
                    name: `${each.rpStepState.name} - Generic`,
                };
            });
        const externalSteps =
            recruitmentProcessData?.external?.rpFlowSteps?.map((each) => {
                return {
                    flowId: recruitmentProcessData.external.id,
                    stepId: each.id,
                    id: each.rpStepState.id,
                    name: `${each.rpStepState.name} - Job Specific`,
                };
            }) || [];
        return {
            recruitmentProcessStepOptions: [...internalSteps, ...externalSteps],
        };
    }

    getFlowLatestrpStepState(flow) {
        if (!flow && !Array.isArray(flow)) return;
        return [...flow.rpFlowSteps].pop()?.rpStepState;
    }

    /**
     * NOTICE:
     *
     * The methods `transformRpStatesForFilters` and `transformRpStatusesForFilters` were designed to address the
     * challenge of presenting multiple backend options (belonging to different categories)
     * as a single unified option to the user.
     *
     * For instance, an option like "Assessment" might exist multiple times in the backend
     * across different categories. However, from a user's perspective, they see and select
     * it as one option. When filtering based on this selection, it's crucial to send all
     * associated IDs from the backend categories (e.g., "Internal General Screening" and
     * "Internal Specific Screening") to fetch the correct data.
     *
     * These functions, therefore, serve to map all options and extract the necessary IDs
     * for each grouped selection, ensuring accurate data retrieval.
     */

    /**
     * Transforms the provided options to align with Product specifications.
     * This function enhances the user experience by:
     * - Grouping related selections.
     * - Ensuring option uniqueness.
     * - Concealing options related to features still under development.
     * @param {Array} options - The original options to be transformed.
     * @returns {Array} - The transformed options.
     */
    transformRpStatesForFilters(options = []) {
        // Remove internal sourcing options, as they include
        // features still WIP
        const removeInternalSourcing = options.filter(
            (option) =>
                option.category.id !== RP_CATEGORIES.INTERNAL_SOURCING_ID
        );

        // Change the names according to our user's need (e.g., internal - client, etc.)
        const mutatedOptions = removeInternalSourcing.map((item) => {
            let newItem = { ...item };
            switch (newItem.category.id) {
                case RP_CATEGORIES.INTERNAL_SPECIFIC_SCREENING_ID:
                case RP_CATEGORIES.INTERNAL_GENERAL_SCREENING_ID:
                case RP_CATEGORIES.INTERNAL_SOURCING_ID:
                    newItem.state.name += " - Internal";
                    break;
                case RP_CATEGORIES.CLIENT_SCREENING_ID:
                    newItem.state.name += " - Client";
                    break;
                default:
                    break;
            }
            return newItem;
        });

        // Make each option only appear once and collect the outer ids
        const uniqueStates = mutatedOptions.reduce((acc, item) => {
            const existingState = acc.find(
                (existingItem) =>
                    existingItem.state.id === item.state.id &&
                    existingItem.state.name === item.state.name
            );
            if (existingState) {
                existingState.ids.push(item.id);
            } else {
                acc.push({
                    ...item,
                    ids: [item.id],
                    name: item.state.name,
                });
            }
            return acc;
        }, []);

        return uniqueStates;
    }

    /**
     * Transforms the provided options to align with Product specifications.
     * This function enhances the user experience by:
     * - Grouping related selections.
     * - Ensuring option uniqueness.
     * - Concealing options related to features still under development.
     * @function
     * @param {Array} options - The list of RP status options to transform.
     * @returns {Array} The transformed list of RP status options.
     */
    transformRpStatusesForFilters(options = []) {
        // Remove internal sourcing options, as they include
        // features still WIP
        const removeInternalSourcing = options.filter(
            (option) =>
                option.category.id !== RP_CATEGORIES.INTERNAL_SOURCING_ID
        );

        // Change the names according to ours user's need!( eg. internal - client etc )
        const mutatedOptions = removeInternalSourcing.map((item) => {
            let newItem = { ...item };
            switch (newItem.category.id) {
                case RP_CATEGORIES.INTERNAL_SPECIFIC_SCREENING_ID:
                case RP_CATEGORIES.INTERNAL_GENERAL_SCREENING_ID:
                case RP_CATEGORIES.INTERNAL_SOURCING_ID:
                    newItem.status.name += " - Internal";
                    break;
                case RP_CATEGORIES.CLIENT_SCREENING_ID:
                    newItem.status.name += " - Client";
                    break;
                default:
                    break;
            }
            return newItem;
        });

        // Make each option only appear once and collect the outer ids
        const uniqueStatuses = mutatedOptions.reduce((acc, item) => {
            const existingStatus = acc.find(
                (existingItem) =>
                    existingItem.status.id === item.status.id &&
                    existingItem.status.name === item.status.name
            );
            if (existingStatus) {
                existingStatus.ids.push(item.id);
            } else {
                acc.push({
                    ...item,
                    ids: [item.id],
                    name: item.status.name,
                });
            }
            return acc;
        }, []);

        return uniqueStatuses;
    }

    /**
     * Checks if the user has a hired status based on deeply nested properties of the recruitment process object.
     *
     * @function
     * @param {Object} reproData - The recruitment process object .
     * @returns {boolean} Returns true if the last status ID matches STEP_STATUS_HIRED, otherwise returns false.
     */
    isHired(reproData) {
        const external = reproData?.external;
        const steps = external?.rpFlowSteps;
        // Get the last element of the 'steps' array
        // since the newest step will always be last
        const lastStep = steps?.[steps.length - 1];
        const statuses = lastStep?.rpStepStatuses;
        // Get the first element of the 'statuses' array
        // since the newest status will always be first
        const lastStatus = statuses?.[0];
        return lastStatus?.rpStepStatus?.id === this.STEP_STATUS_HIRED;
    }
}

const service = new RecruitmentProccessCardService();
export default service;
