import { Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CompanyTopSection from "./CompanyTopSection";
import Tabs from "./Tabs";
import { useEffect } from "react";
import axios from "utils/axiosConfig";
import { urlService } from "infra/envs";
import { currentCompany } from "reducers/company/companySlice";
import { useDispatch } from "react-redux";

export const ViewCompany = ({ companyId }) => {
    const theme = useTheme();

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const response = await axios.get(
                    `${urlService.COMPANY_SUBSCRIPTIONS_PATH}${companyId}`
                );

                dispatch(currentCompany(response.data));
            } catch (e) {
                console.log(e);
            }
        };
        fetchCompanyData();
    }, [companyId, dispatch]);

    return (
        <Box
            sx={{
                background: theme.palette.gray.light,
                height: "100%",
            }}
        >
            <CompanyTopSection
                sx={{
                    padding: "1rem 2rem",
                    width: "100%",
                    background: "white",
                }}
            />
            <Divider />
            <Tabs />
        </Box>
    );
};

export default ViewCompany;
