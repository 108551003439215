import axios from "utils/axiosConfig";
import { useState } from "react";
import { urlService } from "infra/envs/";
import useNotifications from "hooks/useNotifications";
import Utils from "services/GeneralUtilityService";
import { setCurrentCandidateHistory } from "reducers/candidate/currentCandidateSlice";
import { useDispatch } from "react-redux";

export const useHistory = () => {
    const [isCandidateHistoryFetchLoading, setIsCandidateHistoryFetchLoading] =
        useState(false);

    const { renderErrorNotification } = useNotifications();
    const dispatch = useDispatch();

    const getCandidateHistory = async (id) => {
        setIsCandidateHistoryFetchLoading(true);
        try {
            const response = await axios.get(
                `${urlService.CANDIDATE_HISTORY_PATH}/${id}`
            );
            const candidateHistory = response.data;
            dispatch(setCurrentCandidateHistory(candidateHistory));
            return candidateHistory;
        } catch (error) {
            const errorMessage = Utils.extractErrorMessage(error.response.data);
            renderErrorNotification(errorMessage);
        } finally {
            setIsCandidateHistoryFetchLoading(false);
        }
    };

    return {
        getCandidateHistory,
        isCandidateHistoryFetchLoading,
    };
};
