import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Typography, Button, Divider, Stack } from "@mui/material";
import { addSearchFilters } from "reducers/candidate/searchFiltersDataSlice";
import removeEmptyProperties from "utils/removeEmptyProperties";
import SpecificCandidateFilters from "./SpecificCandidateFilters";
import RecruitmentProcessFilters from "./RecruitmentProcessFilters";
import PoolCreationFilters from "./PoolCreationFilters";

const extractIds = (dataArray) =>
    dataArray.flatMap((item) => (item.id ? [item.id] : []));

export const Filters = () => {
    const {
        control,
        handleSubmit,
        register,
        watch,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const formData = {
        control,
        handleSubmit,
        register,
        watch,
        reset,
        setValue,
        getValues,
        errors,
    };
    const dispatch = useDispatch();

    //Clean keys not needed in the back
    const tidyUpFormData = (formData) => {
        const cleanedData = removeEmptyProperties(formData);
        return cleanedData;
    };

    const onSubmit = (filterData) => {
        const {
            currentStateStatus,
            yearOfExperienceMinimum,
            yearOfExperienceMaximum,
            salaryMinimum,
            salaryMaximum,
        } = filterData;

        if (yearOfExperienceMinimum || yearOfExperienceMaximum) {
            filterData.years_of_experience = [
                yearOfExperienceMinimum || 0, //If no min value is set, default to 0
                yearOfExperienceMaximum || 100, //If no max value is set, default to 100
            ];
        }

        if (salaryMinimum || salaryMaximum) {
            filterData.salary = [
                salaryMinimum || 0, //If no min value is set, default to 0
                salaryMaximum || 999999, //If no max value is set, default to 999999
            ];
        }

        filterData.rp_statuses = filterData.rp_statuses.flatMap(
            (item) => item.ids
        );

        filterData.rp_states = filterData.rp_states.flatMap((item) => item.ids);
        filterData.currentStateStatus = Number(currentStateStatus);

        filterData.preferredIndustries = extractIds(
            filterData.preferredIndustries
        );
        filterData.preferredJobFunctions = extractIds(
            filterData.preferredJobFunctions
        );
        filterData.preferredOccupations = extractIds(
            filterData.preferredOccupations
        );
        filterData.preferredSkills = extractIds(filterData.preferredSkills);

        removeEmptyProperties(filterData);

        const preparedData = tidyUpFormData(filterData);

        dispatch(addSearchFilters(preparedData));
    };

    const handleDynamicSubmitForm = (event) => {
        event.preventDefault();
        handleSubmit(onSubmit)();
        event.target.blur();
        if (document.activeElement) document.activeElement.blur();
    };

    return (
        <form onSubmit={handleDynamicSubmitForm}>
            <Stack
                direction="column"
                px={2}
                py={3}
                spacing={2}
                sx={{ position: "relative" }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="header1" component="h3">
                        Filters
                    </Typography>
                    <Button
                        sx={{
                            textTransform: "none",
                            padding: 0,
                        }}
                        variant="text"
                        onClick={(event) => {
                            reset();
                            handleDynamicSubmitForm(event);
                        }}
                    >
                        Clear all
                    </Button>
                    <Button sx={{ display: "none" }} type="submit"></Button>
                </Stack>

                <Stack spacing={3}>
                    <PoolCreationFilters
                        handleSubmit={handleDynamicSubmitForm}
                        formData={formData}
                    />

                    <Divider />

                    <SpecificCandidateFilters
                        handleSubmit={handleDynamicSubmitForm}
                        formData={formData}
                    />

                    <Divider />

                    <RecruitmentProcessFilters
                        handleSubmit={handleDynamicSubmitForm}
                        formData={formData}
                    />
                </Stack>
            </Stack>
        </form>
    );
};

export default Filters;
