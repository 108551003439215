import Box from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MainNavigationItem from "./MainNavigationItem";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import WorkIcon from "@mui/icons-material/Work";
import SendIcon from "@mui/icons-material/Send";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

export default function MainNavigation({ isSidebarOpen }) {
    return (
        <Box sx={{ padding: "10px", paddingTop: "30px" }}>
            <MenuList>
                <MainNavigationItem
                    to="/"
                    text="Candidates"
                    icon={<LocalFireDepartmentOutlinedIcon />}
                    activeIcon={<LocalFireDepartmentIcon />}
                    isSidebarOpen={isSidebarOpen}
                />
                <MainNavigationItem
                    to="/companies"
                    text="Companies"
                    icon={<WorkOutlineIcon />}
                    activeIcon={<WorkIcon />}
                    isSidebarOpen={isSidebarOpen}
                />
                <MainNavigationItem
                    to="/requests"
                    text="Linq requests"
                    icon={<SendOutlinedIcon />}
                    activeIcon={<SendIcon />}
                    isSidebarOpen={isSidebarOpen}
                />
            </MenuList>
        </Box>
    );
}
