import React from "react";
import { Box } from "@mui/system";

export const HorizontalFlex = ({ sx: parentStyles, children }) => {
    return (
        <Box sx={{ display: "flex", gap: 3, ...parentStyles }}>{children}</Box>
    );
};

export default HorizontalFlex;
