import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useHistory } from "hooks/useHistory";
import { useSelector } from "react-redux";

export const History = () => {
    const history = useSelector((state) => state.currentCandidate?.history);

    const { isCandidateHistoryFetchLoading } = useHistory();

    const columns = [
        { field: "id", headerName: "Id", width: 70 },
        { field: "email", headerName: "Email", width: 200 },
        { field: "action", headerName: "Action", width: 250 },
        { field: "desc", headerName: "Desc", width: 500 },
        { field: "created_at", headerName: "Action ts", width: 200 },
    ];

    if (isCandidateHistoryFetchLoading === true || !history) {
        return <CircularProgress />;
    }

    return (
        <Box
            sx={{
                maxWidth: "1200px",
            }}
        >
            <div style={{ height: 600, width: "100%" }}>
                <DataGrid
                    rows={history}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    columnVisibilityModel={{
                        id: false,
                    }}
                />
            </div>
        </Box>
    );
};

export default History;
