import { useState } from "react";
import { Paper, Typography, Avatar, Tooltip, Stack } from "@mui/material";
import { Box } from "@mui/system";
import Divider from "@mui/material/Divider";
import IconContent from "../../../components/lib-ui/IconContent";
import Chip from "@mui/material/Chip";
import IconBtn from "../../../components/lib-ui/IconBtn";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlaceholderText from "./PlaceholderText";
import GeneralUtilityService from "services/GeneralUtilityService";
import DialogBox from "components/lib-ui/DialogBox";
import ExperienceForm from "../candidate-form/ExperienceForm";
import { formatDistance } from "date-fns";

export const ExperienceCard = ({ experience }) => {
    const { endAt, updated_at } = experience;
    const updatedDate = new Date(updated_at);
    const timeDiff = formatDistance(updatedDate, new Date());
    const companyName = experience?.company?.name;
    const [dialogOpen, setDialogOpen] = useState(false);
    const yearsOfExperience =
        GeneralUtilityService.calculateYearsAndMonthsBetweenDates(
            experience.startAt,
            experience.endAt
        );

    const companyInitials = GeneralUtilityService.getStringInitals(companyName);
    const toolTipData = `Conpany size: 
                         Company industry:
                         Team size:${experience?.teamSize}`;

    const composeJobTypeWorkingModel = (jobType, workingModel) => {
        if (jobType && workingModel !== null) {
            return `${jobType.name}, ${workingModel.name}`;
        }
        if (jobType === null && workingModel === null) {
            return null;
        }
        if (jobType === null && workingModel) {
            return workingModel.name;
        }
        if (workingModel === null && jobType) {
            return jobType.name;
        }
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    return (
        <Paper
            elevation={3}
            sx={{
                borderRadius: "10px",
                width: "600px",
                minWidth: "600px",
                height: "fit-content",
            }}
        >
            <Box
                p={2}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                }}
            >
                <IconBtn
                    defaulted={true}
                    sx={{
                        top: 0,
                        right: 0,
                        border: "unset",
                        alignSelf: "start",
                    }}
                    icon={EditOutlinedIcon}
                    iconStyles={{ fontSize: "1.2rem" }}
                    onClick={handleDialogOpen}
                ></IconBtn>

                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Tooltip title={toolTipData} placement="top-start">
                        <Avatar
                            alt="Candidate picture"
                            loading="lazy"
                            sx={{
                                width: 47,
                                height: 47,
                                fontSize: "1rem",
                                backgroundColor:
                                    GeneralUtilityService.stringToColor(
                                        companyInitials
                                    ),
                                zIndex: 1,
                            }}
                        >
                            <div
                                display="grid"
                                color="red"
                                backgroundcolor="red"
                            >
                                {companyInitials}
                            </div>
                        </Avatar>
                    </Tooltip>

                    <Stack direction="column">
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 400 }}
                            >
                                {experience?.jobTitle}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 600 }}
                            >
                                {`@ ${experience?.company?.name}`}
                            </Typography>
                            {endAt === null && <Chip label="Currently" />}
                        </Stack>
                        <Typography color="gray.medium">
                            {timeDiff} ago
                        </Typography>
                    </Stack>
                </Box>
            </Box>

            <Divider />
            <Box>
                <IconContent
                    text={
                        endAt === null ? (
                            <span>
                                <strong> {yearsOfExperience.years}</strong>{" "}
                                year(s) and{" "}
                                <strong>{yearsOfExperience.months}</strong>{" "}
                                month(s) of experience • {experience.startAt} -
                                today
                            </span>
                        ) : (
                            <span>
                                <strong>{yearsOfExperience.years}</strong>{" "}
                                year(s) and{" "}
                                <strong>{yearsOfExperience.months}</strong>{" "}
                                month(s) of experience • {experience.startAt} -
                                {experience.endAt}
                            </span>
                        )
                    }
                    sx={{ px: 2, py: 1 }}
                    icon={InfoOutlinedIcon}
                    tooltipText={"Years of experience & start-end date"}
                    tooltipPlacement={"left"}
                />
                <IconContent
                    sx={{ px: 2, py: 1 }}
                    icon={ExtensionOutlinedIcon}
                    tooltipText={"Main Hard Skills"}
                    tooltipPlacement={"left"}
                >
                    <Box gap={2} sx={{ display: "flex", flexWrap: "wrap" }}>
                        {experience?.skills?.map((each, index) => (
                            <Chip key={index} label={each.name} />
                        ))}
                    </Box>
                </IconContent>

                <IconContent
                    sx={{ px: 2, py: 1 }}
                    icon={FactCheckOutlinedIcon}
                    tooltipText={"Main Responsibilities"}
                    tooltipPlacement={"left"}
                >
                    <Box gap={2} sx={{ display: "flex", flexWrap: "wrap" }}>
                        <List>
                            {experience?.responsibilities?.map(
                                (responsibility, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{ paddingLeft: 0, paddingTop: 0 }}
                                    >
                                        <Typography
                                            color="gray.dark"
                                            fontSize=".9rem"
                                        >
                                            • {responsibility?.name}
                                        </Typography>
                                    </ListItem>
                                )
                            )}
                        </List>
                    </Box>
                </IconContent>

                <IconContent
                    text={
                        experience?.jobDescription ? (
                            experience?.jobDescription
                        ) : (
                            <PlaceholderText returnOnlyText={true} />
                        )
                    }
                    sx={{ px: 2, py: 1 }}
                    icon={StarsOutlinedIcon}
                    tooltipText={"Job description"}
                    tooltipPlacement={"left"}
                />

                {/* Sectors */}
                <IconContent
                    sx={{ px: 2, py: 1 }}
                    icon={ExtensionOutlinedIcon}
                    tooltipText={"Job Functions"}
                    tooltipPlacement={"left"}
                >
                    <Box gap={2} sx={{ display: "flex", flexWrap: "wrap" }}>
                        {experience?.job_functions?.map((each, index) => (
                            <Chip
                                key={index}
                                color="greenCyan"
                                label={each.name}
                            />
                        ))}
                    </Box>
                </IconContent>
                <IconContent
                    sx={{ px: 2, py: 1 }}
                    icon={ExtensionOutlinedIcon}
                    tooltipText={"Occupations"}
                    tooltipPlacement={"left"}
                >
                    <Box gap={2} sx={{ display: "flex", flexWrap: "wrap" }}>
                        {experience?.occupations?.map((each, index) => (
                            <Chip
                                key={index}
                                color="primary"
                                label={each.name}
                            />
                        ))}
                    </Box>
                </IconContent>
                <IconContent
                    text={
                        experience?.jobChangeReason?.name ? (
                            experience?.jobChangeReason?.name
                        ) : (
                            <PlaceholderText returnOnlyText={true} />
                        )
                    }
                    sx={{ px: 2, py: 1 }}
                    icon={InfoOutlinedIcon}
                    tooltipText={"Reason for job change"}
                    tooltipPlacement={"left"}
                />
                <IconContent
                    text={composeJobTypeWorkingModel(
                        experience?.jobType,
                        experience?.workingModel
                    )}
                    sx={{ px: 2, py: 1 }}
                    icon={InfoOutlinedIcon}
                    tooltipText={"Job type & working model"}
                    tooltipPlacement={"left"}
                />
            </Box>

            {/* Edit Experience Popup */}
            <DialogBox
                isDialogOpen={dialogOpen}
                setIsDialogOpen={setDialogOpen}
                title="Edit candidate experience"
                floatCancel={true}
                showActionButtons={false}
            >
                <ExperienceForm
                    edit
                    setIsDialogOpen={setDialogOpen}
                    experience={experience}
                />
            </DialogBox>
        </Paper>
    );
};

export default ExperienceCard;
