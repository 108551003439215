import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useTokenAuth from "./useTokenAuth";

const RequireAuth = ({ children }) => {
    const auth = useSelector((state) => state.userValues);

    // authenticate token with api call
    useTokenAuth();

    if (!auth.user) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default RequireAuth;
