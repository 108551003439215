import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const searchFiltersDataSlice = createSlice({
    name: "searchFiltersData",
    initialState,
    reducers: {
        addSearchFilters: (_state, action) => {
            return {
                ...action.payload,
            };
        },
        clearSearchFilters: () => {
            return {};
        },
    },
});

export const { addSearchFilters, clearSearchFilters } =
    searchFiltersDataSlice.actions;
export default searchFiltersDataSlice.reducer;
