import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showModal: false,
};

const authModalSlice = createSlice({
    name: "authModal",
    initialState,
    reducers: {
        openModal: (state) => {
            state.showModal = true;
        },
        closeModal: (state) => {
            state.showModal = false;
        },
    },
});

export const { openModal, closeModal } = authModalSlice.actions;
export default authModalSlice.reducer;
