import React, { useRef } from "react";
import { Box, IconButton, Stack, Button, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import { useNavigate } from "react-router-dom";
import { useParser } from "hooks/useParser";

export const ParseCvForm = ({ setIsDialogOpen }) => {
    const navigate = useNavigate();
    const cvRef = useRef();
    const {
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const { parsePDF, isLoading } = useParser();

    const submitForm = async () => {
        const cv = cvRef.current.files[0];

        if (!cv) return;

        if (cv.type !== "application/pdf") {
            setError("cv_pdf", {
                type: "manual",
                message: "CV should be in PDF format",
            });
            return;
        }

        const formData = new FormData();
        formData.append("cv_pdf", cv);

        await parsePDF(formData);

        setIsDialogOpen(false);
        navigate("/candidate/add/parse-cv");
    };

    return (
        <Box mt={3} component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={6}>
                <TextField
                    name="cv_pdf"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.cv_pdf)}
                    helperText={errors.cv_pdf ? errors.cv_pdf.message : ""}
                    InputProps={{
                        accept: "application/pdf",
                        type: "file",
                        inputRef: cvRef,
                        endAdornment: cvRef.current &&
                            cvRef.current.files[0] && (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Clear"
                                        onClick={() => {
                                            cvRef.current.value = "";
                                        }}
                                    >
                                        <ClearIcon
                                            color="grey"
                                            fontSize="14px"
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                    }}
                    label="Upload CV"
                    onChange={() => {
                        if (cvRef.current.files[0].type !== "application/pdf") {
                            setError("cv_pdf", {
                                type: "manual",
                                message: "CV should be in PDF format",
                            });
                        }
                    }}
                />
            </Box>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    color="gray"
                    variant="text"
                    onClick={() => {
                        setIsDialogOpen(false);
                    }}
                >
                    Cancel
                </Button>

                <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<ImportExportOutlinedIcon />}
                >
                    Parse PDF
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default ParseCvForm;
