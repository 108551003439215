// Utility function to remove empty properties from an object
export const removeEmptyProperties = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
        if (
            value === "" ||
            value === undefined ||
            (Array.isArray(value) && value.length === 0)
        ) {
            delete obj[key];
        }
    }
    return obj;
};

export default removeEmptyProperties;
