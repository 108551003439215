import axios from "utils/axiosConfig";
import { useState } from "react";
import { EnvService } from "infra/envs";
import useNotifications from "./useNotifications";

export const useInsertUniversity = () => {
    const [isLoadingInsertUniversity, setIsLoadingInsertUniversity] =
        useState(false);

    const [
        insertUniversityRequestSuccessful,
        setInsertUniversityRequestSuccessful,
    ] = useState(false);

    const { renderNotification, renderErrorNotification } = useNotifications();

    const insertUniversity = async (data) => {
        const endpoint = EnvService.getBackEndBaseUrl() + "/api/universities";
        const universityData = {
            name: data?.new_university,
        };
        try {
            const response = await axios.post(endpoint, universityData);
            const newUniversityId = response?.data?.id;
            setInsertUniversityRequestSuccessful(true);
            renderNotification("University inserted successfully");
            setIsLoadingInsertUniversity(false);
            return newUniversityId;
        } catch (error) {
            setInsertUniversityRequestSuccessful(false);
            renderErrorNotification(`Error updating university: ${error}`);
            console.error("Error updating university:", error);
            setIsLoadingInsertUniversity(false);
            throw new Error("Error updating university");
        }
    };
    return {
        insertUniversity,
        isLoadingInsertUniversity,
        insertUniversityRequestSuccessful,
    };
};
export default useInsertUniversity;
