import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { Autocomplete, Box, InputLabel, Stack, TextField } from "@mui/material";
import ToggleContent from "components/lib-ui/ToggleContent";
import { Controller } from "react-hook-form";
import { GlobalAppDataStorageService } from "infra/storage";
import { useTheme } from "@mui/material/styles";
import useFetchCities from "hooks/useFetchCities";
import GeneralUtilityService from "services/GeneralUtilityService";
import { useDispatch, useSelector } from "react-redux";
import { resetCitiesOptions } from "reducers/candidate/citiesSlice";
import ExperienceFilters from "./ExperienceFilters";
import PreferredFilters from "./PreferredFilters";

export const PoolCreationFilters = ({ handleSubmit, formData }) => {
    const { fetchCities, citiesFetching } = useFetchCities();
    const { control, watch, getValues } = formData;
    const theme = useTheme();
    const countryOptions = GlobalAppDataStorageService.getCountries();
    const cityOptions = useSelector((state) => state.citiesSlice);

    const selectedCountry = watch("country_id");
    const hasCountry = GeneralUtilityService.isObjectEmpty(selectedCountry);
    const dispatch = useDispatch();

    const debouncedFetchCitiesOptions = GeneralUtilityService.debounce(
        async (countryID, q) => {
            await fetchCities(countryID, q);
        },
        1000
    );

    const handleInputChange = (event, value, reason) => {
        if (reason === "input") {
            const selectedCountryId = selectedCountry?.id;
            debouncedFetchCitiesOptions(selectedCountryId, value);
        }
    };

    const handleInputSubmit = (event) => {
        const filterData = getValues();

        const { salaryMinimum, salaryMaximum } = filterData;

        if (salaryMinimum || salaryMaximum) {
            filterData.preferredSalary = [
                salaryMinimum || 0, //If no min value is set, default to 0
                salaryMaximum || 999999, //If no max value is set, default to 999999
            ];
        }

        handleSubmit(event);
    };

    return (
        <>
            <ToggleContent
                title="Pool creation"
                icon={<FormatListBulletedOutlinedIcon fontSize="small" />}
            >
                <Stack spacing={1}>
                    <Stack
                        direction="column"
                        sx={{
                            backgroundColor: theme.palette.grey[100],
                        }}
                    >
                        <Controller
                            name="country_id"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                                <Autocomplete
                                    id="country_id"
                                    fullWidth
                                    limitTags={1}
                                    options={countryOptions || []}
                                    getOptionLabel={(option) => option.name}
                                    value={field.value || undefined}
                                    isOptionEqualToValue={(option, value) =>
                                        option.name === value.name
                                    }
                                    {...field}
                                    onChange={(event, newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            variant="standard"
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="city"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                                <Autocomplete
                                    id="city"
                                    multiple
                                    fullWidth
                                    autoComplete
                                    filterSelectedOptions
                                    options={cityOptions}
                                    loading={citiesFetching}
                                    disabled={hasCountry}
                                    onClose={() =>
                                        dispatch(resetCitiesOptions())
                                    }
                                    onInputChange={handleInputChange}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) =>
                                        option.name === value.name
                                    }
                                    {...field}
                                    onChange={(event, newValue) => {
                                        field.onChange(newValue);
                                        handleInputSubmit(event);
                                        dispatch(resetCitiesOptions());
                                    }}
                                    renderInput={(params) => (
                                        <div>
                                            <InputLabel htmlFor="city">
                                                City
                                            </InputLabel>
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="Start typing..."
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                        />
                    </Stack>

                    <Box
                        sx={{
                            border: `2px solid ${theme.palette.grey[100]}`,
                            p: 1,
                            borderRadius: "10px",
                        }}
                    >
                        <Box>
                            <ExperienceFilters
                                handleSubmit={handleInputSubmit}
                                formData={formData}
                            />
                        </Box>

                        <Box sx={{ pt: "1.5rem", pl: ".5rem" }}>
                            <PreferredFilters
                                handleSubmit={handleInputSubmit}
                                formData={formData}
                            />
                        </Box>
                    </Box>
                </Stack>
            </ToggleContent>
        </>
    );
};

export default PoolCreationFilters;
