import axios from "utils/axiosConfig";
import { EnvService } from "infra/envs";

class LoginApi {
    async loginRequest(credentials) {
        let loginUserURL = EnvService.getBackEndBaseUrl() + "/api/user/login";
        let response = await axios.post(loginUserURL, credentials);
        return response.data.token;
    }
}

const loginApiInstance = new LoginApi();
export default loginApiInstance;
