import { createSlice } from "@reduxjs/toolkit";

const citiesSlice = createSlice({
    name: "cities",
    initialState: [],
    reducers: {
        fetchCitiesOptions: (_, action) => {
            return action.payload;
        },
        resetCitiesOptions: () => {
            return [];
        },
    },
});

export const { fetchCitiesOptions, resetCitiesOptions } = citiesSlice.actions;
export default citiesSlice.reducer;
