import getErrorMessage from "assets/data/errors.json";
import { GlobalAppDataStorageService } from "infra/storage";
import { Divider, Box, TextField } from "@mui/material/";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import FormHorizontalSection from "./FormHorizontalSection";
import FormActions from "./FormActions";
import SlideForm from "./SlideForm";
import React from "react";
import SocialMediaURL from "services/SocialMediaURL.service";

export const StepOne = (props) => {
    const sourceOptions = GlobalAppDataStorageService.getSourceOptions();
    const { stepState } = props;
    const callingCodeOptions = GlobalAppDataStorageService.getCallingCodes();

    const {
        control,
        register,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            ...stepState.stepOneData,
            //If there is Linkedin add the domain to the handler
            linkedinProfile: stepState?.stepOneData?.linkedinProfile
                ? `${SocialMediaURL.LINKEDIN_PROFILE_BASE_URL}/${stepState?.stepOneData?.linkedinProfile}`
                : stepState?.stepOneData?.linkedinProfile,
            //If there is Collegelink ID add the domain to the handler
            collegelinkProfile: stepState?.stepOneData?.collegelinkProfile
                ? `${SocialMediaURL.COLLEGELINK_PROFILE_BASE_URL}${stepState?.stepOneData?.collegelinkProfile}`
                : stepState?.stepOneData?.collegelinkProfile,
        },
    });

    const onSubmit = async (formData, event) => {
        formData.step = 1;

        const { email, phone, linkedinProfile } = formData;
        if (!linkedinProfile && !phone && !email) {
            setError("linkedinProfile", {
                type: "manual",
                message: getErrorMessage.key.required,
            });
            setError("email", {
                type: "manual",
                message: getErrorMessage.key.required,
            });
            setError("phone", {
                type: "manual",
                message: getErrorMessage.key.required,
            });
            return;
        }
        const updateData = {
            ...formData,
            //Extracts CollegeLink ID
            collegelinkProfile: SocialMediaURL.extractCollegelinkId(
                formData.collegelinkProfile
            ),
            //Extracts Linkedin ID
            linkedinProfile: SocialMediaURL.extractLinkedinId(
                formData.linkedinProfile
            ),
            calling_code_id: formData.calling_code_id?.id,
        };

        stepState.formSubmit(updateData, errors);
    };

    return (
        <SlideForm stepState={{ ...stepState }}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <FormHorizontalSection sx={{ my: 4 }} title="Identity">
                    {/* First Name - Last Name */}
                    <Box sx={{ display: "flex", maxWidth: "700px" }} gap={2}>
                        <TextField
                            fullWidth
                            name="firstName"
                            {...register("firstName", {
                                required: getErrorMessage.firstName.required,
                            })}
                            error={Boolean(errors.firstName)}
                            helperText={
                                errors.firstName ? errors.firstName.message : ""
                            }
                            label="First name*"
                        />

                        <TextField
                            fullWidth
                            name="lastName"
                            {...register("lastName", {
                                required: getErrorMessage.lastName.required,
                            })}
                            error={Boolean(errors.lastName)}
                            helperText={
                                errors.lastName ? errors.lastName.message : ""
                            }
                            label="Last name*"
                        />
                    </Box>
                </FormHorizontalSection>
                <Divider sx={{ my: 4 }} />
                <FormHorizontalSection sx={{ my: 4 }} title="Contact">
                    {/* Linkedin - CollegeLink */}
                    <Box sx={{ display: "flex", maxWidth: "700px" }} gap={2}>
                        <TextField
                            fullWidth
                            name="linkedinProfile"
                            {...register("linkedinProfile", {
                                validate: {
                                    isLinkedinDomain: (value) => {
                                        if (!value) return true;

                                        return (
                                            SocialMediaURL.isLinkedInDomain(
                                                value
                                            ) ||
                                            getErrorMessage.social.linkedin
                                                .invalid
                                        );
                                    },
                                    isRecruiterAccount: (value) => {
                                        if (!value) return true;

                                        return (
                                            !SocialMediaURL.isRecruiterAccount(
                                                value
                                            ) ||
                                            getErrorMessage.social.linkedin
                                                .recruiter
                                        );
                                    },
                                    containsLinkedinId: (value) => {
                                        if (!value) return true;

                                        return (
                                            SocialMediaURL.containsLinkedinId(
                                                value
                                            ) ||
                                            getErrorMessage.social.linkedin
                                                .badUrl
                                        );
                                    },
                                },
                            })}
                            error={Boolean(errors.linkedinProfile)}
                            helperText={
                                errors.linkedinProfile
                                    ? errors.linkedinProfile.message
                                    : getErrorMessage.key.required
                            }
                            label="Linkedin profile**"
                        />

                        <TextField
                            fullWidth
                            name="collegelinkProfile"
                            {...register("collegelinkProfile", {
                                validate: {
                                    isCollegelinkDomain: (value) => {
                                        if (!value) return true;

                                        return (
                                            SocialMediaURL.isCollegelinkDomain(
                                                value
                                            ) ||
                                            getErrorMessage.collegelink.invalid
                                        );
                                    },
                                    containsStudent: (value) => {
                                        if (!value) return true;

                                        return (
                                            SocialMediaURL.hasCollegelinkStudentParam(
                                                value
                                            ) ||
                                            getErrorMessage.collegelink.badUrl
                                        );
                                    },
                                },
                            })}
                            label="CollegeLink profile"
                            error={Boolean(errors.collegelinkProfile)}
                            helperText={
                                errors.collegelinkProfile
                                    ? errors.collegelinkProfile.message
                                    : ""
                            }
                        />
                    </Box>

                    {/* Email - Phone */}
                    <Box sx={{ display: "flex", maxWidth: "700px" }} gap={2}>
                        <Controller
                            name="calling_code_id"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                                <Autocomplete
                                    id="calling_code_id"
                                    autoComplete
                                    limitTags={1}
                                    fullWidth
                                    options={callingCodeOptions || []}
                                    getOptionLabel={(option) => option?.name}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    {...field}
                                    onChange={(event, newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country code"
                                        />
                                    )}
                                />
                            )}
                        />

                        <TextField
                            fullWidth
                            name="phone"
                            {...register("phone", {
                                pattern: {
                                    value: /^[0-9]*$/,
                                    message: getErrorMessage.phone.invalid,
                                },
                                validate: {
                                    maxLength: (value) =>
                                        value.length === 0 ||
                                        value.length > 8 ||
                                        getErrorMessage.phone.tooShort,
                                },
                            })}
                            error={Boolean(errors.phone)}
                            helperText={
                                errors.phone
                                    ? errors.phone.message
                                    : getErrorMessage.key.required
                            }
                            type="tel"
                            inputMode="numeric"
                            label="Phone**"
                        />
                    </Box>

                    {/* Source */}
                    <Box sx={{ display: "flex", maxWidth: "700px" }} gap={2}>
                        <TextField
                            fullWidth
                            name="email"
                            {...register("email")}
                            error={Boolean(errors.email)}
                            helperText={
                                errors.email
                                    ? errors.email.message
                                    : getErrorMessage.key.required
                            }
                            type="email"
                            label="Email**"
                        />
                        <Controller
                            name="source"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Source is required" }}
                            render={({ field, fieldState }) => (
                                <Autocomplete
                                    id="source"
                                    fullWidth
                                    autoComplete
                                    options={sourceOptions || []}
                                    getOptionLabel={(option) =>
                                        option?.name || ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    {...field}
                                    onChange={(e, data) => field.onChange(data)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!fieldState.error}
                                            label="Select source"
                                            helperText={
                                                fieldState.error?.message
                                            }
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                </FormHorizontalSection>

                <FormActions stepState={{ ...stepState }} />
            </Box>
        </SlideForm>
    );
};

export default StepOne;
