import { createSlice } from "@reduxjs/toolkit";

const educationsSlice = createSlice({
    name: "educations",
    initialState: { loading: false, data: [] },
    reducers: {
        setEducationsStart: (state) => {
            state.loading = true;
        },
        setEducations: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        addEducation: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        updateEducation: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        deleteEducation: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
    },
});

export const {
    setEducationsStart,
    setEducations,
    addEducation,
    updateEducation,
    deleteEducation,
} = educationsSlice.actions;

export default educationsSlice.reducer;
