import { Stack, TextField } from "@mui/material";
import ToggleContent from "components/lib-ui/ToggleContent";
import PersonIcon from "@mui/icons-material/Person";

export const SpecificCandidateFilters = ({ handleSubmit, formData }) => {
    const { register } = formData;

    return (
        <>
            <ToggleContent
                title="Specific Candidate"
                icon={<PersonIcon fontSize="small" />}
            >
                <Stack spacing={1}>
                    <TextField
                        {...register("first_name")}
                        name="first_name"   
                        id="first_name"
                        label="First name"
                        onBlur={handleSubmit}
                        variant="standard"
                    />
                    <TextField
                        {...register("last_name")}
                        name="last_name"
                        id="last_name"
                        label="Last name"
                        onBlur={handleSubmit}
                        variant="standard"
                    />
                    <TextField
                        {...register("email")}
                        name="email"
                        id="email"
                        label="Email"
                        onBlur={handleSubmit}
                        variant="standard"
                    />
                    <TextField
                        {...register("phone_number")}
                        name="phone_number"
                        id="phone_number"
                        label="Phone number"
                        onBlur={handleSubmit}
                        variant="standard"
                    />
                    <TextField
                        {...register("linkedin_profile")}
                        name="linkedin_profile"
                        id="linkedin_profile"
                        label="LinkedIn"
                        onBlur={handleSubmit}
                        variant="standard"
                    />
                    <TextField
                        {...register("collegelink_id")}
                        name="collegelink_id"
                        id="collegelink_id"
                        label="CL profile"
                        onBlur={handleSubmit}
                        variant="standard"
                    />
                </Stack>
            </ToggleContent>
        </>
    );
};

export default SpecificCandidateFilters;
