import SocialMediaURL from "services/SocialMediaURL.service";

const clPrefix = SocialMediaURL.COLLEGELINK_PROFILE_BASE_URL;

export const transformResData = (candidates = []) => {
    return candidates.map((candidate) => ({
        id: candidate?.id,
        last_name: candidate?.personal_information?.last_name,
        first_name: candidate?.personal_information?.first_name,
        email: candidate?.contact_information?.email,
        elligible: "Yes",
        phone_number: candidate?.contact_information?.phone_number,
        linkedin_profile:
            candidate?.contact_information?.linkedin_profile || null,
        collegelink_profile_id: candidate?.contact_information
            ?.collegelink_profile_id
            ? `${clPrefix}${candidate?.contact_information?.collegelink_profile_id}`
            : null,
        cv: candidate?.experience?.has_cv === 1 ? `${candidate?.id}` : null,
        view: candidate?.id,
    }));
};

export default transformResData;
