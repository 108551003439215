import { Stack, Box, alpha } from "@mui/material";
import ParsedDataRow from "components/lib-ui/parser/ParsedDataRow";
import ValueDisplay from "./ValueDisplay";
import DiagonalPatternBox from "./DiagonalPatternBox";
import GeneralUtilityService from "services/GeneralUtilityService";
import FieldInteractionRow from "./FieldInteractionRow";
import ParserHiddenInput from "./dynamicInputs/ParserHiddenInput";
import { green, red } from "@mui/material/colors";
import { useSelector } from "react-redux";
import CustomCheckbox from "../CustomCheckbox";

const styles = {
    willBeSaved: {
        ".rappDataStyles, .rappDataStyles div, .MuiFormControl-root, textarea":
            {
                color: green[800],
                background: alpha(green[800], 0.1),
            },
        input: {
            color: green[800],
        },
    },
    willBeDeleted: {
        ".rappDataStyles, .rappDataStyles div, .MuiFormControl-root": {
            color: red[800],
            background: alpha(red[800], 0.1),
            textDecoration: "line-through",
        },
    },
    dataRow: {
        "&:not(:first-of-type)": { mt: 4 },
    },
    boxStyle: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: "center",
        height: "100%",
    },
    boxRightStyle: {
        borderRight: "1px solid lightgray",
        height: "100%",
        display: "flex",
        alignItems: "center",
    },
};

export const DifferenceGroup = ({
    extractFields,
    data,
    groupName,
    formControls,
}) => {
    const checkboxGroups = useSelector(
        (state) => state.parserCheckboxGroupSlice.checkboxGroups
    );

    const renderRowContent = (rappData, parsedData, field, index) => {
        const { fieldName, origin, type, freeSolo } = field;

        const rappDataValue = rappData[fieldName];
        const parsedDataValue = parsedData[fieldName];
        const isRappDataEmpty = GeneralUtilityService.isEmpty(rappDataValue);
        const isParsedDataEmpty = GeneralUtilityService.isEmpty(parsedData);
        const uniqueIdentifier = `${origin}_${index}_${fieldName}`;
        const dynamicFieldName = `dynamic_${uniqueIdentifier}`;
        const parts = uniqueIdentifier.split("_");
        const groupId = `${groupName}_${parts[1]}`;
        const isSelected = !checkboxGroups[groupId]?.[uniqueIdentifier];

        const getParsedDataStyles = !isSelected
            ? styles.willBeSaved
            : styles.willBeDeleted;

        let getRappDataStyles = "";

        if (!isParsedDataEmpty) {
            getRappDataStyles = isSelected
                ? styles.willBeSaved
                : styles.willBeDeleted;
        }

        if (type === "hidden") {
            return (
                <ParserHiddenInput
                    key={uniqueIdentifier}
                    value={rappDataValue}
                    fieldName={uniqueIdentifier}
                    disabled={true}
                    formControls={formControls}
                />
            );
        }

        return (
            <ParsedDataRow
                title={field.title}
                key={`${field.fieldName}_${index}`}
                dataSourceType={field.dataSourceType}
            >
                <Box sx={styles.boxStyle}>
                    <Box sx={{ ...styles.boxRightStyle, ...getRappDataStyles }}>
                        {isRappDataEmpty ? (
                            <DiagonalPatternBox />
                        ) : (
                            <ValueDisplay value={rappDataValue} type={type} />
                        )}
                    </Box>

                    {!GeneralUtilityService.isObjectEmpty(parsedData) ? (
                        <Box sx={getParsedDataStyles}>
                            <FieldInteractionRow
                                parsedDataValue={parsedDataValue}
                                rappDataValue={rappDataValue}
                                field={field}
                                freeSolo={freeSolo}
                                uniqueIdentifier={uniqueIdentifier}
                                dynamicFieldName={dynamicFieldName}
                                index={index}
                                formControls={formControls}
                                groupName={groupName}
                            />
                        </Box>
                    ) : (
                        <>
                            {isParsedDataEmpty && (
                                <ParserHiddenInput
                                    value={rappDataValue}
                                    fieldName={dynamicFieldName}
                                    formControls={formControls}
                                />
                            )}
                            <DiagonalPatternBox />
                        </>
                    )}
                </Box>
            </ParsedDataRow>
        );
    };

    if (Array.isArray(data)) {
        return data?.map(([rappData, parsedData], dataIdx) => {
            const isParsedDataEmpty = GeneralUtilityService.isEmpty(parsedData);

            const groupKey = `${groupName}_${dataIdx}`;
            return (
                <Stack key={groupKey} sx={styles.dataRow}>
                    <ParsedDataRow
                        title={`${groupName} ${dataIdx + 1}`}
                        titleStyles={{
                            fontSize: "1rem",
                            textTransform: "uppercase",
                            fontWeight: "600",
                        }}
                    >
                        <Box sx={styles.boxStyle}>
                            <Box></Box>
                            <CustomCheckbox
                                disabled={isParsedDataEmpty}
                                label={`Toggle all ${groupName}`}
                                groupKey={`${groupName}_${dataIdx}`}
                                isGroupCheckbox={true}
                            />
                            <Box></Box>
                        </Box>
                    </ParsedDataRow>

                    <Stack>
                        {extractFields.map((field) =>
                            renderRowContent(
                                rappData,
                                parsedData,
                                field,
                                dataIdx
                            )
                        )}
                    </Stack>
                </Stack>
            );
        });
    }
    return null;
};

export default DifferenceGroup;
