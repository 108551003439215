import { useState, useEffect } from "react";
import { Box, Stack, Button, Typography, Menu } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
    LinkedIn,
    Article,
    Keyboard,
    AddCircleOutlined,
} from "@mui/icons-material";
import DialogBox from "components/lib-ui/DialogBox";
import LinkButton from "components/layout/navigation/LinkButton";
import ParseLinkedinForm from "features/candidate/candidate-form/ParseLinkedinForm";
import ParseCvForm from "features/candidate/candidate-form/ParseCvForm";
import useParser from "hooks/useParser";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: "5px 10px",
        textTransform: "none",
        color: "primary",
        borderRadius: "200px",
        border: "unset",
        borderColor: "gray.soft",
    },
    addIcon: {
        fontSize: "1.5rem",
    },
}));

export const MenuButton = ({
    text,
    icon,
    add,
    hasCv,
    hasLinkedIn,
    candidateId,
    variant,
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState({
        linkedin: false,
        cv: false,
    });

    const { parseExistingCandidate } = useParser();

    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDialogOpen = (type) => {
        setIsDialogOpen((prevState) => ({
            ...prevState,
            [type]: !prevState[type],
        }));
    };

    const handleExistingCandidateCvParse = async () =>
        await parseExistingCandidate(candidateId, "pdf");

    const handleExistingCandidateLinkedinParse = async () =>
        await parseExistingCandidate(candidateId, "linkedin");

    const createCandidateMenuOptions = [
        {
            text: "Manual Fill",
            to: "/candidate/add/manual-fill",
            icon: <Keyboard fontSize="small" />,
        },
        {
            text: "Parse Linkedin",

            icon: <LinkedIn fontSize="small" />,
            handleClick: () => {
                handleDialogOpen("linkedin");
            },
        },
        {
            text: "Upload and parse CV",

            icon: <Article fontSize="small" />,
            handleClick: () => {
                handleDialogOpen("cv");
            },
        },
    ];

    let parseCandidateMenuOptions = [];

    if (hasLinkedIn) {
        parseCandidateMenuOptions.push({
            text: "Parse Linkedin",
            to: "/candidate/add/parse-linkedin",
            icon: <LinkedIn fontSize="small" />,
            handleClick: () => {
                handleExistingCandidateLinkedinParse();
            },
        });
    }

    if (hasCv) {
        parseCandidateMenuOptions.push({
            text: "Parse CV",
            to: "/candidate/add/parse-cv",
            icon: <Article fontSize="small" />,
            handleClick: () => {
                handleExistingCandidateCvParse();
            },
        });
    }

    //cleanup
    useEffect(() => {
        return () => {
            setIsDialogOpen({ linkedin: false, cv: false });
        };
    }, []);

    return (
        <Box>
            <DialogBox
                isDialogOpen={isDialogOpen.linkedin}
                setIsDialogOpen={() => handleDialogOpen("linkedin")}
                title="Parse Linkedin"
                floatCancel={true}
                showActionButtons={false}
            >
                <ParseLinkedinForm
                    setIsDialogOpen={() => handleDialogOpen("linkedin")}
                />
            </DialogBox>
            <DialogBox
                isDialogOpen={isDialogOpen.cv}
                setIsDialogOpen={() => handleDialogOpen("cv")}
                title="Import from PDF"
                floatCancel={true}
                showActionButtons={false}
            >
                <ParseCvForm setIsDialogOpen={() => handleDialogOpen("cv")} />
            </DialogBox>

            <Stack direction="row" alignItems="center">
                <Button
                    onClick={handleOpen}
                    variant={variant}
                    aria-controls={
                        open ? "create-candidate-options" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    className={classes.button}
                    startIcon={
                        icon ? (
                            <AddCircleOutlined className={classes.addIcon} />
                        ) : null
                    }
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography color="gray.dark`">{text}</Typography>
                    </Stack>
                </Button>
            </Stack>

            <Menu
                anchorEl={anchorEl}
                id="create-candidate-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {add
                    ? createCandidateMenuOptions.map((option) => {
                          return (
                              <LinkButton
                                  key={option.text}
                                  to={option.to}
                                  onClick={option.handleClick}
                                  text={option.text}
                                  icon={option.icon}
                              />
                          );
                      })
                    : parseCandidateMenuOptions.map((option) => {
                          return (
                              <LinkButton
                                  key={option.text}
                                  to={option.to}
                                  onClick={option.handleClick}
                                  text={option.text}
                                  icon={option.icon}
                              />
                          );
                      })}
            </Menu>
        </Box>
    );
};

export default MenuButton;
