import { Box, Stack, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import getErrorMessage from "assets/data/errors.json";
import SocialMediaURL from "services/SocialMediaURL.service";
import LoadingButton from "@mui/lab/LoadingButton";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import { useNavigate } from "react-router-dom";
import { useParser } from "hooks/useParser";

export const ParseLinkedinForm = ({ setIsDialogOpen }) => {
    const navigate = useNavigate();
    const { parseLinkedinURL, isLoading: isParseLinkedinLoading } = useParser();
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    const submitForm = async (data) => {
        if (data) {
            const linkedinProfileHandler = SocialMediaURL.extractLinkedinId(
                data.linkedin_url
            );

            data.firstName = "temp";
            data.lastName = "temp";
            data.linkedinProfile = linkedinProfileHandler;

            const isParsed = await parseLinkedinURL(data);

            if (isParsed) {
                navigate("/candidate/add/parse-linkedin");
                setIsDialogOpen(false);
            }
        }
    };

    return (
        <Box mt={3} component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={6}>
                <TextField
                    fullWidth
                    required
                    name="linkedin_url"
                    {...register("linkedin_url", {
                        validate: {
                            isLinkedinDomain: (value) => {
                                if (!value) return true;
                                return (
                                    SocialMediaURL.isLinkedInDomain(value) ||
                                    getErrorMessage.social.linkedin.invalid
                                );
                            },
                            isRecruiterAccount: (value) => {
                                if (!value) return true;
                                return (
                                    !SocialMediaURL.isRecruiterAccount(value) ||
                                    getErrorMessage.social.linkedin.recruiter
                                );
                            },
                            containsLinkedinId: (value) => {
                                if (!value) return true;

                                return (
                                    SocialMediaURL.containsLinkedinId(value) ||
                                    getErrorMessage.social.linkedin.badUrl
                                );
                            },
                        },
                    })}
                    error={Boolean(errors.linkedin_url)}
                    helperText={
                        errors.linkedin_url ? errors.linkedin_url.message : null
                    }
                    label="Linkedin profile"
                />
            </Box>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    color="gray"
                    variant="text"
                    onClick={() => {
                        setIsDialogOpen(false);
                    }}
                >
                    Cancel
                </Button>

                <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isParseLinkedinLoading}
                    loadingPosition="start"
                    startIcon={<ImportExportOutlinedIcon />}
                >
                    Parse profile
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default ParseLinkedinForm;
