import axios from "utils/axiosConfig";
import { useState, useEffect } from "react";
import { urlService } from "infra/envs/";

export const useFetchHfrJobsAndCompanies = () => {
    const jobsAndCompaniesPath = urlService.JOBS_COMPANIES_PATH;
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [jobOptions, setJobOptions] = useState([]);

    useEffect(() => {
        const fetchHfrJobsAndCompanies = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(jobsAndCompaniesPath);
                setCompanyOptions(response.data.companies);
                setJobOptions(response.data.jobs);
                setRequestSuccessful(true);
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchHfrJobsAndCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isLoading, requestSuccessful, companyOptions, jobOptions };
};

export default useFetchHfrJobsAndCompanies;
