import { createSlice } from "@reduxjs/toolkit";

const appLoaderSlice = createSlice({
    name: "appLoader",
    initialState: true,
    reducers: {
        startAppLoader: (_state) => true,
        stopAppLoader: (_state) => false,
    },
});

export const { startAppLoader, stopAppLoader } = appLoaderSlice.actions;
export default appLoaderSlice.reducer;
