import { useEffect, useState } from "react";
import {
    Stack,
    Box,
    Paper,
    Typography,
    Button,
    Avatar,
    Divider,
    Tab,
    Collapse,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddIcon from "@mui/icons-material/Add";
import Utils from "services/GeneralUtilityService";
import RecruitmentProccessCardService from "services/RecruitmentProccessCard.service.js";
import PlugOutlineIcon from "assets/icons/PlugOutlineIcon";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import DynamicFormOutlinedIcon from "@mui/icons-material/DynamicFormOutlined";
import InfoIcon from "@mui/icons-material/Info";
import DialogBox from "components/lib-ui/DialogBox";
import IconContent from "../../../components/lib-ui/IconContent";
import AddNewStep from "../candidate-form/recruitment-processes/AddNewStep";
import AttachJob from "../candidate-form/recruitment-processes/AttachJob";
import RpNoteForm from "../candidate-form/recruitment-processes/RpNoteForm";
import NewHireDataForm from "../candidate-form/NewHireDataForm";
import { useSelector } from "react-redux";
import HorizontalStepTimeline from "./tabs/recruitment-process/HorizontalStepTimeline";
import RecruitmentProcessNotes from "./tabs/recruitment-process/RecruitmentProcessNotes";
import RecruitmentProcessNotesReport from "./tabs/recruitment-process/RecruitmentProcessNotesReport";
import ReProCardService from "services/RecruitmentProccessCard.service";

export const RecruitmentProcessCard = ({ recruitmentProcessData }) => {
    const isHired = RecruitmentProccessCardService.isHired(
        recruitmentProcessData
    );
    const isHiddenInfoVisible = useSelector(
        (state) => state.hiddenInfoSlice.isHiddenInfoVisible
    );

    const internalTabValue = "internal";
    const externalTabValue = "external";

    const [isShowMoreActive, setIsShowMoreActive] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [selectedDialog, setSelectedDialog] = useState(null);

    const candidateData = useSelector((state) => state.currentCandidate);

    const { title, updatedAt, hiredInfo } = recruitmentProcessData || {};

    const { notes, externalReport } =
        ReProCardService.extractNotesFromRPSteps(recruitmentProcessData) || {};

    const companyName = recruitmentProcessData?.external?.job?.company?.name;
    const jobDashboardId = recruitmentProcessData?.external?.job?.id;
    const jobTitle = recruitmentProcessData?.external?.job?.name;

    const computeHasExternal = (obj) => !Utils.isObjectEmpty(obj);
    const computeActiveTab = (hasExternal) =>
        hasExternal ? externalTabValue : internalTabValue;

    const [hasExternal, setHasExternal] = useState(
        computeHasExternal(recruitmentProcessData?.external)
    );
    const [activeTab, setActiveTab] = useState(computeActiveTab(hasExternal));
    const [activeFlowTab, setActiveFlowTab] = useState(activeTab);

    useEffect(() => {
        const currentHasExternal = computeHasExternal(
            recruitmentProcessData?.external
        );
        setHasExternal(currentHasExternal);
        setActiveTab(computeActiveTab(currentHasExternal));
        setActiveFlowTab(computeActiveTab(hasExternal));
    }, [recruitmentProcessData, hasExternal]);

    const candidateProfileImageUrl = null;
    const companyProfileImageUrl = null;

    const candidateName = `${candidateData.firstName} ${candidateData.lastName}`;
    const candidateNameInitials = Utils.getStringInitals(candidateName);
    const companyNameInitials = Utils.getStringInitals(companyName);

    const creator = `${recruitmentProcessData?.createdBy?.firstName} ${recruitmentProcessData?.createdBy?.lastName}`;

    const recruiterNameInitials = Utils.getStringInitals(creator);

    const companyData = {
        companyProfileImageUrl,
        companyName,
        companyNameInitials,
    };

    const currentRecruitmentProcessId =
        ReProCardService.getRecruitmentProccessCurrentId(
            recruitmentProcessData
        );

    const internalFlowSteps = {
        ...recruitmentProcessData.internal,
        hasExternal: hasExternal,
        ...companyData,
        currentRecruitmentProcessId: currentRecruitmentProcessId,
    };

    const externalFlowSteps = {
        ...recruitmentProcessData.external,
        ...companyData,
        currentRecruitmentProcessId: currentRecruitmentProcessId,
    };

    const handleChange = (event, newValue) => {
        setActiveFlowTab(newValue);
    };

    const getFlowTabIcon = (currentTab) => {
        return activeFlowTab === currentTab ? (
            <RadioButtonCheckedOutlinedIcon />
        ) : (
            <RadioButtonUncheckedOutlinedIcon />
        );
    };

    const isInternalTabActive = activeFlowTab === internalTabValue;

    const renderModalContent = (modalName) => {
        switch (modalName) {
            case "addNewState":
                return (
                    <AddNewStep
                        setIsDialogOpen={setIsDialogOpen}
                        cardData={recruitmentProcessData}
                        isInternalTabActive={isInternalTabActive}
                    />
                );
            case "attachJob":
                return (
                    <AttachJob
                        setIsDialogOpen={setIsDialogOpen}
                        cardData={recruitmentProcessData}
                    />
                );
            case "addNote":
                return (
                    <RpNoteForm
                        cardData={{
                            activeFlowTab: activeFlowTab,
                            ...recruitmentProcessData,
                            ...ReProCardService.getRecruitmentProcessStepOptions(
                                recruitmentProcessData
                            ),
                        }}
                        setIsDialogOpen={setIsDialogOpen}
                    />
                );
            case "newHireDataForm":
                return (
                    <NewHireDataForm
                        setIsDialogOpen={setIsDialogOpen}
                        recruitmentProcessData={recruitmentProcessData}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Paper
            data-testid={recruitmentProcessData.title}
            data-rp-id={recruitmentProcessData.id}
            elevation={3}
            sx={{
                borderRadius: "10px",
                width: "900px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    height: "fit-content",
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: 2,
                }}
            >
                {/* Recruitment process top header section */}
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ width: "100%" }}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Stack
                            direction="row"
                            spacing={-1}
                            alignItems="center"
                            justifyContent="flex-start"
                        >
                            {/* Candidate Picture */}
                            <Avatar
                                alt="Candidate picture"
                                src={candidateProfileImageUrl}
                                loading="lazy"
                                sx={{
                                    width: 47,
                                    height: 47,
                                    fontSize: "1rem",
                                    backgroundColor: candidateProfileImageUrl
                                        ? "gray"
                                        : Utils.stringToColor(candidateName),
                                    zIndex: 1,
                                }}
                            >
                                {candidateNameInitials}
                            </Avatar>
                            {/* Company Picture */}
                            <Avatar
                                variant="rounded"
                                alt="Candidate picture"
                                src={candidateProfileImageUrl}
                                loading="lazy"
                                sx={{
                                    border: "1px solid",
                                    borderColor: "gray",
                                    width: 46,
                                    textTransform: "uppercase",
                                    height: 46,
                                    fontSize: "1rem",
                                    backgroundColor: candidateProfileImageUrl
                                        ? "gray"
                                        : Utils.stringToColor(
                                              companyNameInitials
                                          ),
                                }}
                            >
                                {hasExternal ? (
                                    companyNameInitials
                                ) : (
                                    <PlugOutlineIcon />
                                )}
                            </Avatar>
                        </Stack>
                        <Stack
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="center"
                        >
                            <IconContent
                                icon={isHiddenInfoVisible ? InfoIcon : null}
                                tooltipText={`Recruitment Process Id: ${recruitmentProcessData.id}`}
                                tooltipPlacement={"top"}
                            >
                                <Typography
                                    variant="header2"
                                    sx={{ fontWeight: 400, color: "gray.dark" }}
                                >
                                    {jobTitle || title}
                                    {hasExternal && companyName
                                        ? ` @ ${companyName}`
                                        : null}
                                    {jobDashboardId && ` • ${jobDashboardId}`}
                                </Typography>
                            </IconContent>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0.5}
                                justifyContent="center"
                            >
                                <Typography
                                    variant="header2"
                                    sx={{ fontWeight: 400, color: "gray.dark" }}
                                >
                                    {candidateName}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack
                        direction="column"
                        spacing={1}
                        alignItems="flex-end"
                        justifyContent="flex-start"
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            justifyContent="flex-start"
                        >
                            <Box>
                                <Typography variant="subtitle2">
                                    assigned to {creator}
                                </Typography>
                            </Box>
                            {/* Candidate Picture */}
                            <Avatar
                                alt="Candidate picture"
                                src={candidateProfileImageUrl}
                                loading="lazy"
                                sx={{
                                    width: 30,
                                    height: 30,
                                    fontSize: ".8rem",
                                    backgroundColor: candidateProfileImageUrl
                                        ? "gray"
                                        : Utils.stringToColor(
                                              recruiterNameInitials
                                          ),
                                }}
                            >
                                {recruiterNameInitials}
                            </Avatar>
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="flex-end"
                            spacing={2}
                            justifyContent="flex-end"
                        >
                            {hasExternal ? null : (
                                <Button
                                    disableElevation
                                    variant="contained"
                                    color="cool"
                                    title="Attach job"
                                    startIcon={<PlugOutlineIcon />}
                                    onClick={() => {
                                        setIsDialogOpen(true);
                                        setSelectedDialog(
                                            renderModalContent("attachJob")
                                        );
                                        setDialogTitle("Attach Job");
                                    }}
                                    sx={{
                                        textTransform: "none",
                                        borderRadius: "50px",
                                    }}
                                >
                                    Attach job
                                </Button>
                            )}

                            <Button
                                disableElevation
                                disabled={hasExternal && isInternalTabActive}
                                variant="contained"
                                color="cool"
                                title="Add new step"
                                startIcon={<AddIcon />}
                                sx={{
                                    textTransform: "none",
                                    borderRadius: "50px",
                                }}
                                onClick={() => {
                                    setIsDialogOpen(true);
                                    setSelectedDialog(
                                        renderModalContent("addNewState")
                                    );
                                    setDialogTitle("Add new step");
                                }}
                            >
                                Add new step
                            </Button>

                            {isHired && (
                                <Button
                                    disableElevation
                                    disabled={
                                        hasExternal && isInternalTabActive
                                    }
                                    variant="contained"
                                    color={hiredInfo ? "cool" : "primary"}
                                    title="Ergani report"
                                    startIcon={<DynamicFormOutlinedIcon />}
                                    sx={{
                                        textTransform: "none",
                                        borderRadius: "50px",
                                    }}
                                    onClick={() => {
                                        setIsDialogOpen(true);
                                        setSelectedDialog(
                                            renderModalContent(
                                                "newHireDataForm"
                                            )
                                        );
                                        setDialogTitle("Hire information");
                                    }}
                                >
                                    Hire information
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Stack>

                {/* Flow Section */}
                <Box mt={1} sx={{ width: "100%" }}>
                    <TabContext value={activeFlowTab}>
                        <Stack alignItems="center" justifyContent="center">
                            <TabList
                                onChange={handleChange}
                                aria-label="Flow of recruitment process"
                                indicatorColor="none"
                                sx={{
                                    height: "auto",
                                    minHeight: "unset",
                                }}
                            >
                                {hasExternal && (
                                    <Tab
                                        disableRipple
                                        icon={getFlowTabIcon(internalTabValue)}
                                        iconPosition="start"
                                        value={internalTabValue}
                                        label="Generic flow"
                                        sx={{
                                            textTransform: "none",
                                            minHeight: "unset",
                                        }}
                                    />
                                )}

                                {hasExternal && (
                                    <Tab
                                        disableRipple
                                        icon={getFlowTabIcon(externalTabValue)}
                                        iconPosition="start"
                                        value={externalTabValue}
                                        label={"Job Specific flow"}
                                        sx={{
                                            textTransform: "none",
                                            minHeight: "unset",
                                        }}
                                    />
                                )}
                            </TabList>
                        </Stack>

                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ height: "170px" }}
                        >
                            {/* CollegeLink Flow Content*/}

                            <TabPanel
                                key={1}
                                value="internal"
                                sx={{
                                    padding: "50px 20px",
                                    width: "100%",
                                    overflowX: "auto",
                                }}
                                data-rp-general-flow-id={
                                    recruitmentProcessData.internal?.id
                                }
                            >
                                <IconContent
                                    icon={isHiddenInfoVisible ? InfoIcon : null}
                                    tooltipText={`RP General Flow Id: ${recruitmentProcessData.internal?.id}`}
                                    tooltipPlacement={"top"}
                                >
                                    <HorizontalStepTimeline
                                        flowStepsData={internalFlowSteps}
                                    />
                                </IconContent>
                            </TabPanel>

                            {/* Company Flow Content*/}

                            <TabPanel
                                key={2}
                                value={"external"}
                                sx={{
                                    padding: "50px 20px",
                                    width: "100%",
                                    overflowX: "auto",
                                }}
                                data-rp-specific-flow-id={
                                    recruitmentProcessData.external?.id
                                }
                            >
                                <IconContent
                                    icon={isHiddenInfoVisible ? InfoIcon : null}
                                    tooltipText={`RP Specific Flow Id: ${recruitmentProcessData.external?.id}`}
                                    tooltipPlacement={"top"}
                                >
                                    <HorizontalStepTimeline
                                        flowStepsData={externalFlowSteps}
                                    />
                                </IconContent>
                            </TabPanel>
                        </Stack>
                    </TabContext>
                </Box>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Stack
                direction="row"
                alignItems="center"
                p={2}
                spacing={2}
                justifyContent="space-between"
            >
                <Stack direction="row" spacing={2}>
                    {Boolean(notes?.length || externalReport) && (
                        <Button
                            disableElevation
                            variant="outlined"
                            color="gray"
                            title="Show more information"
                            startIcon={
                                isShowMoreActive ? (
                                    <KeyboardArrowUpOutlinedIcon />
                                ) : (
                                    <KeyboardArrowDownOutlinedIcon />
                                )
                            }
                            sx={{
                                textTransform: "none",
                                borderRadius: "50px",
                            }}
                            onClick={() => {
                                setIsShowMoreActive(!isShowMoreActive);
                            }}
                        >
                            Show more
                        </Button>
                    )}

                    <Button
                        variant="outlined"
                        color="gray"
                        startIcon={<AddIcon />}
                        title="Add note"
                        onClick={() => {
                            setIsDialogOpen(true);
                            setSelectedDialog(renderModalContent("addNote"));
                            setDialogTitle("Add note");
                        }}
                        sx={{
                            textTransform: "none",
                            borderRadius: "50px",
                        }}
                    >
                        Add note
                    </Button>
                </Stack>
                <Typography variant="subtitle2">
                    last update {Utils.getTimeDifferenceString(updatedAt)}
                </Typography>
            </Stack>

            <Collapse orientation="vertical" in={isShowMoreActive}>
                <Box sx={{ boxShadow: (theme) => theme.shadows[3] }}>
                    <Divider />
                </Box>

                {/* Notes */}
                <Stack
                    spacing={2}
                    py={2}
                    sx={{
                        maxHeight: "520px",
                        overflow: "auto",
                    }}
                >
                    {Boolean(externalReport) && (
                        <Box>
                            <RecruitmentProcessNotesReport
                                data={externalReport}
                            />
                            <Divider
                                sx={{
                                    margin: "0 1rem",
                                    padding: "0 0 1rem 0",
                                }}
                            />
                        </Box>
                    )}
                    {notes &&
                        notes.map((noteData, index, allNotes) => {
                            const isLastNote = index + 1 >= allNotes.length;
                            return (
                                <Box key={index}>
                                    <RecruitmentProcessNotes
                                        noteData={noteData}
                                        recruitmentProcessData={
                                            recruitmentProcessData
                                        }
                                    />
                                    {isLastNote ? null : (
                                        <Divider
                                            sx={{
                                                margin: "0 1rem",
                                                padding: "0 0 1rem 0",
                                            }}
                                        />
                                    )}
                                </Box>
                            );
                        })}
                </Stack>
            </Collapse>

            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title={dialogTitle}
                floatCancel={true}
                showActionButtons={false}
            >
                {selectedDialog}
            </DialogBox>
        </Paper>
    );
};

export default RecruitmentProcessCard;
