import React from "react";
import { Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import SubscriptionsAndCredits from "./tabs/subscriptions-and-credits";

export const CompanyTabs = ({ sx: parentStyles }) => {
    const activeTab = "AccessToTalent";

    return (
        <Box
            sx={{
                background: "inherit",
                ...parentStyles,
            }}
        >
            <TabContext value={activeTab}>
                <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "#F8F8F8",
                    }}
                >
                    <SubscriptionsAndCredits />
                </Box>
            </TabContext>
        </Box>
    );
};

export default CompanyTabs;
