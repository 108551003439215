import getErrorMessage from "assets/data/errors";
/**
 * Validates an email address.
 * @param {string} email - The email address to validate.
 * @returns {object} - An object containing a boolean indicating validity and an error message.
 */
const emailValidator = (email) => {
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (pattern.test(email)) {
        return {
            isValid: true,
            errorMessage: "",
        };
    } else {
        return {
            isValid: false,
            errorMessage: getErrorMessage.email.invalid,
        };
    }
};

export default emailValidator;
