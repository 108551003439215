import { Stack, Box, Link, Typography } from "@mui/material";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import SectionCard from "../SectionCard";
import SectorExperienceTree from "./summary/SectorExperienceTree";
import IconContent from "../../../../components/lib-ui/IconContent";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import EuroOutlinedIcon from "@mui/icons-material/EuroOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import ElectricMeterOutlinedIcon from "@mui/icons-material/ElectricMeterOutlined";
import PlaceholderText from "../PlaceholderText";
import { useSelector } from "react-redux";

export const Summary = () => {
    const candidate = useSelector((state) => state.currentCandidate);
    const composeCompanyUrl = candidate?.summary?.currentCompanyUrl;
    const composeCurrentOccupation =
        candidate?.summary?.currentJobTitle ||
        candidate?.summary?.currentCompanyName
            ? `${candidate.summary?.currentJobTitle} @ ${candidate?.summary?.currentCompanyName}`
            : null;

    return (
        <Box
            sx={{
                width: "fit-content",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 3,
                alignItems: "flex-start",
            }}
        >
            <Stack gap={3}>
                {/* Currently */}
                <SectionCard title="Currently" titleIcon={SmartToyOutlinedIcon}>
                    <Box
                        p={2}
                        gap={2}
                        sx={{ display: "grid", flexDirection: "column" }}
                    >
                        <IconContent
                            icon={WorkOutlineOutlinedIcon}
                            tooltipText={"Current role & current company"}
                            tooltipPlacement={"left"}
                        >
                            {composeCompanyUrl ? (
                                <Link href={composeCompanyUrl}>
                                    {composeCurrentOccupation}
                                </Link>
                            ) : composeCurrentOccupation ? (
                                composeCurrentOccupation
                            ) : (
                                <PlaceholderText returnOnlyText={true} />
                            )}
                        </IconContent>

                        <IconContent
                            text={
                                candidate?.summary?.currentRoleExperience || (
                                    <PlaceholderText returnOnlyText={true} />
                                )
                            }
                            icon={StarsOutlinedIcon}
                            tooltipText={"Years of experience in current role"}
                            tooltipPlacement={"left"}
                        />
                    </Box>
                </SectionCard>

                {/* Interview highlights */}
                <SectionCard
                    title="Interview Highlights"
                    titleIcon={SmartToyOutlinedIcon}
                >
                    <Box
                        p={2}
                        gap={2}
                        sx={{ display: "flex", flexDirection: "column" }}
                    >
                        <IconContent
                            text={
                                candidate?.summary?.salary ? (
                                    `${candidate?.summary?.salary} / net monthly`
                                ) : (
                                    <PlaceholderText returnOnlyText={true} />
                                )
                            }
                            icon={EuroOutlinedIcon}
                            tooltipText={"Preferred Monthly NET Salary"}
                            tooltipPlacement={"left"}
                        />

                        <IconContent
                            text={
                                candidate?.summary?.noticePeriod ? (
                                    `${candidate?.summary?.noticePeriod} weeks notice period`
                                ) : (
                                    <PlaceholderText returnOnlyText={true} />
                                )
                            }
                            icon={DateRangeOutlinedIcon}
                            tooltipText={"Notice Period in weeks"}
                            tooltipPlacement={"left"}
                        />
                        <IconContent
                            text={
                                candidate?.summary?.motivations ? (
                                    candidate?.summary?.motivations
                                        .map((each) => each?.name)
                                        .join(", ")
                                ) : (
                                    <PlaceholderText returnOnlyText={true} />
                                )
                            }
                            icon={ElectricMeterOutlinedIcon}
                            tooltipText={"Main Motivations for a change"}
                            tooltipPlacement={"left"}
                        />
                    </Box>
                </SectionCard>

                {/* Preferences highlights */}
                <SectionCard
                    title="Preferences Highlights"
                    titleIcon={SmartToyOutlinedIcon}
                >
                    <Box
                        p={2}
                        gap={2}
                        sx={{ display: "flex", flexDirection: "column" }}
                    >
                        <IconContent
                            text={
                                candidate?.summary?.workModel ? (
                                    candidate?.summary?.workModel
                                        ?.map((each) => each?.name)
                                        .join(", ")
                                ) : (
                                    <PlaceholderText returnOnlyText={true} />
                                )
                            }
                            icon={MapsHomeWorkOutlinedIcon}
                            tooltipText={"Preferred Working Model"}
                            tooltipPlacement={"left"}
                        />

                        <IconContent
                            text={
                                candidate?.summary?.contactTypes ? (
                                    candidate?.summary?.contactTypes
                                        ?.map((each) => each?.name)
                                        .join(", ")
                                ) : (
                                    <PlaceholderText returnOnlyText={true} />
                                )
                            }
                            icon={ConnectWithoutContactOutlinedIcon}
                            tooltipText={"Preferred Means of Contact"}
                            tooltipPlacement={"left"}
                        />
                    </Box>
                </SectionCard>
            </Stack>

            <Stack gap={3}>
                {/* Experience highlights */}
                <SectionCard
                    title="Experience highlights"
                    titleIcon={SmartToyOutlinedIcon}
                >
                    <Stack p={2} gap={3}>
                        {candidate?.totalYearsOfExperience && (
                            <Typography>
                                <b> {candidate?.totalYearsOfExperience}</b> of
                                total experience
                            </Typography>
                        )}

                        {candidate?.summary?.experienceHighlights?.length ? (
                            candidate?.summary?.experienceHighlights?.map(
                                (data, index) => {
                                    return (
                                        <SectorExperienceTree
                                            key={index}
                                            data={data}
                                        />
                                    );
                                }
                            )
                        ) : (
                            <Typography variant="subtitle2">
                                No experience to show
                            </Typography>
                        )}
                    </Stack>
                </SectionCard>
            </Stack>
        </Box>
    );
};

export default Summary;
