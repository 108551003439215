import { createSlice } from "@reduxjs/toolkit";

const experiences = createSlice({
    name: "experiences",
    initialState: { loading: false, data: [] },
    reducers: {
        setExperiencesLoadingStart: (state) => {
            state.loading = true;
        },
        setExperiences: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        addExperience: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        updateExperience: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
    },
});

export const {
    setExperiencesLoadingStart,
    setExperiences,
    addExperience,
    updateExperience,
} = experiences.actions;

export default experiences.reducer;
