import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { useUpdateCandidateById } from "hooks/useUpdateCandidateById";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import { InputAdornment } from "@mui/material";
import GeneralUtilityService from "services/GeneralUtilityService";

/**
 * Renders an inline text field.
 * @param {Object} props - The props object.
 * @param {*} props.defaultValue - The current value of the text field to display. Defaults to '-'.
 * @param {string} props.fieldName - The name attribute of the input field. Defaults to "name".
 * @param {Array} props.extraUpdateData - An array of additional data to be passed for updating the form. Defaults to an empty array.
 * @param {string} props.type - The type of the input field (e.g., text, number, email). Defaults to text.
 * @param {string} props.after - The string to display after the text field value. Defaults to an empty string.
 * @param {string} props.before - The string to display before the text field value. Defaults to an empty string.
 * @param {Object} props.editability - The editability object that holds the current active editor ID and setter function.
 * @param {string} props.id - The ID of this component, which is used to track which component is currently active for editing.
 * @returns {JSX.Element} - Returns a JSX element that renders the InlineFormText component.
 */

export const InlineFormText = ({
    defaultValue,
    fieldName = "name",
    extraUpdateData = [],
    before,
    type,
}) => {
    const { handleSubmit } = useForm();
    const candidate = useSelector((state) => state.currentCandidate);
    const [value, setValue] = useState(defaultValue);
    const [tempValue, setTempValue] = useState(value);
    const { updateCandidateById, isLoading } = useUpdateCandidateById();

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            editModeCancel();
        }
    };

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const editModeCancel = () => {
        setTempValue(value);
    };

    const onSubmit = async () => {
        if (!isLoading) {
            let userInput = tempValue;

            if (type === "month") {
                userInput =
                    GeneralUtilityService.convertMonthInputToDate(userInput);
            }

            setValue(tempValue);

            const updatedData = {
                ...extraUpdateData,
                [fieldName]: userInput,
            };
            await updateCandidateById(candidate.id, updatedData);
        }
    };

    const handleDynamicSubmitForm = (event) => {
        event.preventDefault();
        if (tempValue !== value) {
            handleSubmit(onSubmit)();
        }
    };

    return (
        <Box onKeyDown={handleKeyDown}>
            {isLoading && (
                <Box
                    sx={{
                        position: "absolute",
                        left: "100%",
                        transform: "translateX(calc(-100% - .5rem))",
                    }}
                >
                    <CircularProgress thickness={2} color="gray" size={20} />
                </Box>
            )}

            <Box
                component="form"
                onSubmit={handleDynamicSubmitForm}
                onKeyDown={handleKeyDown}
                sx={{
                    display: "flex",
                }}
            >
                <TextField
                    fullWidth
                    type={type}
                    label=""
                    color="focus"
                    onChange={(event) => setTempValue(event.target.value)}
                    onBlur={handleDynamicSubmitForm}
                    value={tempValue ?? ""}
                    variant="standard"
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                {before}
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );
};

export default InlineFormText;
