import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Divider from "@mui/material/Divider";

export const SectionCard = ({ candidate, ...props }) => {
    const { title, titleIcon: Icon } = props;

    return (
        <Paper
            elevation={3}
            sx={{
                borderRadius: "10px",
                width: "570px",
                height: "fit-content",
            }}
        >
            <Box
                px={2}
                py={1.5}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    {title}
                </Typography>
                {Icon ? (
                    <Icon sx={{ fontSize: "1.3rem", color: "gray.medium" }} />
                ) : (
                    ""
                )}
            </Box>
            <Divider />
            <Box>{props.children}</Box>
        </Paper>
    );
};

export default SectionCard;
