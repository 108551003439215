import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const PageTitle = ({ title }) => {
    return (
        <Box px={3}>
            <Typography variant="header2" component="h2" py={3}>
                {title}
            </Typography>
        </Box>
    );
};

export default PageTitle;
