import { useState } from "react";
import { Box, Stack, Button, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import axios from "utils/axiosConfig";
import { EnvService } from "infra/envs";
import { useDispatch } from "react-redux";
import { logout, clearStore } from "reducers/userValuesSlice";
import { StorageService } from "infra/storage";
import { AuthTokenService } from "infra/api/httpClient";

export default function ProfileMenuButton() {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = async () => {
        const logoutUrl = EnvService.getBackEndBaseUrl() + "/api/user/logout";
        dispatch(clearStore());
        StorageService.clearItem(StorageService.AUTH_TOKEN);
        StorageService.clearItem("globalAppData");
        // clear axios header
        AuthTokenService.unset();
        // set User values //redux
        dispatch(logout());
        await axios.post(logoutUrl);
    };
    return (
        <Box>
            <Stack direction="row" alignItems="center">
                <Tooltip title="Account settings">
                    <Button
                        onClick={handleClick}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        sx={{
                            padding: "8px",
                            textTransform: "none",
                            color: "primary",
                            borderRadius: "200px",
                            border: "1px solid",
                            borderColor: "gray.soft",
                        }}
                    >
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {/* <Avatar sx={{ width: 35, height: 35 }}>M</Avatar> */}
                            <Typography color="gray.dark`">Menu</Typography>
                        </Stack>
                    </Button>
                </Tooltip>
            </Stack>

            {/* Menu Box */}
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {/* <MenuItem onClick={handleClose}>
                    <Avatar /> Profile
                </MenuItem> */}
                {/* <Divider /> */}
                <MenuItem
                    onClick={() => {
                        handleLogout();
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    );
}
