import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export const YearAutoComplete = ({
    value,
    fieldName,
    multiple = false,
    freeSolo = false,
    disableClearable = true,
    disabled = false,
    options = [],
    formControls,
}) => {
    const { control } = formControls;    
    const convertedValue = value != null ? { id: Number(value), name: String(value) } : null;

    return (
        <Controller
            name={fieldName}
            control={control}
            defaultValue={convertedValue || '' }
            render={({ field }) => (
                <Autocomplete
                    fullWidth
                    id={fieldName}
                    multiple={multiple}
                    freeSolo={freeSolo}
                    disableClearable={disableClearable}
                    disabled={disabled}
                    autoComplete
                    options={options}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) => {
                        if (!value) return false;
                        return option.id === value.id;
                    }}
                    {...field}
                    onChange={(_, newValue) => {
                        field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            fullWidth
                            color="focus"
                            variant="standard"
                            name="active-field"
                            sx={{
                                color: "red",
                                ".MuiInputBase-root": {
                                    padding: "5px",
                                },
                                input: {
                                    padding: "0",
                                },
                            }}
                        />
                    )}
                />
            )}
        />
    );
};

export default YearAutoComplete;
