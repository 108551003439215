import { useEffect } from "react";
import { Box, Stack, Paper, Typography, Slide } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import PrimaryData from "components/lib-ui/parser/PrimaryData";
import SecondaryData from "components/lib-ui/parser/SecondaryData";
import PageTitle from "components/layout/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import UserAvatar from "components/lib-ui/UserAvatar";
import RappIcon from "assets/icons/RappIcon";
import { resetParseData } from "reducers/candidate/parsedProfileDataSlice";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
    table: {
        display: "grid",
        gridTemplateColumns: "180px 1fr 1fr",
    },
};

export const CandidateParse = () => {
    const parserData = useSelector((state) => state.parsedProfileDataSlice);

    const theme = useTheme();
    const dispatch = useDispatch();
    const { loading, editableData } = parserData;
    const title = "Parse candidate data";

    const profileExists = !!editableData?.existingUserData?.generalInfo?.id;

    const {
        firstName = "",
        lastName = "",
        id,
    } = editableData?.existingUserData?.generalInfo || {};
    const composeName = profileExists && `${firstName} ${lastName}`;

    useEffect(() => {
        const cleanupParsedCandidateData = () => {
            dispatch(resetParseData({}));
        };

        return cleanupParsedCandidateData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            sx={{
                height: "100%",
                background: theme.palette.gray.light,
            }}
        >
            <PageTitle title={title} />
            <Stack
                direction="row"
                spacing={4}
                alignItems="flex-start"
                sx={{
                    p: 3,
                }}
            >
                <Box>
                    {!!editableData?.generalInfo && !loading ? (
                        <Paper
                            elevation={3}
                            sx={{
                                px: 3,
                                py: 3,
                                background: "white",
                                borderRadius: "10px",
                                maxWidth: "1000px",
                                overflow: "hidden",
                            }}
                        >
                            <Box sx={styles.table}>
                                <Stack
                                    py={3}
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: "gray.dark" }}
                                    />
                                    <Typography
                                        color="gray.dark"
                                        textTransform="uppercase"
                                        variant="title"
                                        fontWeight="400"
                                    >
                                        Labels
                                    </Typography>
                                </Stack>

                                {profileExists && (
                                    <Stack
                                        py={3}
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        sx={{ width: "100%" }}
                                    >
                                        <RappIcon
                                            sx={{
                                                ml: 2,
                                            }}
                                            fill={theme.palette.gray.dark}
                                        />
                                        <Typography
                                            color="gray.dark"
                                            textTransform="uppercase"
                                            variant="title"
                                            fontWeight="400"
                                        >
                                            R.APP data
                                        </Typography>
                                    </Stack>
                                )}

                                <Stack
                                    py={3}
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{ width: "100%" }}
                                >
                                    <ImportExportOutlinedIcon
                                        sx={{ color: "gray.dark" }}
                                    />
                                    <Typography
                                        color="gray.dark"
                                        textTransform="uppercase"
                                        variant="title"
                                        fontWeight="400"
                                    >
                                        Imported data
                                    </Typography>
                                </Stack>
                            </Box>

                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        transition: "all .4s",
                                    }}
                                >
                                    <Slide
                                        in={!editableData?.isValidated}
                                        direction="right"
                                        mountOnEnter
                                        unmountOnExit
                                        timeout={800}
                                        sx={{
                                            width: "100%",
                                        }}
                                    >
                                        <Box>
                                            <PrimaryData data={editableData} />
                                        </Box>
                                    </Slide>

                                    <Slide
                                        in={editableData?.isValidated}
                                        direction="left"
                                        mountOnEnter
                                        unmountOnExit
                                        timeout={800}
                                        sx={{
                                            width: "100%",
                                        }}
                                    >
                                        <Box sx={{ background: "red" }}>
                                            <SecondaryData
                                                data={editableData}
                                                styles={styles}
                                            />
                                        </Box>
                                    </Slide>
                                </Box>
                            </Box>
                        </Paper>
                    ) : (
                        <Box>
                            <CircularProgress size={16} />
                        </Box>
                    )}
                </Box>

                {profileExists && (
                    <Stack
                        spacing={2}
                        sx={{
                            position: "sticky",
                            top: "2rem",
                        }}
                    >
                        <Typography
                            variant="title"
                            fontWeight="400"
                            color={theme.palette.gray.medium}
                        >
                            Affected candidate
                        </Typography>
                        <Link to={`/candidate/view/${id}`} target="_blank">
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    "&:hover p": {
                                        textDecoration: "underline",
                                    },
                                }}
                            >
                                <UserAvatar name={composeName} />
                                <Typography>{composeName}</Typography>
                                <ArrowForwardIcon />
                            </Stack>
                        </Link>
                    </Stack>
                )}
            </Stack>
        </Box>
    );
};

export default CandidateParse;
