import { useEffect } from "react";
import axios from "utils/axiosConfig";
import { EnvService } from "infra/envs";
import { AuthTokenService } from "infra/api/httpClient";
import ActionsApi from "./auth.api";
import { StorageService } from "infra/storage";
import { useDispatch } from "react-redux";
import { logout, clearStore } from "reducers/userValuesSlice";
import { startAppLoader, stopAppLoader } from "reducers/appLoaderSlice";
import { GlobalAppDataStorageService } from "infra/storage";
import { setUserId } from "reducers/userIdSlice";
import { useNavigate } from "react-router-dom";

const useTokenAuth = () => {
    const token = StorageService.getAuthToken() || "";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) return navigate("/login");

        // you need to check If have token then you call the API
        if (token) {
            const token = StorageService.getAuthToken();

            AuthTokenService.set(token);
            const fetchData = async () => {
                const token_access = await ActionsApi.tokenAuthRequest();
                const loggedUserId = token_access?.data?.id;
                dispatch(setUserId(loggedUserId));

                if (!token_access) {
                    const logoutUrl =
                        EnvService.getBackEndBaseUrl() + "/api/user/logout";

                    StorageService.clearItem(StorageService.AUTH_TOKEN);
                    StorageService.clearItem("globalAppData");
                    // clear axios header
                    AuthTokenService.unset();
                    // set User values //redux
                    dispatch(logout());
                    dispatch(clearStore());
                    await axios.post(logoutUrl);
                } else {
                    dispatch(startAppLoader());
                    let globalAppData =
                        await GlobalAppDataStorageService.loadGlobalAppData();
                    dispatch(stopAppLoader());
                    return globalAppData;
                }
            };
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useTokenAuth;
