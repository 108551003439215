export const companiesColumnHeaders = [
    {
        field: "id",
        headerName: "ID",
        width: 80,
    },
    {
        field: "companyName",
        headerName: "Company",
        sortable: false,
        width: 300,
    },
    {
        field: "linqCredits",
        headerName: "Linq credits",
        sortable: false,
        width: 170,
    },
    {
        field: "startDate",
        headerName: "Start Date",
        sortable: false,
        width: 170,
    },
    {
        field: "endDate",
        headerName: "End Date",
        sortable: false,
        width: 170,
    },
];

export default companiesColumnHeaders;
