import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

export const FormActions = (props) => {
    const { activeStep, navigate, loading, isLastStep } = props.stepState;

    return (
        <Box
            sx={{ display: "flex", justifyContent: "flex-end", p: 10 }}
            gap={2}
        >
            {activeStep ? (
                <Button
                    sx={{ minWidth: 200 }}
                    onClick={navigate.back}
                    size="large"
                    color="navy"
                    variant="text"
                >
                    Back
                </Button>
            ) : null}

            <LoadingButton
                size="large"
                color="primary"
                type="submit"
                loading={loading}
                variant="contained"
                loadingPosition="start"
                startIcon={isLastStep ? <SaveIcon /> : <PersonSearchIcon />}
            >
                <span>
                    {loading
                        ? isLastStep
                            ? "Saving..."
                            : "Checking If user exists"
                        : isLastStep
                        ? "Save user"
                        : "Check user and continue"}
                </span>
            </LoadingButton>
        </Box>
    );
};

export default FormActions;
