import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { GlobalAppDataStorageService } from "infra/storage";
import getErrorMessage from "assets/data/errors.json";

export const ExperienceFilters = ({ handleSubmit, formData }) => {
    const { control, watch, errors } = formData;
    const hardSkillsOptions = GlobalAppDataStorageService.getHardSkills() || [];
    const jobFunctionOptions =
        GlobalAppDataStorageService.getJobFunctions() || [];
    const occupationOptions =
        GlobalAppDataStorageService.getOccupations() || [];

    const watchYearsOfExperienceMinimum = watch("yearOfExperienceMinimum");
    const watchYearsOfExperienceMaximum = watch("yearOfExperienceMaximum");

    return (
        <>
            <Typography fontWeight="500">Experience</Typography>
            <Stack spacing={1}>
                {/* Experience in Hard Skills */}
                <Controller
                    name="skills"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            autoComplete
                            filterSelectedOptions
                            options={hardSkillsOptions}
                            getOptionLabel={(option) => option.name}
                            value={field.value}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                                handleSubmit(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Hard skills"
                                />
                            )}
                        />
                    )}
                />
                {/* Experience in Job Function */}
                <Controller
                    name="jobFunctions"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            autoComplete
                            filterSelectedOptions
                            options={jobFunctionOptions}
                            getOptionLabel={(option) => option.name}
                            value={field.value}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                                handleSubmit(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Job function"
                                />
                            )}
                        />
                    )}
                />
                {/* Experience in Occupation */}
                <Controller
                    name="occupations"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            autoComplete
                            filterSelectedOptions
                            options={occupationOptions}
                            getOptionLabel={(option) => option.name}
                            value={field.value}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                                handleSubmit(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Occupations"
                                />
                            )}
                        />
                    )}
                />

                {/* Years of Experience range */}
                <Stack>
                    <Typography
                        variant="subtitle3"
                        fontSize="0.8rem"
                        color="gray.medium"
                    >
                        Years of experience range
                    </Typography>
                    <Stack direction="row" gap={2}>
                        <Controller
                            name="yearOfExperienceMinimum"
                            control={control}
                            defaultValue=""
                            rules={{
                                validate: (value) => {
                                    const toValue =
                                        watchYearsOfExperienceMaximum;
                                    if (
                                        value &&
                                        toValue &&
                                        Number(value) > Number(toValue)
                                    ) {
                                        return getErrorMessage.generic.from
                                            .shouldBeSmaller;
                                    }
                                    return true;
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="From"
                                    variant="standard"
                                    type="number"
                                    onBlur={handleSubmit}
                                    error={!!errors.yearOfExperienceMinimum}
                                    helperText={
                                        errors.yearOfExperienceMinimum?.message
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="yearOfExperienceMaximum"
                            control={control}
                            defaultValue=""
                            rules={{
                                validate: (value) => {
                                    const fromValue =
                                        watchYearsOfExperienceMinimum;
                                    if (
                                        value &&
                                        fromValue &&
                                        Number(value) < Number(fromValue)
                                    ) {
                                        return getErrorMessage.generic.to
                                            .shouldBeLarger;
                                    }
                                    return true;
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="To"
                                    variant="standard"
                                    type="number"
                                    onBlur={handleSubmit}
                                    error={!!errors.yearOfExperienceMaximum}
                                    helperText={
                                        errors.yearOfExperienceMaximum?.message
                                    }
                                />
                            )}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default ExperienceFilters;
