import axios from "utils/axiosConfig";
class AuthTokenService {
    /**
     * In order to use JWT on each private request, we need to add them to the api request header as expected.
     * Axios has a header common set option, and we’ll use that with a helper method called setAuthToken().
     * @param {string} token
     */
    set = (token) => {
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    };

    unset = () => {
        delete axios.defaults.headers.common["Accept"];
        delete axios.defaults.headers.common["Authorization"];
    };
}

const authTokenServiceInstance = new AuthTokenService();
export default authTokenServiceInstance;
