import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    toggleCheckbox,
    toggleGroup,
    createGroup,
    addToGroup,
} from "reducers/candidate/parserCheckboxGroupSlice";
import { FormControlLabel, Checkbox } from "@mui/material";

function getGroupCheckStatus(group) {
    if (!group) return false;
    const allChecked = Object.values(group).every(Boolean);
    const anyChecked = Object.values(group).some(Boolean);
    return allChecked ? true : anyChecked ? "indeterminate" : false;
}

const CustomCheckbox = ({
    disabled = false,
    label = null,
    groupKey = null,
    checkboxKey = null,
    isGroupCheckbox = false,
    onChange: onCheckboxChange = null,
}) => {
    const dispatch = useDispatch();
    const checkboxGroups = useSelector(
        (state) => state.parserCheckboxGroupSlice.checkboxGroups
    );

    useEffect(() => {
        if (!isGroupCheckbox) {
            const payload = {
                groupId: groupKey,
                checkboxKey: checkboxKey,
                checked: true,
            };
            dispatch(addToGroup(payload));
        }

        if (isGroupCheckbox) {
            if (groupKey && !checkboxGroups[groupKey]) {
                dispatch(createGroup({ groupId: groupKey }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isChecked = checkboxKey
        ? checkboxGroups[groupKey]?.[checkboxKey] || false
        : getGroupCheckStatus(checkboxGroups[groupKey]);

    const handleChange = (event) => {
        const newCheckedState = checkboxKey
            ? event.target.checked
            : !getGroupCheckStatus(checkboxGroups[groupKey]);

        if (checkboxKey) {
            // Individual checkbox control
            dispatch(
                toggleCheckbox({
                    groupId: groupKey,
                    checkboxIndex: checkboxKey,
                    checked: newCheckedState,
                })
            );
        } else {
            // Group control
            dispatch(toggleGroup({ groupId: groupKey }));
        }

        if (onCheckboxChange) onCheckboxChange(event);
    };

    return (
        <FormControlLabel
            sx={{ m: 0 }}
            label={label}
            control={
                <Checkbox
                    disabled={disabled}
                    checked={isChecked === true}
                    indeterminate={isChecked === "indeterminate"}
                    onChange={handleChange}
                    size="small"
                />
            }
        />
    );
};

export default CustomCheckbox;
