import React, { useState } from "react";
import FormStepper from "./FormStepper";
import { Box, Stack } from "@mui/material/";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import axios from "utils/axiosConfig";
import SnackbarNotification from "./SnackbarNotification";
import { useNavigate } from "react-router";
import { urlService } from "infra/envs";
import useNotifications from "hooks/useNotifications";
import Utils from "services/GeneralUtilityService";

let stepState = {};

let steps = [
    <StepOne stepState={{ ...stepState }} />,
    <StepTwo stepState={{ ...stepState }} />,
];

const renderStep = (step) => {
    return React.cloneElement(step, { stepState: { ...stepState } });
};

/**
 * TODO: Refactor and move this function to a more reusable & appropriate utilities directory.
 *
 * @param {string} url - The endpoint URL to send the request.
 * @param {object} data - The data to be sent in the request.
 * @returns {number | object} - The response status or an object containing error information.
 */
const sendRequest = async (url, data) => {
    if (!url) {
        console.error("Error: URL is not defined");
        return;
    }
    try {
        const response = await axios.post(url, data, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        return { status: response.status, data: response.data };
    } catch (error) {
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log("Error", error.message);
        }
        console.error("Error:", error);
        return { status: error.response.status, data: error.response.data };
    }
};

export const AddCandidateForm = () => {
    const CANDIDATE_PATH = urlService.CANDIDATES_API_PATH;
    const VALIDATE_CANDIDATE_PATH = urlService.VALIDATE_CANDIDATE_PATH;
    const redirect = useNavigate();

    const { renderAddCandidateNotification } = useNotifications();

    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [stepOneData, setStepOneData] = useState(null);
    const [previousStep, setPreviousStep] = useState(null);
    const [isLastStep, setIsLastStep] = useState(
        activeStep === steps.length - 1 ? true : false
    );

    const [isFirstStep, setIsFirstStep] = useState(
        activeStep === 0 ? true : false
    );
    const [responseErrors, setResponseErrors] = useState([]);

    const navigateForward = () => {
        if (activeStep !== steps.length - 1) {
            setPreviousStep(activeStep);
            setActiveStep((previousStep) => previousStep + 1);
            setIsLastStep(activeStep !== steps.length - 1);
            setIsFirstStep(activeStep === 0);
        }
    };

    const navigateBack = () => {
        if (activeStep !== 0) {
            setPreviousStep(activeStep);
            setActiveStep((previousStep) => previousStep - 1);
            setIsLastStep(activeStep !== steps.length - 1);
            setIsFirstStep(activeStep === 0);
        }
    };

    const navigateTo = (step) => {
        const clampedStep = Math.min(Math.max(step, 0), steps.length - 1);
        setPreviousStep(activeStep);
        setActiveStep(clampedStep);
        setIsLastStep(clampedStep !== steps.length - 1);
        setIsFirstStep(clampedStep === 0);
    };

    const navigate = {
        forward: navigateForward,
        back: navigateBack,
        to: navigateTo,
    };

    stepState = {
        activeStep,
        previousStep,
        isFirstStep,
        stepOneData,
        isLastStep,
        navigate,
        loading,
    };

    const formBrain = async (data) => {
        let stepErrors = null;

        /**
         * TODO: Create a reusable function to generate API URLs based on a base URL and endpoint.
         */

        const handleFirstStep = async () => {
            const firstStepData = {
                source: data.source?.id,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.phone,
                collegelinkProfile: data.collegelinkProfile,
                linkedinProfile: data.linkedinProfile,
                calling_code_id: data.calling_code_id,
            };

            const clearedData = Utils.clearEmptyKeys(firstStepData);
            const stepResponse = await sendRequest(
                VALIDATE_CANDIDATE_PATH,
                clearedData
            );

            if (stepResponse.status === 204) {
                data.source = data.source?.id;
                setStepOneData(Utils.clearEmptyKeys(firstStepData));
                stepState.navigate.forward();
            }
            if (stepResponse.data.errors) stepErrors = stepResponse.data;
        };

        const handleSecondStep = async () => {
            const secondStepData = {
                cv: data.cv,
                country: data.country?.id,
                city: data.city?.id,
            };

            const updateData = {
                ...stepOneData,
                ...secondStepData,
            };

            updateData.collegelink_id = stepOneData?.collegelinkProfile;
            updateData.linkedin = stepOneData?.linkedinProfile;

            const stepResponse = await sendRequest(
                CANDIDATE_PATH,
                Utils.clearEmptyKeys(updateData)
            );

            if (stepResponse.status >= 200 && stepResponse.status < 300) {
                setTimeout(() => {
                    redirect("/");
                    renderAddCandidateNotification(stepResponse.data.id);
                }, 100);
            }
            if (stepResponse.errors) stepErrors = stepResponse;
        };

        setLoading(true);
        if (data.step === 1) await handleFirstStep();
        if (data.step === 2) await handleSecondStep();
        setLoading(false);

        // Error to UI
        setResponseErrors(false);
        if (stepErrors?.errors) {
            for (const key in stepErrors) {
                setResponseErrors(stepErrors[key]);
            }
        }
    };

    stepState.formSubmit = (data) => {
        formBrain(data);
    };

    return (
        <Box>
            <Box>
                {responseErrors && typeof responseErrors === "object" ? (
                    <Stack
                        spacing={2}
                        sx={{ position: "fixed", top: "20px", right: "20px" }}
                    >
                        <SnackbarNotification
                            messages={Object.entries(responseErrors).map(
                                (error) => {
                                    return error[1];
                                }
                            )}
                            severity="error"
                            containerStyle={{ marginBottom: "8px" }}
                        />
                    </Stack>
                ) : null}
            </Box>

            <FormStepper stepState={{ ...stepState }} />
            <Box sx={{ overflow: "hidden" }}>
                {renderStep(steps[activeStep])}
            </Box>
        </Box>
    );
};

export default AddCandidateForm;
