import { useState } from "react";
import useNotifications from "./useNotifications";
import { useDispatch } from "react-redux";
import { urlService } from "infra/envs";
import axios from "utils/axiosConfig";
import { currentCompany } from "reducers/company/companySlice";
import { extractErrorMessage } from "./useRecruitmentProcess";

export const useCompanySubscriptions = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const { renderNotification, renderErrorNotification } = useNotifications();
    const dispatch = useDispatch();

    const addLinqCredits = async (params) => {
        setIsLoading(true);
        const url = urlService.MODIFY_ACCESS_TO_TALENT_CREDITS_PATH;
        try {
            const response = await axios.post(url, params);
            const updatedCredits = response.data;
            dispatch(currentCompany(updatedCredits));
            setRequestSuccessful(true);
            setIsLoading(false);
            renderNotification(`Credits succesfully updated!`);
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed update credits");
        } finally {
            setIsLoading(false);
        }
    };

    const addSubscription = async (params) => {
        setIsLoading(true);
        const url = urlService.CREATE_ATT_SUBSCRIPTION_PATH;
        try {
            const response = await axios.post(url, params);
            const updatedSubscription = response.data;
            dispatch(currentCompany(updatedSubscription));
            setRequestSuccessful(true);
            setIsLoading(false);
            renderNotification(`Subscription succesfully updated!`);
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed update subscription");
        } finally {
            setIsLoading(false);
        }
    };

    const editSubscription = async (params) => {
        const url = urlService.EDIT_ATT_SUBSCRIPTION_PATH;
        setIsLoading(true);
        try {
            const response = await axios.post(url, params);
            const updatedSubscription = response.data;
            dispatch(currentCompany(updatedSubscription));
            setRequestSuccessful(true);
            renderNotification(`Subscription succesfully updated!`);
        } catch (error) {
            setRequestSuccessful(false);
            const errorMessage = extractErrorMessage(error.response.data);
            renderErrorNotification("Failed to update subscription");
            throw new Error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const addJobBoardCredits = async (credits, companyId) => {
        if (!credits || !companyId) return null;
        setIsLoading(true);

        const url = urlService.MODIFY_JOB_POST_CREDITS_PATH;
        const params = { credits, companyId };

        try {
            const { data: updatedCredits } = await axios.post(url, params);
            dispatch(currentCompany(updatedCredits));
            setRequestSuccessful(true);
            renderNotification(`Job board credits updated`);
        } catch (error) {
            const errorMessage = extractErrorMessage(error?.response?.data || {});
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed update credits");
        } finally {
            setIsLoading(false);
        }
    }

    return {
        addLinqCredits,
        addJobBoardCredits,
        addSubscription,
        editSubscription,
        isLoading,
        requestSuccessful,
    };
};
