import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://8f5098f7b7e340909337c9c2f00a5264@sentry.cl-team.xyz/4",
    integrations: [
        new Sentry.Integrations.Breadcrumbs({
            console: false,
        }),
    ],
});

ReactDOM.render(<App />, document.getElementById("app"));
