import React from "react";
import Box from "@mui/material/Box";

/**
 * Container for the content that lies under the Top section.
 */

export const Content = ({ children }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                minHeight: "calc(100vh - 70px)",
            }}
        >
            {children}
        </Box>
    );
};

export default Content;
