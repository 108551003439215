import URLService from "infra/envs/URL.service";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CollegeLinkIcon from "assets/icons/CollegeLinkIcon";
import CvIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconBtn from "components/lib-ui/IconBtn";
import Link from "@mui/material/Link";

const linkedinPrefix = "https://www.linkedin.com/in/";

export const tableColumnHeaders = [
    {
        field: "id",
        headerName: "ID",
        width: 80,
    },
    // TODO: Will be enabled once the elligibility functionality is live
    // {
    //     field: "elligible",
    //     headerName: "Elligible",
    //     width: 80,
    //     renderCell: (params) => (
    //         <div>
    //             {params.value === "Yes" ? (
    //                 <CheckCircleOutlinedIcon color="success" fontSize="small" />
    //             ) : (
    //                 <CancelOutlinedIcon color="error" fontSize="small" />
    //             )}
    //         </div>
    //     ),
    // },
    {
        field: "last_name",
        headerName: "Last name",
        sortable: false,
        width: 160,
    },
    {
        field: "first_name",
        headerName: "First name",
        sortable: false,
        width: 160,
    },
    {
        field: "email",
        headerName: "Email",
        sortable: false,
        width: 250,
        flex: 1,
    },
    {
        field: "phone_number",
        headerName: "Phone",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        flex: 1,
    },
    {
        field: "linkedin_profile",
        headerName: "LI",
        width: 5,
        renderCell: (params) => (
            <div>
                {params.value ? (
                    <Link
                        href={`${linkedinPrefix}${params.value}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <IconBtn icon={LinkedInIcon} />
                    </Link>
                ) : (
                    <IconBtn
                        disabled={true}
                        sx={{ opacity: 0.3 }}
                        icon={LinkedInIcon}
                    />
                )}
            </div>
        ),
    },
    {
        field: "collegelink_profile_id",
        headerName: "CL",
        width: 5,
        renderCell: (params) => (
            <div>
                {params.value !== null ? (
                    <Link
                        href={`${params.value}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <IconBtn icon={CollegeLinkIcon} />
                    </Link>
                ) : (
                    <IconBtn
                        disabled={true}
                        sx={{ opacity: 0.3 }}
                        icon={CollegeLinkIcon}
                    />
                )}
            </div>
        ),
    },
    {
        field: "cv",
        headerName: "CV",
        width: 5,
        renderCell: (params) => (
            <div>
                {params.value ? (
                    <IconBtn
                        onClick={() =>
                            window.open(
                                `${URLService.CANDIDATE_PROFILE_PATH}/${params.value}#Cv`
                            )
                        }
                        icon={CvIcon}
                    />
                ) : (
                    <IconBtn
                        disabled={true}
                        sx={{ opacity: 0.3 }}
                        icon={CvIcon}
                    />
                )}
            </div>
        ),
    },
    {
        field: "view",
        headerName: "View",
        description: "View the candidate's full",
        sortable: false,
        width: 55,
        renderCell: (params) => (
            <div>
                <Link
                    href={`${URLService.CANDIDATE_PROFILE_PATH}/${params.value}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <IconBtn icon={VisibilityIcon} />
                </Link>
            </div>
        ),
    },
];

export default tableColumnHeaders;
