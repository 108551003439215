import React from "react";
import Box from "@mui/material/Box";

/**
 * Container for the top section of the page.
 */

export const Top = ({ children }) => {
    return (
        <Box
            sx={{
                height: "70px",
                width: "100%",
                display: "flex",
                borderBottom: "1px solid #E0E0E0",
            }}
        >
            {children}
        </Box>
    );
};

export default Top;
