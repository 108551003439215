import React from "react";

import RappIcon from "assets/icons/RappIcon";

export const NotFound = () => {
    return (
        <div
            style={{
                maxWidth: "960px",
                width: "100%",
                margin: "30px auto",
                transform: "scale(0.8)",
                backgroundColor: "#000000 !important",
            }}
        >
            <div
                style={{
                    maxWidth: " 960px",
                    height: " 475px",
                    margin: " 0",
                    boxShadow: " 0px 0px 8px 1px #ccc",
                    background: " #fafafa",
                    borderRadius: " 8px",
                    display: " flex",
                    flexDirection: " column",
                    justifyContent: " center",
                    alignItems: " center",
                }}
            >
                <div
                    style={{ textAlign: "center", marginBottom: "40px" }}
                    className="icon__download"
                >
                    <RappIcon style={{ fontSize: "80px" }} />
                </div>

                <h1 style={{ fontSize: "100px" }}> 404</h1>
                <p
                    style={{
                        fontSize: "32px",

                        margin: "16px auto 38px",
                        textAlign: "center",
                    }}
                >
                    {" "}
                    Don't panic and make sure to check your oxygen levels!
                </p>
                <a
                    href="/"
                    style={{
                        borderRadius: " 50px",
                        padding: " 16px 50px",
                        fontSize: " 16px",
                        cursor: " pointer",
                        background: " #00314A",
                        color: " #fff",
                        border: " none",
                        boxShadow: " 0 4px 8px 0 #ccc",
                    }}
                >
                    Back to safety
                </a>
            </div>
        </div>
    );
};

export default NotFound;
