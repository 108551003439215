import { debounce } from "lodash";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import useFetchCompanies from "hooks/useFetchCompanies";
import { useEffect } from "react";
import { Controller } from "react-hook-form";

export const CompanyAutoComplete = ({
    value,
    fieldName,
    required = false,
    freeSolo = false,
    disableClearable = true,
    disabled = false,
    formControls,
}) => {
    const { control, setValue } = formControls;

    const { fetching, fetchCompaniesByName, companiesOptions } =
        useFetchCompanies();

    const debouncedFetchCompanyByName = debounce((value) => {
        fetchCompaniesByName(value);
    }, 500);

    const handleCompanyFetch = (event) => {
        debouncedFetchCompanyByName(event.target.value);
    };

    useEffect(() => {
        if (value) setValue(fieldName, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputBlur = (event) => {
        if (!freeSolo) return;
        const newValue = {
            name: event.target.value,
            id: null,
        };
        setValue(fieldName, newValue);
    };

    return (
        <Controller
            name={fieldName}
            control={control}
            defaultValue={value}
            render={({ field }) => (
                <Autocomplete
                    fullWidth
                    id={fieldName}
                    loading={fetching}
                    loadingText={"Loading companies"}
                    disableClearable={disableClearable}
                    disabled={disabled}
                    autoComplete
                    options={
                        companiesOptions.length ? companiesOptions : [value]
                    }
                    getOptionLabel={(option) =>
                        option?.name || option?.value || ""
                    }
                    isOptionEqualToValue={(option, value) => {
                        if (!value || value?.id === null) return;
                        return option.id === value.id;
                    }}
                    {...field}
                    onChange={(_, newValue) => {
                        field.onChange(newValue);
                        setValue(fieldName, newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            fullWidth
                            onInput={handleCompanyFetch}
                            required={required}
                            color="focus"
                            variant="standard"
                            onBlur={handleInputBlur}
                            sx={{
                                color: "red",
                                ".MuiInputBase-root": {
                                    padding: "5px",
                                },
                                input: {
                                    padding: "0",
                                },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {fetching && (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        )}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            )}
        />
    );
};

export default CompanyAutoComplete;
