import React from "react";
import { Box, Stack } from "@mui/material";
import SubscriptionsTable from "./sections/SubscriptionsTable";
import { useSelector } from "react-redux";
import TopSectionSkeleton from "./sections/TopSectionSkeleton";
import BodySectionSkeleton from "./sections/BodySectionSkeleton";
import TopSection from "./sections/TopSection";
import AccessToTalentCreditsTable from "./sections/AccessToTalentCreditsTable";
import { COMPANY_SERVICES } from "constants/subscriptions";

const creditsFinder = (companyServices, serviceId) => {
    return (
        companyServices?.find((item) => item.service.id === serviceId)
            ?.credits ?? 0
    );
};

const historyFinder = (companyServices, serviceId) => {
    return (
        companyServices?.find((item) => item.service.id === serviceId)
            ?.serviceActions ?? []
    );
};

export default function SubscriptionsAndCredits() {
    const company = useSelector((state) => state.companySlice);
    const companyServices = company?.companyServices;

    const actCredits = creditsFinder(
        companyServices,
        COMPANY_SERVICES.ACCESS_TO_TALENT.id
    );

    const jobBoardCredits = creditsFinder(
        companyServices,
        COMPANY_SERVICES.JOB_BOARD.id
    );

    const actCreditsHistory = historyFinder(
        companyServices,
        COMPANY_SERVICES.ACCESS_TO_TALENT.id
    );

    const subscriptionHistory = company?.subscriptions;
    const defaultAccessToTalentCredits = actCredits;
    const defaultJobBoardCredits = jobBoardCredits;
    const defaultSubscriptionDaysLeft =
        company?.activeSubscription?.remainingDays ?? "-";

    return (
        <Box
            component="article"
            aria-labelledby="access-to-talent-section-title"
        >
            <Stack gap={4}>
                {company ? (
                    <>
                        <TopSection
                            actCredits={defaultAccessToTalentCredits}
                            jobBoardCredits={defaultJobBoardCredits}
                            daysLeft={defaultSubscriptionDaysLeft}
                            companyId={company.id}
                        />
                        <Stack direction="row" gap={3}>
                            <AccessToTalentCreditsTable
                                rows={actCreditsHistory}
                            />
                            <Stack sx={{ width: "100%" }}>
                                <SubscriptionsTable
                                    rows={subscriptionHistory}
                                />
                            </Stack>
                        </Stack>
                    </>
                ) : (
                    <Stack gap={4}>
                        <TopSectionSkeleton />
                        <BodySectionSkeleton />
                    </Stack>
                )}
            </Stack>
        </Box>
    );
}
