import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const SectionRow = ({
    candidate,
    title,
    hasRowDivider = true,
    children,
    helperText,
}) => {
    return (
        <Box
            className="data-row"
            px={2}
            py={1}
            sx={{
                display: "grid",
                gridTemplateColumns: "200px auto",
                position: "relative",
                alignItems: "flex-start",
                "&:not(:last-child)": {
                    borderBottom: hasRowDivider ? "1px solid #dcdcdc" : "none",
                },
                ":hover [type='button']": {
                    opacity: "1",
                    pointerEvents: "auto",
                },
            }}
        >
            <Stack spacing={0.5}>
                <Typography variant="rowTitle" color="gray.dark">
                    {title}
                </Typography>
                <Typography variant="helperText">{helperText}</Typography>
            </Stack>

            <Box sx={{ width: "100%", overflow: "hidden" }}>{children}</Box>
        </Box>
    );
};

export default SectionRow;
