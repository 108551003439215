import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    Stack,
    TextField,
} from "@mui/material";
import ToggleContent from "components/lib-ui/ToggleContent";
import { Controller } from "react-hook-form";
import { GlobalAppDataStorageService } from "infra/storage";
import RecruitmentProccessCardService from "services/RecruitmentProccessCard.service";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useFetchHfrJobsAndCompanies from "hooks/useFetchHfrJobsAndCompanies";
import { useEffect } from "react";
import useRecruitersAccounts from "hooks/useRecruitersAccounts";

export const RecruitmentProcessFilters = ({ handleSubmit, formData }) => {
    const { control, watch, setValue, register } = formData;
    const theme = useTheme();
    const recruitersOptions = useSelector(
        (state) => state.recruitersAccounts.data
    );
    const { companyOptions, jobOptions } = useFetchHfrJobsAndCompanies();

    const rpStateOptions = GlobalAppDataStorageService.getRpStatesOptions();
    const rpStatusOptions = GlobalAppDataStorageService.getRpStatusesOptions();

    useRecruitersAccounts();

    /**
     * Transform Recruitment Process States and Statuses Filters.
     *
     * For a deeper understanding and context on how this transformation is utilized,
     * refer to the logic in `/RecruitmentProcessCard.service`.
     */
    const rpStateOptionsTransformed =
        RecruitmentProccessCardService.transformRpStatesForFilters(
            rpStateOptions
        );

    const rpStatusesOptionsTransformed =
        RecruitmentProccessCardService.transformRpStatusesForFilters(
            rpStatusOptions
        );

    const watchStatus = watch("rp_statuses");
    const watchState = watch("rp_states");

    const emptyStateOrStatus = !(
        watchState?.length > 0 || watchStatus?.length > 0
    );

    // when rp status date range becomes disabled clear the inputs of the dates
    useEffect(() => {
        setValue("rp_status_start_date", "");
        setValue("rp_status_end_date", "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emptyStateOrStatus]);

    const handleInputSubmit = (event) => {
        handleSubmit(event);
    };

    return (
        <>
            <ToggleContent
                title="Recruitment Processes"
                icon={<LinearScaleIcon fontSize="small" size="small" />}
            >
                <Stack spacing={1}>
                    <Controller
                        name="recruiters"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <Autocomplete
                                id="recruiters"
                                multiple
                                autoComplete
                                filterSelectedOptions
                                options={recruitersOptions || []}
                                getOptionLabel={(option) => {
                                    const composeName =
                                        option.name || option.surname
                                            ? `${option.name} ${option.surname}`
                                            : null;
                                    return (
                                        `${composeName ?? option.email}` ||
                                        `Loading_${option.id}`
                                    );
                                }}
                                value={field.value}
                                isOptionEqualToValue={(option, value) =>
                                    option?.id === value?.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                    handleInputSubmit(event);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Recruiters"
                                        variant="standard"
                                    />
                                )}
                            />
                        )}
                    />
                    <Controller
                        name="jobs"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <Autocomplete
                                id="jobs"
                                multiple
                                autoComplete
                                filterSelectedOptions
                                options={jobOptions || []}
                                getOptionLabel={(option) => {
                                    const jobId = option?.id;
                                    const jobName = option?.name;
                                    const companyName = option?.company?.name
                                        ? `@${option?.company?.name}`
                                        : "";

                                    return jobName
                                        ? `${jobId} ${jobName} ${companyName}`
                                        : `Loading_${jobId}`;
                                }}
                                value={field.value}
                                isOptionEqualToValue={(option, value) =>
                                    option?.id === value?.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                    handleInputSubmit(event);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Jobs"
                                        variant="standard"
                                    />
                                )}
                            />
                        )}
                    />
                    <Controller
                        name="companies"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <Autocomplete
                                id="companies"
                                multiple
                                autoComplete
                                filterSelectedOptions
                                options={companyOptions || []}
                                getOptionLabel={(option) => option.name || ""}
                                value={field.value}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value.name
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                    handleInputSubmit(event);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Companies"
                                        variant="standard"
                                    />
                                )}
                            />
                        )}
                    />

                    <Stack
                        direction="column"
                        sx={{
                            py: 5,
                        }}
                    >
                        <Stack
                            sx={{
                                backgroundColor: theme.palette.grey[100],
                            }}
                        >
                            <Controller
                                name="currentStateStatus"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onChange={(event) => {
                                                    field.onChange(
                                                        event.target.checked
                                                    );
                                                    handleInputSubmit(event);
                                                }}
                                            />
                                        }
                                        label="Exact matches only"
                                    />
                                )}
                            />

                            <Controller
                                name="rp_states"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="state"
                                        multiple
                                        autoComplete
                                        filterSelectedOptions
                                        options={rpStateOptionsTransformed}
                                        getOptionLabel={(option) => option.name}
                                        value={field.value}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                            handleInputSubmit(event);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="State"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                )}
                            />

                            <Controller
                                name="rp_statuses"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="status"
                                        multiple
                                        autoComplete
                                        filterSelectedOptions
                                        options={rpStatusesOptionsTransformed}
                                        value={field.value}
                                        getOptionLabel={(option) => option.name}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                            handleInputSubmit(event);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Status"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>

                    <TextField
                        {...register("rp_status_start_date")}
                        disabled={emptyStateOrStatus}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="rp_status_start_date"
                        defaultValue={undefined}
                        label="RP Status Start Date"
                        type="date"
                        onBlur={handleInputSubmit}
                        variant="standard"
                    />

                    <TextField
                        {...register("rp_status_end_date")}
                        disabled={emptyStateOrStatus}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="rp_status_end_date"
                        defaultValue={undefined}
                        label="RP Status End Date"
                        type="date"
                        onBlur={handleInputSubmit}
                        variant="standard"
                    />
                </Stack>
            </ToggleContent>
        </>
    );
};

export default RecruitmentProcessFilters;
