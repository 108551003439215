import axios from "utils/axiosConfig";
import { useState, useEffect } from "react";
import { EnvService } from "infra/envs";

export const useFetchUniversitiesAndDepartments = () => {
    const universitiesPath =
        EnvService.getBackEndBaseUrl() + "/api/universities";

    const [isLoadingUniversities, setIsLoadingUniversities] = useState(false);

    const [universitiesRequestSuccessful, setUniversitiesRequestSuccessful] =
        useState(false);

    const [universitiesOptions, setUniversitiesOptions] = useState([]);

    const departmentsPath = EnvService.getBackEndBaseUrl() + "/api/departments";

    const [isLoadingDepartments, setIsLoadingDepartments] = useState(false);

    const [departmentsRequestSuccessful, setDepartmentsRequestSuccessful] =
        useState(false);

    const [departmentsOptions, setDepartmentsOptions] = useState([]);

    useEffect(() => {
        const fetchUniversities = async () => {
            setIsLoadingUniversities(true);
            try {
                const response = await axios.get(universitiesPath);
                setUniversitiesOptions(response.data);
                setUniversitiesRequestSuccessful(true);
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsLoadingUniversities(false);
            }
        };
        fetchUniversities();

        const fetchDepartments = async () => {
            setIsLoadingDepartments(true);
            try {
                const response = await axios.get(departmentsPath);
                setDepartmentsOptions(response.data);
                setDepartmentsRequestSuccessful(true);
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsLoadingDepartments(false);
            }
        };
        fetchDepartments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoadingUniversities,
        universitiesRequestSuccessful,
        universitiesOptions,
        isLoadingDepartments,
        departmentsRequestSuccessful,
        departmentsOptions,
    };
};

export default useFetchUniversitiesAndDepartments;
