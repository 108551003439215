import { Stack } from "@mui/material";
import Steps from "./Steps";

export const HorizontalStepTimeline = ({ flowStepsData }) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={4}
            justifyContent="flex-start"
            sx={{
                width: "fit-content",
            }}
        >
            <Steps steps={flowStepsData} />
        </Stack>
    );
};

export default HorizontalStepTimeline;
