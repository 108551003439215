import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { GlobalAppDataStorageService } from "infra/storage";
import getErrorMessage from "assets/data/errors.json";

export const PreferredFilters = ({ handleSubmit, formData }) => {
    const { control, watch, getValues, errors } = formData;
    const { hard, soft } = GlobalAppDataStorageService.getSkills() || [];
    const skillsOptions = [...hard, ...soft];
    const industriesOptions = GlobalAppDataStorageService.getIndustries() || [];
    const jobFunctionOptions =
        GlobalAppDataStorageService.getJobFunctions() || [];
    const occupationOptions =
        GlobalAppDataStorageService.getOccupations() || [];
    const workingModelOptions =
        GlobalAppDataStorageService.getWorkingModels() || [];
    const jobTypeOptions = GlobalAppDataStorageService.getJobTypes() || [];

    const watchSalaryMinimum = watch("salaryMinimum");
    const watchSalaryMaximum = watch("salaryMaximum");

    const handleInputSubmit = (event) => {
        const filterData = getValues();

        const { salaryMinimum, salaryMaximum } = filterData;

        if (salaryMinimum || salaryMaximum) {
            filterData.preferredSalary = [
                salaryMinimum || 0, //If no min value is set, default to 0
                salaryMaximum || 999999, //If no max value is set, default to 999999
            ];
        }

        handleSubmit(event);
    };

    return (
        <>
            <Typography fontWeight="500">Preferences</Typography>
            <Stack spacing={1}>
                {/* Preferred Industry */}
                <Controller
                    name="preferredIndustries"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            autoComplete
                            filterSelectedOptions
                            options={industriesOptions}
                            getOptionLabel={(option) => option.name}
                            value={field.value}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                                handleInputSubmit(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Ιndustry"
                                />
                            )}
                        />
                    )}
                />
                {/* Preferred Job Function */}
                <Controller
                    name="preferredJobFunctions"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            autoComplete
                            filterSelectedOptions
                            options={jobFunctionOptions}
                            getOptionLabel={(option) => option.name}
                            value={field.value}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                                handleInputSubmit(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Job function"
                                />
                            )}
                        />
                    )}
                />
                {/* Preferred Occupation */}
                <Controller
                    name="preferredOccupations"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            autoComplete
                            filterSelectedOptions
                            options={occupationOptions}
                            getOptionLabel={(option) => option.name}
                            value={field.value}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                                handleInputSubmit(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Occupations"
                                />
                            )}
                        />
                    )}
                />
                {/* Preferred Skills */}
                <Controller
                    name="preferredSkills"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            autoComplete
                            filterSelectedOptions
                            options={skillsOptions}
                            getOptionLabel={(option) => option.name}
                            value={field.value}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                                handleInputSubmit(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Skills"
                                />
                            )}
                        />
                    )}
                />
                {/* Preferred Working Model */}
                <Controller
                    name="workingModels"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            autoComplete
                            filterSelectedOptions
                            options={workingModelOptions}
                            getOptionLabel={(option) => option.name}
                            value={field.value}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                                handleInputSubmit(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Working model"
                                />
                            )}
                        />
                    )}
                />
                {/* Preferred Job Type */}
                <Controller
                    name="jobTypes"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            autoComplete
                            filterSelectedOptions
                            options={jobTypeOptions}
                            getOptionLabel={(option) => option.name}
                            value={field.value}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            {...field}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                                handleInputSubmit(event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Job type"
                                />
                            )}
                        />
                    )}
                />
                {/* Preferred Salary Range */}
                <Stack>
                    <Typography
                        variant="subtitle2"
                        fontSize="0.8rem"
                        color="gray.medium"
                    >
                        Preferred salary range
                    </Typography>
                    <Stack direction="row" gap={2}>
                        <Controller
                            name="salaryMinimum"
                            control={control}
                            defaultValue=""
                            rules={{
                                validate: (value) => {
                                    const toValue = watchSalaryMaximum;
                                    if (
                                        value &&
                                        toValue &&
                                        Number(value) > Number(toValue)
                                    ) {
                                        return getErrorMessage.generic.from
                                            .shouldBeSmaller;
                                    }
                                    return true;
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="From"
                                    variant="standard"
                                    type="number"
                                    error={!!errors.salaryMinimum}
                                    onBlur={handleInputSubmit}
                                    helperText={errors.salaryMinimum?.message}
                                />
                            )}
                        />
                        <Controller
                            name="salaryMaximum"
                            control={control}
                            defaultValue=""
                            rules={{
                                validate: (value) => {
                                    const fromValue = watchSalaryMinimum;
                                    if (
                                        value &&
                                        fromValue &&
                                        Number(fromValue) >= Number(value)
                                    ) {
                                        return getErrorMessage.generic.to
                                            .shouldBeLarger;
                                    }
                                    return true;
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="To"
                                    variant="standard"
                                    type="number"
                                    error={!!errors.salaryMaximum}
                                    onBlur={handleInputSubmit}
                                    helperText={errors.salaryMaximum?.message}
                                />
                            )}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default PreferredFilters;
