import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export const ParserTextField = ({
    type = "text",
    value,
    fieldName,
    disabled = false,
    formControls,
}) => {
    const { control } = formControls;
    let inputType = "text";
    let multiline = false;
    let inputProps = {};

    switch (type) {
        case "textarea":
            multiline = true;
            break;
        case "number":
            inputType = "number";
            inputProps = { step: "0.1" };
            break;
        case "date":
            inputType = "date";
            break;
        default:
            inputType = type;
    }

    return (
        <Controller
            name={fieldName}
            control={control}
            defaultValue={value}
            render={({ field }) => (
                <TextField
                    {...field}
                    autoFocus
                    fullWidth
                    disabled={disabled}
                    multiline={multiline}
                    type={inputType}
                    inputProps={inputProps}
                    variant="standard"
                />
            )}
        />
    );
};

export default ParserTextField;
