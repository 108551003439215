import { Typography } from "@mui/material";

/**
 * PlaceholderText component displays a placeholder message in a specific format.
 * It can be used when data is missing or unavailable.
 *
 * @example
 * // To render the component with a default message
 * <PlaceholderText />
 *
 * @example
 * // To render the component with a custom message
 * <PlaceholderText message="Custom message" />
 *
 * @example
 * // To return only the text without rendering the component
 * <PlaceholderText returnOnlyText={true} />
 *
 * @param {Object} props - The component properties
 * @param {string} [props.message="Not available"] - The message to be displayed as a placeholder
 * @param {boolean} [props.returnOnlyText=false] - A flag to indicate if only the text should be returned without rendering the component
 * @returns {ReactElement} The rendered placeholder message, or just the text if returnOnlyText is true
 */
const PlaceholderText = ({ message = "-", returnOnlyText = false }) => {
    if (returnOnlyText) return message;
    return (
        <Typography color="gray.dark" fontSize=".9rem">
            {message}
        </Typography>
    );
};

export default PlaceholderText;
