import React, { useState } from "react";
import { Typography } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Box, Stack } from "@mui/system";

const ShowMore = ({
    items,
    initialCount,
    render,
    containerProps,
    buttonStyles,
}) => {
    const [showCount, setShowCount] = useState(initialCount);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        if (isExpanded) {
            setShowCount(initialCount);
        } else {
            setShowCount(items.length);
        }
        setIsExpanded(!isExpanded);
    };

    return (
        <Stack spacing={2}>
            <Box {...containerProps}>
                {items
                    .slice(0, showCount)
                    .map((item, index) => render(item, index))}

                {items.length > initialCount && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ cursor: "pointer", ...buttonStyles }}
                    >
                        {isExpanded ? (
                            <KeyboardArrowUpOutlinedIcon
                                fontSize="small "
                                color="gray"
                            />
                        ) : (
                            <KeyboardArrowDownOutlinedIcon
                                fontSize="small "
                                color="gray"
                            />
                        )}
                        <Typography
                            variant="subtitle2"
                            color="gray"
                            onClick={handleToggle}
                        >
                            {isExpanded ? "Show Less" : "Show More"}
                        </Typography>
                    </Stack>
                )}
            </Box>
        </Stack>
    );
};

export default ShowMore;
