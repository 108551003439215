import {
    formatDistanceToNow,
    parseISO,
    differenceInYears,
    differenceInMonths,
    isDate,
    parse,
    startOfMonth,
    format,
} from "date-fns";
import _ from "lodash";

class GeneralUtilityService {
    /**
     * Searches for an object in the provided array based on the given key
     * and a specified property to search within the objects. By default, the property is set to "id".
     * If a match is found, the function returns the corresponding object.
     * If no match is found, the function returns null.
     *
     * @function
     * @param {(number)} key - The key to search for in the array of objects.
     * @param {Array.<Object>} arrayOfObjects - The array of objects to search through.
     * @param {string} [searchKey="id"] - The property within the objects to search for the key (defaults to "id").
     * @returns {?Object} - The found object, or null if no match is found.
     */
    findObjectInArray(key, arrayOfObjects, searchKey = "id") {
        if (
            !Array.isArray(arrayOfObjects) ||
            key === undefined ||
            key === null
        ) {
            return null;
        }

        const foundObject = arrayOfObjects.find(
            (obj) => obj[searchKey] === key
        );

        return foundObject || null;
    }

    /**
     * Finds and returns objects from the first array that have matching keys in the second array.
     *
     * @param {Array<Object>} array1 - The first array of objects to search within.
     * @param {Array<Object>} array2 - The second array of objects to compare against.
     * @param {string} [searchKey='id'] - The object key to use for comparison. Defaults to 'id'.
     * @returns {Array<Object>|null} An array of matching objects or null if no matches are found or input is invalid.
     */
    findMatchingObjects(array1, array2, searchKey = "id") {
        // Validate input
        if (!Array.isArray(array1) || !Array.isArray(array2)) {
            return null;
        }

        // Find matching objects
        const matchingObjects = array1.filter((obj1) =>
            array2.some((obj2) => obj1[searchKey] === obj2[searchKey])
        );
        return matchingObjects.length > 0 ? matchingObjects : null;
    }

    /**
     * Converts a string into a hex color code.
     * @param {string} string - The string to be converted into a color code.
     * @returns {string} The hex color code generated from the string.
     */
    stringToColor(string) {
        if (!string || typeof string !== "string") return;

        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = "#";

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            const minValue = 50;
            const adjustedValue = Math.max(value, minValue);
            color += `00${adjustedValue.toString(16)}`.slice(-2);
        }

        return color;
    }

    /**
     * Returns initials of a given name in a string format.
     * @param {string} name - The name to be converted into initials.
     * @returns {string} The initials of the name as a string.
     */
    getStringInitals(name) {
        if (!name || typeof name !== "string") return;

        const nameParts = name.trim().split(" ");
        const hasMultipleParts = nameParts.length > 1;

        const initials = hasMultipleParts
            ? `${nameParts[0][0]}${nameParts[1][0]}`
            : `${nameParts[0][0]}${nameParts[0].length > 1 ? nameParts[0][1] : ""
            }`;

        return initials.toUpperCase();
    }

    /**
     * Returns the given string to camelCase format.
     * @param {string} key - The string to be converted to camelCase.
     * @returns {string} The converted camelCase string.
     */
    formatToCamelCase = (key) => {
        return key
            .toLowerCase()
            .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
                return index === 0 ? word.toLowerCase() : word.toUpperCase();
            })
            .replace(/\s+/g, "");
    };

    /**
     * Replaces spaces with hyphens in the given string.
     * @param {string} inputString - The string to process.
     * @returns {string} - The modified string with spaces replaced by hyphens.
     */
    replaceSpacesWithHyphens = (inputString) => {
        return inputString.replace(/\s+/g, "-");
    };

    /**
     * Returns a human-readable time difference string between the given date string and now.
     * @function
     * @param {string} dateString - The date string to compare with the current date.
     * @returns {string} - The human-readable time difference string.
     * @example
     * // The date string is set to read: 'D-M-YYYY' format, e.g., const dateString = '8-5-2023';
     */
    getTimeDifferenceString = (dateString) => {
        if (!dateString || typeof dateString !== "string") return;
        const dateFormat = "Y-m-d H:i:s";
        // 2023-05-11
        const parseDate = parseISO(dateString, dateFormat, new Date());
        return formatDistanceToNow(parseDate, { addSuffix: true });
    };

    /**
     * Returns the years passed between 2 given dates
     * @function
     * @param {string} startDate - start date
     * @param {string} endDate - end date
     * @returns {string} - years between 2 dates
     */
    calculateYearsAndMonthsBetweenDates(startDateStr, endDateStr) {
        const startDate = parseISO(startDateStr);
        let endDate;

        if (endDateStr && isDate(parseISO(endDateStr))) {
            endDate = parseISO(endDateStr);
        } else {
            endDate = new Date(); // Current date
        }

        const years = differenceInYears(endDate, startDate);
        if (years >= 1) {
            const months = differenceInMonths(endDate, startDate) - 12 * years;
            return { years, months };
        }
        const months = differenceInMonths(endDate, startDate);
        return { years, months };
    }

    /**
     * Converts a month input value in 'YYYY-MM' format to a string representing
     * the first day of that month in 'YYYY-MM-DD' format.
     *
     * @param {string} monthInputValue - The input value in 'YYYY-MM' format.
     *                                   Example: "2023-04" for April 2023.
     * @returns {string} A string representing the first day of the specified month
     *                   in 'YYYY-MM-DD' format.
     *
     * @example
     * // returns "2023-04-01" for 1st April 2023
     * convertMonthInputToDate("2023-04");
     */
    convertMonthInputToDate(monthInputValue) {
        if (!monthInputValue) return;
        const parsedDate = parse(monthInputValue, "yyyy-MM", new Date());
        const firstDayOfMonth = startOfMonth(parsedDate);
        return format(firstDayOfMonth, "yyyy-MM-dd");
    }

    /**
     * Converts a JavaScript Date object or a date string in 'YYYY-MM-DD' format
     * to a string in 'YYYY-MM' format suitable for a month input.
     *
     * @param {Date|string} date - The date to be converted. Can be a Date object
     *                             or a string in 'YYYY-MM-DD' format.
     * @returns {string} A string representing the year and month in 'YYYY-MM' format.
     *
     * @example
     * // returns "2023-04" for April 2023
     * convertDateToMonthInput(new Date(2023, 3, 1));
     */
    convertDateToMonthInput(date) {
        if (!date) return;
        let dateObj;
        if (typeof date === 'string') {
            dateObj = new Date(date);
            if (isNaN(dateObj)) {
                console.error('Invalid date string');
                return;
            }
        } else if (date instanceof Date) {
            dateObj = date;
        } else {
            console.error('Invalid date type');
            return;
        }
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Adding 1 to month as getMonth() returns 0-based index

        return `${year}-${month}`;
    }
    /**
     * Filters an array of options based on the provided category.
     * @function
     * @param {array} options - The array containing options to be filtered.
     * @param {boolean} isInternal - Flag indicating the category to filter by. Set to true for "Internal Screening" and false for "Client Screening".
     * @returns {array} - An array of options belonging to either "Internal Screening" or "Client Screening" category based on the isInternal flag.
     */
    filterOptionsByFlow = (options, isInternal = true) => {
        if (isInternal)
            return options.filter(
                (option) => option.category.id === 1 // "Internal Screening"
            );
        return options.filter(
            (option) => option.category.id === 3 // "Client Screening"
        );
    };

    /**
     * This function takes an array as input and returns the last item.
     *
     * @param {Array} array - The array from which to retrieve the last item.
     * @returns {any} The last item from the array. If the array is empty, returns `undefined`.
     */
    getLastItem(array) {
        const [last] = array.slice(-1);
        return last;
    }

    /**
     * Clear Empty Keys from an object.
     * Removes keys with empty, undefined, null, or empty array values.
     *
     * @param {Object} obj - The object to be processed.
     * @returns {Object} - The processed object with empty keys removed.
     */
    clearEmptyKeys(obj) {
        for (const [key, value] of Object.entries(obj)) {
            if (
                value === "" ||
                value === undefined ||
                value === null ||
                (Array.isArray(value) && value.length === 0)
            ) {
                delete obj[key];
            }
        }
        return obj;
    }

    /**
     * Recursively clears empty keys from an object or array.
     * Removes keys with empty strings, undefined, null, empty arrays, or empty objects.
     *
     * @param {Object|Array} obj - The object or array to be processed.
     * @returns {Object|Array} - The processed object or array with empty keys and empty objects removed.
     */
    clearDeepEmptyKeys(obj) {
        if (typeof obj !== "object" || obj === null) return obj;

        // Create a shallow copy of the object to avoid modifying read-only properties
        obj = Array.isArray(obj) ? [...obj] : { ...obj };

        if (Array.isArray(obj)) {
            return obj
                .map((value) => this.clearDeepEmptyKeys(value))
                .filter(
                    (value) =>
                        !(
                            typeof value === "object" &&
                            Object.keys(value).length === 0
                        )
                )
                .filter(
                    (value) =>
                        value !== null && value !== undefined && value !== ""
                );
        }

        for (const [key, value] of Object.entries(obj)) {
            if (
                value === "" ||
                value === undefined ||
                value === null ||
                (Array.isArray(value) && value.length === 0)
            ) {
                delete obj[key];
            } else if (typeof value === "object") {
                obj[key] = this.clearDeepEmptyKeys(value);
                if (Object.keys(obj[key]).length === 0) {
                    delete obj[key];
                }
            }
        }
        return obj;
    }

    /**
     * Checks if the provided value is empty or evaluates to false.
     * The function considers the following as empty or false:
     * - false
     * - null
     * - undefined
     * - Empty string ('')
     * - Zero (0) and NaN
     * - Empty array ([])
     * - Empty object ({})
     * Note: This function uses Lodash's _.isEmpty() to check for emptiness in arrays and objects.
     *
     * @param {any} value - The value to be checked.
     * @returns {boolean} Returns true if the value is empty or false, otherwise returns false.
     */
    isEmpty(value) {
        // Check for null, 0, NaN, and empty string
        if (
            value === null ||
            value === 0 ||
            Number.isNaN(value) ||
            value?.length === 0
        ) {
            return true;
        }

        // Check for numeric values
        if (typeof value === "number") {
            return false;
        }

        if (
            (typeof value === "object" && this.isObjectEmpty(value)) ||
            this.isIdAndNameEmpty(value)
        )
            return true;

        // For other cases, use lodash's isEmpty function
        return _.isEmpty(value);
    }

    /**
     * Converts the given value to a number if it represents a valid year (e.g., '2013').
     * If the value is not a valid year, it returns the original value.
     *
     * @param {any} value - The value to be converted to a number or left unchanged.
     * @returns {number|any} - If the value is a valid year, returns the numeric representation; otherwise, returns the original value.
     */
    convertToNumberOrLeaveUnchanged(value) {
        // Check if the value is a string representing a valid year
        const isYearString = /^\d{4}$/.test(value);

        // If it's a valid year string, convert it to a number
        return isYearString ? Number(value) : value;
    }

    /**
     * Checks if an object is empty.
     *
     * @param {Object} obj - The object to check.
     * @returns {boolean} True if the object is empty or not an object, false otherwise.
     */
    isObjectEmpty(obj) {
        if (typeof obj !== "object" || obj === null) return true;
        return Object.keys(obj).length === 0;
    }

    /**
     * Checks if an object has 'id' and 'name' properties that are either null or empty strings.
     *
     * @param {Object} obj - The object to be checked.
     * @returns {boolean} Returns true if 'id' and 'name' are null or empty, false otherwise.
     */
    isIdAndNameEmpty(obj) {
        if (!_.isPlainObject(obj)) return false;

        const hasIdAndName = "id" in obj && "name" in obj;
        const areIdAndNameEmpty =
            (obj.id === null || obj.id === "") &&
            (obj.name === null || obj.name === "");

        return hasIdAndName && areIdAndNameEmpty;
    }

    /**
     * Scrolls smoothly to the top of the page.
     * @param {number} duration - The duration of the scroll animation in milliseconds.
     */
    scrollToTop(duration = 500) {
        const scrollStep = -window.scrollY / (duration / 15); // Adjust scrolling speed here
        const scrollAnimation = () => {
            if (window.scrollY !== 0) {
                window.scrollBy(0, scrollStep);
                requestAnimationFrame(scrollAnimation);
            }
        };
        requestAnimationFrame(scrollAnimation);
    }

    /**
     * Checks if an object is empty.
     *
     * @param {number} key - The amount of years to deduct and add to current year.
     * @returns {Array} Returns and array of objects with all the years within the given range.
     */
    getYearOptions(yearRange) {
        const today = new Date();
        const currentYear = today.getFullYear();
        const yearOptions = [];

        for (
            let i = currentYear - yearRange;
            i <= currentYear + yearRange;
            i++
        ) {
            yearOptions.push({ id: i, name: String(i) });
        }

        return yearOptions;
    }

    /**
     * Extracts the error message from the given error object.
     *
     * @param {object} errorObject - The object that containts the error.
     * @returns {string} Returns the string containing the error message.
     */
    extractErrorMessage = (errorObject) => {
        const keys = Object.keys(errorObject);
        if (keys.length === 0) return null;

        const firstKey = keys[0];
        const firstErrorMessage = errorObject[firstKey];

        // If it's an array, join the messages together with a space.
        const errorMessage = Array.isArray(firstErrorMessage)
            ? firstErrorMessage.join(" ")
            : firstErrorMessage;

        return `${errorMessage}`;
    };

    /**
     * Simplifies an array of recruiter objects to include only 'id' and 'name'.
     * Handles various possible fields for name composition.
     * @param {Array<Object>} rawRecruiters - An array of complex recruiter objects.
     * Each object may contain 'id', 'name', 'surname', 'firstName', 'lastName', and 'email'.
     *
     * @returns {Array<Object>} An array of simplified recruiter objects, each containing 'id' and 'name'.
     */
    simplifyRecruiterData(rawRecruiters) {
        const getFormattedName = ({
            name,
            surname,
            firstName,
            lastName,
            email,
        }) => {
            if (name && surname) {
                return `${name} ${surname} - ${email}`.trim();
            }
            if (firstName && lastName) {
                return `${firstName} ${lastName} - ${email}`.trim();
            }
            return email;
        };

        const toSimplifiedFormat = (recruiter) => {
            const formattedName = getFormattedName(recruiter);
            return {
                id: recruiter.id,
                name: formattedName,
            };
        };

        if (Array.isArray(rawRecruiters)) {
            return rawRecruiters.map(toSimplifiedFormat);
        } else {
            return toSimplifiedFormat(rawRecruiters);
        }
    }

    simplifySpecializationOptions(rawSpecializationOptions) {
        return rawSpecializationOptions.map(({ code, description, id }) => {
            return {
                code,
                name: `${code} - ${description}`,
                id,
            };
        });
    }

    /*
     * Checks if 2 arrays have the same (primitive)items.
     * @param {Array} arr1 - The firtst array to check.
     * @param {Array} arr2 - The second array to check.
     * @returns {Boolean} Returns true if the arrays have the same items, false if not
     */
    areArraysEqual(arr1, arr2) {
        return (
            arr1?.length === arr2?.length &&
            arr1?.every((element, index) => element === arr2[index])
        );
    }

    /*
     * Adds debounce to a function call
     * @param func - The function to apply the debounce to.
     * @param delay - The delay added to the debounce
     */
    debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    getTypeOfValue = (value) => {
        if (Array.isArray(value)) {
            return "array";
        } else if (typeof value === "object") {
            return "object";
        }
        return typeof value;
    };

    deepClone = (object) => {
        return _.cloneDeep(object);
    };

    formattedDate = (date) => {
        const parsedDate = parseISO(date);

        return format(parsedDate, "PP");
    };
}

const service = new GeneralUtilityService();
export default service;
