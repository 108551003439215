import IconBtn from "components/lib-ui/IconBtn";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { LinqRequestStatusChip } from "./LinqRequestStatusChip";

export const linqTableColumnHeaders = () => [
    {
        field: "candidate_id",
        headerName: "Candidate ID",
        width: 160,
    },
    {
        field: "candidate_name",
        headerName: "Candidate name",
        width: 160,
    },
    {
        field: "candidate_phone",
        headerName: "Candidate phone",
        sortable: false,
        width: 160,
    },
    {
        field: "candidate_email",
        headerName: "Candidate email",
        sortable: false,
        width: 160,
    },
    {
        field: "company_id",
        headerName: "Company ID",
        width: 160,
    },
    {
        field: "company",
        headerName: "Company name",
        sortable: false,
        width: 160,
    },
    {
        field: "employer_email",
        headerName: "Employer email",
        sortable: false,
        width: 160,
    },
    {
        field: "status",
        headerName: "Status",
        sortable: false,
        width: 160,
        renderCell: (params) => {
            return <LinqRequestStatusChip status={params.row.status} />;
        },
    },

    {
        field: "edit",
        headerName: "",
        description: "",
        sortable: false,
        width: 55,
        renderCell: (params) => (
            <div>
                <IconBtn
                    defaulted={true}
                    sx={{
                        top: 0,
                        right: 0,
                        border: "unset",
                    }}
                    icon={EditOutlinedIcon}
                    iconStyles={{ fontSize: "1.2rem" }}
                ></IconBtn>
            </div>
        ),
    },
];

export default linqTableColumnHeaders;
