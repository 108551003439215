import { useState, useRef, useEffect } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import { currentCandidate } from "reducers/candidate/currentCandidateSlice";
import SaveIcon from "@mui/icons-material/Save";
import { useUploadCv } from "hooks/useCandidateCv";
import { urlService } from "infra/envs";

export const AddCvForm = ({
    title,
    onSubmit,
    setIsDialogOpen,
    setRenderKey,
}) => {
    const candidate = useSelector((state) => state.currentCandidate);
    const dispatch = useDispatch();
    const cvRef = useRef();
    const [cv, setCv] = useState("");

    const UPLOAD_PATH = urlService.CANDIDATE_CV_UPLOAD_PATH;
    const { uploadCv, isLoading, requestSuccessful } = useUploadCv();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (requestSuccessful) {
            setIsDialogOpen(false);
            setRenderKey((prevKey) => prevKey + 1);
        }
    }, [requestSuccessful, setIsDialogOpen, setRenderKey]);

    const cvBaseURL = urlService.CANDIDATE_CV_PATH;
    const submitForm = async (data) => {
        if (!isLoading) {
            await uploadCv(UPLOAD_PATH, {
                candidateId: candidate.id,
                cv: data.cv[0],
            });
            dispatch(
                currentCandidate({
                    ...candidate,
                    cv: {
                        hasCv: 1,
                        cvUrl: `${cvBaseURL}${candidate.id}`,
                    },
                })
            );
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={2}>
                <Typography variant="header2">{title}</Typography>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
                <TextField
                    name="cv"
                    fullWidth
                    defaultValue={cv}
                    InputLabelProps={{ shrink: true }}
                    {...register("cv")}
                    error={Boolean(errors.cv)}
                    helperText={errors.cv ? errors.cv.message : ""}
                    InputProps={{
                        accept: "application/pdf",
                        inputMode: "file",
                        endAdornment: cv && (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Clear"
                                    onClick={() => {
                                        setCv(null);
                                        cvRef.current.value = "";
                                    }}
                                >
                                    <ClearIcon color="grey" fontSize="14px" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    type="file"
                    label="Upload CV"
                    inputRef={cvRef}
                    onChange={(event) => setCv(event.target.files[0])}
                />
            </Box>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    color="gray"
                    variant="text"
                    onClick={() => {
                        setIsDialogOpen(false);
                    }}
                >
                    Cancel
                </Button>

                <LoadingButton
                    disabled={cv === "" || cv === null}
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                >
                    Save
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default AddCvForm;
