import { AutoAwesome, DateRange, FeaturedVideo } from "@mui/icons-material";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useCompanySubscriptions } from "hooks/useCompanySubscriptions";
import { useForm } from "react-hook-form";

export default function TopSection({
    actCredits,
    jobBoardCredits,
    daysLeft,
    companyId,
}) {
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm({ defaultValues: { credits: "", jobBoardCredits: "" } });
    const { addLinqCredits, addJobBoardCredits } = useCompanySubscriptions();

    const handleAccessToTalentCreditChange = async (data) => {
        if (data?.credits === actCredits || !data?.credits) return;
        const params = { credits: data.credits, companyId };
        await addLinqCredits(params);
        setValue("credits", "");
    };

    const handleJobBoardCreditChange = async (data) => {
        if (data.jobBoardCredits === jobBoardCredits || !data?.jobBoardCredits)
            return;

        await addJobBoardCredits(data.jobBoardCredits, companyId);
        setValue("jobBoardCredits", "");
    };

    return (
        <Stack
            direction="row"
            gap={2}
            aria-label="Credits and Subscriptions Summary"
        >
            {/* Access to talent credits */}
            <Stack
                component="form"
                direction="row"
                gap={2}
                aria-label="Access to Talent"
                onBlur={handleSubmit(handleAccessToTalentCreditChange)}
            >
                <AutoAwesome
                    sx={{ color: "grey", fontSize: 30 }}
                    aria-hidden="true"
                />
                {/* {actCredits} */}
                <TextField
                    type="number"
                    name="credits"
                    placeholder="Type the number of credits"
                    {...register("credits")}
                    label="Access to talent"
                    inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        "aria-label": "Access to talent credits input",
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.credits}
                    helperText={
                        errors.credits
                            ? errors.credits.message
                            : "To remove credits type a negative number eg -100"
                    }
                    size="small"
                    variant="standard"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Typography
                                    color="primary"
                                    mb={"5px"}
                                    component="span"
                                >
                                    {`${actCredits} credits`}
                                </Typography>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            {/* Job board credits */}
            <Stack
                component="form"
                direction="row"
                gap={2}
                aria-label="Job Board"
                onBlur={handleSubmit(handleJobBoardCreditChange)}
            >
                <FeaturedVideo sx={{ color: "grey", fontSize: 30 }} />
                <TextField
                    type="number"
                    label="Job board"
                    {...register("jobBoardCredits")}
                    placeholder="Type the number of credits"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        "aria-label": "Job board credits input",
                    }}
                    size="small"
                    variant="standard"
                    helperText={
                        errors.jobBoardCredits
                            ? errors.jobBoardCredits.message
                            : "To remove credits type a negative number eg -100"
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Typography
                                    color="primary"
                                    mb={"5px"}
                                    component="span"
                                >
                                    {`${jobBoardCredits} credits`}
                                </Typography>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            {/* Subscription Days Left */}
            <Stack direction="row" gap={2} aria-label="Subscription Days Left">
                <DateRange
                    sx={{
                        color: "grey",
                        fontSize: 30,
                    }}
                    aria-hidden="true"
                />
                <Stack gap={0}>
                    <Typography
                        variant="caption"
                        sx={{ color: "grey" }}
                        id="subscription-days-left"
                    >
                        Subscription days left
                    </Typography>
                    <Typography
                        pt={"1px"}
                        pb={"5px"}
                        variant="body1"
                        aria-labelledby="subscription-days-left"
                    >
                        {daysLeft}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}
