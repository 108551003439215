import { useState } from "react";
import axios from "utils/axiosConfig";
import { EnvService } from "infra/envs";
import useNotifications from "./useNotifications";

export const useInsertCompany = () => {
    const [isInsertLoading, setIsInsertLoading] = useState(false);
    const [insertSuccessful, setInsertSuccessful] = useState(false);
    const { renderNotification, renderErrorNotification } = useNotifications();

    const insertCompany = async (data) => {
        setIsInsertLoading(true);
        const endpoint = `${EnvService.getBackEndBaseUrl()}/api/companies`;
        const insertData = {
            name: data?.name,
            companySizeId: data?.companySizeId?.id,
            industries: data?.industries?.map((industry) => industry.id),
            companyTypeId: data?.companyTypeId?.id,
            websiteUrl: data?.websiteUrl,
        };

        try {
            const response = await axios.post(endpoint, insertData);
            const newCompanyId = response?.data?.id;
            setInsertSuccessful(true);
            renderNotification("Company inserted successfully");
            setIsInsertLoading(false);
            return newCompanyId;
        } catch (error) {
            setInsertSuccessful(false);
            renderErrorNotification(`Error updating candidate: ${error}`);
            console.error("Error updating candidate:", error);
            setIsInsertLoading(false);
            throw new Error("Error updating candidate");
        }
    };
    return { insertCompany, isInsertLoading, insertSuccessful };
};

export default useInsertCompany;
