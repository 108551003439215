import SectionCard from "../../SectionCard";
import SectionRow from "../../SectionRow";
import InlineFormText from "components/lib-ui/inline-forms/InlineFormText";
import InlineFormSelect from "components/lib-ui/inline-forms/InlineFormSelect";
import InlineFormAutocomplete from "components/lib-ui/inline-forms/InlineFormAutocomplete";
import { GlobalAppDataStorageService } from "infra/storage";
import GeneralUtilityService from "services/GeneralUtilityService";
import AccessToTalentLabel from "components/lib-ui/AccessToTalentLabel";

export const LookingFor = ({ candidate }) => {
    const workingModelOptions = GlobalAppDataStorageService.getWorkingModels();
    const jobTypeOptions = GlobalAppDataStorageService.getJobTypes();
    const hardSkillsOptions = GlobalAppDataStorageService.getHardSkills();
    const mobilityOptions = GlobalAppDataStorageService.getMobilities();
    const companySizeOptions = GlobalAppDataStorageService.getCompanySizes();
    const clientFacingOptions = [
        {
            name: "Yes",
            id: 1,
        },
        {
            name: "No",
            id: 0,
        },
    ];
    const {
        clientFacing,
        companySize,
        jobType,
        mobility,
        jobFunctions,
        preferredSkills,
        otherSkills,
        occupations,
        teamSize,
        workModel,
        industries,
    } = candidate;

    const industriesOptions = GlobalAppDataStorageService.getIndustries();

    const jobFunctionsOptions = GlobalAppDataStorageService.getJobFunctions();

    const occupationsOptions = GlobalAppDataStorageService.getOccupations();

    return (
        <SectionCard title="Looking for">
            <SectionRow title={<AccessToTalentLabel label="Working model" />}>
                <InlineFormSelect
                    multiple
                    fieldName="working_models"
                    options={workingModelOptions}
                    selected={workModel}
                />
            </SectionRow>

            <SectionRow title="Job type">
                <InlineFormSelect
                    multiple
                    fieldName="job_types"
                    options={jobTypeOptions}
                    selected={jobType}
                />
            </SectionRow>
            <SectionRow title="Preferred industry">
                <InlineFormAutocomplete
                    fieldName="industries"
                    options={industriesOptions}
                    selected={industries}
                    chipAttributes={{
                        sx: { padding: "10px 5px" },
                    }}
                />
            </SectionRow>

            <SectionRow
                title={<AccessToTalentLabel label="Preferred job functions" />}
            >
                <InlineFormAutocomplete
                    fieldName="job_functions"
                    options={jobFunctionsOptions}
                    selected={jobFunctions}
                    chipAttributes={{
                        color: "navy",
                        sx: { padding: "10px 5px" },
                    }}
                />
            </SectionRow>

            <SectionRow
                title={<AccessToTalentLabel label="Preferred ocupations" />}
            >
                <InlineFormAutocomplete
                    fieldName="occupations"
                    options={occupationsOptions}
                    selected={occupations}
                    chipAttributes={{
                        color: "greenCyan",
                        variant: "outlined",
                        sx: { padding: "10px 5px", color: "black" },
                    }}
                />
            </SectionRow>

            <SectionRow
                title={<AccessToTalentLabel label="Preferred skills" />}
            >
                <InlineFormAutocomplete
                    fieldName="skills"
                    options={hardSkillsOptions}
                    selected={preferredSkills}
                    extraUpdateData={otherSkills}
                    chipAttributes={{
                        sx: { padding: "10px 5px" },
                    }}
                />
            </SectionRow>

            <SectionRow title="Mobility">
                <InlineFormSelect
                    multiple
                    fieldName="mobilities"
                    options={mobilityOptions}
                    selected={mobility}
                />
            </SectionRow>

            <SectionRow title="Company size">
                <InlineFormSelect
                    multiple
                    fieldName="company_sizes"
                    options={companySizeOptions}
                    selected={companySize}
                />
            </SectionRow>

            <SectionRow title="Team size">
                <InlineFormText
                    fieldName="team_size"
                    type="number"
                    defaultValue={teamSize}
                />
            </SectionRow>

            <SectionRow title="Client facing">
                <InlineFormSelect
                    fieldName="client_facing"
                    options={clientFacingOptions}
                    selected={GeneralUtilityService.findObjectInArray(
                        clientFacing ? 1 : 0,
                        clientFacingOptions
                    )}
                />
            </SectionRow>
        </SectionCard>
    );
};

export default LookingFor;
