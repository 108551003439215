import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    editableData: {
        isValidated: false,
        existingUserData: {},
    },
    submittedDataToUnflatten: {},
    submitData: {},
};

const parsedProfileDataSlice = createSlice({
    name: "parser",
    initialState,
    reducers: {
        setParserDataStart: (state) => {
            Object.assign(state, initialState);
            state.loading = true;
        },
        setParsedData: (state, action) => {
            state.editableData = {
                ...state.editableData,
                ...action.payload,
            };
            state.loading = false;
        },

        validateParsedCandidate: (state, action) => {
            state.editableData.isValidated = !state.editableData.isValidated;
        },
        setEditableData: (state, action) => {
            const { data } = action.payload;

            state.editableData = {
                ...state.editableData,
                ...data,
            };
        },
        setSubmittedDataToUnflatten: (state, action) => {
            const { data } = action.payload;

            state.submittedDataToUnflatten = {
                ...state.editableData,
                ...data,
            };
        },

        resetParseData: (state, action) => {
            state.editableData = {
                isValidated: false,
            };
        },
    },
});

export const {
    setParserDataStart,
    setParsedData,
    setEditableData,
    setSubmittedDataToUnflatten,
    validateParsedCandidate,
    resetParseData,
} = parsedProfileDataSlice.actions;

export default parsedProfileDataSlice.reducer;
