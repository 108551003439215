class StorageService {
    AUTH_TOKEN = "token";
    SIDEBAR_OPEN_STATE = "sidebarOpenState";

    /**
     *
     * @param {string} token
     * @returns {void}
     */
    setAuthToken(token) {
        window.localStorage.setItem(this.AUTH_TOKEN, token);
    }
    /**
     *
     * @returns {string}
     */
    getAuthToken() {
        return window.localStorage.getItem(this.AUTH_TOKEN);
    }

    /**
     * @param {boolean} isOpen
     */
    setSidebarOpenState(isOpen) {
        localStorage.setItem(this.SIDEBAR_OPEN_STATE, JSON.stringify(isOpen));
    }

    /**
     *
     * @returns {boolean}
     */
    getSidebarOpenState() {
        const item = localStorage.getItem(this.SIDEBAR_OPEN_STATE);
        return item ? JSON.parse(item) : null;
    }

    /**
     * Clear all application storage on browser
     */
    clear() {
        // clear local storage
        window.localStorage.clear();
    }

    /**
     * Clear a specific item from storage
     */
    clearItem(key) {
        localStorage.removeItem(key);
    }

    /**
     * sets data to the localStorage
     * @param {*} key: string
     * @param {*} data
     */
    setLocalStorageItem(key, data) {
        window.localStorage.setItem(key, data);
    }
}

const storageServiceInstance = new StorageService();

export default storageServiceInstance;
