import { Box } from "@mui/system";

const diagonalStyles = {
    width: "100%",
    height: "100%",
    minHeight: "54px",
    opacity: 0.8,
    padding: 0,
    margin: 0,
    backgroundColor: "white",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='20' height='20' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(145)'%3E%3Crect width='100%25' height='100%25' fill='white'/%3E%3Cpath d='M-10 30h60v1h-60zM-10-10h60v1h-60' fill='lightgray'/%3E%3Cpath d='M-10 10h60v1h-60zM-10-30h60v1h-60z' fill='lightgray'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ")`,
};

const DiagonalPatternBox = ({ sx, ...props }) => (
    <Box sx={{ ...diagonalStyles, ...sx }} {...props} />
);

export default DiagonalPatternBox;
