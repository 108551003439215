import { createSlice } from "@reduxjs/toolkit";

const candidatesSlice = createSlice({
    name: "candidates",
    initialState: [],
    reducers: {
        candidatesList: (_state, action) => {
            return action.payload;
        },
    },
});

export const { candidatesList } = candidatesSlice.actions;
export default candidatesSlice.reducer;
