import { useParams } from "react-router-dom";
import ViewCompany from "features/companies/ViewCompany";

export const CompanyView = () => {
    const { id } = useParams();

    return <ViewCompany companyId={id} />;
};

export default CompanyView;
