import React from "react";
import { Box } from "@mui/material/";
import Typography from "@mui/material/Typography";

export const FormHorizontalSection = (props, sx) => {
    return (
        <Box
            component="fieldset"
            sx={{
                border: "unset",
                display: "flex",
                flexGrow: 1,
                px: 3,
                ...props.sx,
            }}
            gap={2}
        >
            <Box sx={{ width: "120px" }}>
                <Typography align="left" variant="p" component="p">
                    {props.title}
                </Typography>
            </Box>
            <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
                gap={2}
            >
                {props.children}
            </Box>
        </Box>
    );
};

export default FormHorizontalSection;
