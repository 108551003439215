import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Chip, Stack } from "@mui/material/";
import usePDF from "hooks/usePDF";
import IconBtn from "../../../../components/lib-ui/IconBtn";
import AddIcon from "@mui/icons-material/Add";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import BlankState from "../BlankState";
import AddCvForm from "features/candidate/candidate-form/AddCvForm";
import DialogBox from "components/lib-ui/DialogBox";
import { useSelector } from "react-redux";

export const Cv = () => {
    const candidate = useSelector((state) => state?.currentCandidate || {});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { hasCv, cvUrl } = candidate?.cv || {};
    const [renderKey, setRenderKey] = useState(0);
    const { getPDFMetadata, parsePDFDateTimeDistance, metadata, PDFBlobUrl } =
        usePDF();

    const updateDate = parsePDFDateTimeDistance(metadata?.ModDate);

    const handleButtonClick = (event, action) => {
        if (action === "Add") {
            console.log("add");
        }
    };

    useEffect(() => {
        getPDFMetadata(cvUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cvUrl, renderKey]);

    return (
        <Box
            sx={{
                maxWidth: "1200px",
            }}
        >
            {hasCv ? (
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "auto 40px",
                        gap: "20px",
                        alignItems: "flex-start",
                        justifyItems: "center",
                    }}
                >
                    <Box
                        key={renderKey}
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            alignItems: "flex-start",
                        }}
                    >
                        {updateDate && (
                            <Chip
                                sx={{
                                    padding: "5px 10px",
                                    height: "auto",
                                    "& .MuiChip-label": {
                                        maxWidth: "unset",
                                    },
                                }}
                                icon={<InfoIcon fontSize="small" />}
                                label={`Medadata found, cv last updated ${updateDate}`}
                            />
                        )}

                        <object
                            data={PDFBlobUrl}
                            type="application/pdf"
                            width="100%"
                            height="600"
                            style={{
                                border: "none",
                                backgroundColor: "#C3C3C3",
                            }}
                        >
                            {/* <p>
                                It appears your browser does not support PDFs.
                                Please use the download link to view the PDF.{" "}
                                <a href={cvUrl}>Download PDF</a>
                            </p> */}
                        </object>
                    </Box>

                    <Stack spacing={2}>
                        <IconBtn
                            // disabled
                            sx={{ backgroundColor: "white" }}
                            iconStyles={{ color: "primary" }}
                            icon={AddIcon}
                            onClick={() => {
                                setIsDialogOpen(true);
                            }}
                        />
                        <IconBtn
                            disabled
                            sx={{ backgroundColor: "white" }}
                            iconStyles={{ color: "primary" }}
                            icon={ShareOutlinedIcon}
                            onClick={(event) =>
                                handleButtonClick(event, "Share")
                            }
                        />
                        <IconBtn
                            disabled
                            sx={{ backgroundColor: "white" }}
                            iconStyles={{ color: "primary" }}
                            icon={DeleteOutlineOutlinedIcon}
                            onClick={(event) =>
                                handleButtonClick(event, "Delete")
                            }
                        />
                    </Stack>
                </Box>
            ) : (
                <BlankState
                    text="No CV uploaded"
                    buttonText="Add CV"
                    showButton
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                />
            )}

            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title="Upload cv"
                floatCancel={true}
                showActionButtons={false}
            >
                <AddCvForm
                    setIsDialogOpen={setIsDialogOpen}
                    setRenderKey={setRenderKey}
                />
            </DialogBox>
        </Box>
    );
};

export default Cv;
