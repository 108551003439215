import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Stack, Chip, IconButton, Collapse } from "@mui/material";
import {
    ExtensionOutlined,
    StarBorderOutlined,
    CheckOutlined,
    LightbulbOutlined,
    KeyboardArrowUpOutlined,
    KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import IconContent from "components/lib-ui/IconContent";
import ShowMore from "./ShowMore";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

export const SectorExperienceTree = ({ data }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const theme = useTheme();
    const lineColor = theme.palette.gray.dark;
    const uncategorized = data.uncategorized;

    const {
        jobFunctionName,
        topOccupations,
        yearsOfExperience,
        seniority,
        topResponsibilities,
        topSkills,
    } = data || {};

    const styles = {
        parent: {
            position: "relative",
            paddingLeft: "8px",
        },
        iconStyles: {
            border: `2px solid ${lineColor}`,
            borderRadius: "100%",
            fontSize: "1.1rem",
        },
    };
    const hasTopResponsibilites = Boolean(topResponsibilities?.length);
    const hasTopSkills = Boolean(topSkills?.length);
    const hasTopOccupations = Boolean(topOccupations?.length);
    const hasUncategorisedTopOccupations = Boolean(
        data?.uncategorized?.topOccupations?.length
    );
    const hasUncategorizedSkills = Boolean(
        data?.uncategorized?.topSkills?.length
    );

    const handleCollapse = () => {
        setIsCollapsed((collapse) => !collapse);
    };

    if (!uncategorized?.yearsOfExperience && !jobFunctionName) return "";

    const categorisedTopOccupations = data?.topOccupations;
    const uncategorisedTopOccupations = data?.uncategorized?.topOccupations;
    const categorisedSkills = data?.topSkills;
    const uncategorisedSkills = data?.uncategorized?.topSkills;

    return (
        <Box component="ul">
            <Stack
                onClick={handleCollapse}
                sx={{ cursor: "pointer" }}
                direction="row"
                justifyContent="space-between"
            >
                <IconContent
                    tooltipText={"Job Function"}
                    icon={ExtensionOutlined}
                >
                    <Chip
                        size="large"
                        color={"primary"}
                        label={
                            uncategorized ? "Uncategorised" : jobFunctionName
                        }
                        sx={{
                            padding: "15px 10px",
                            background: uncategorized
                                ? theme.palette.gray.dark
                                : "inherited",
                        }}
                    />
                </IconContent>
                <IconButton>
                    {isCollapsed ? (
                        <KeyboardArrowUpOutlined />
                    ) : (
                        <KeyboardArrowDownOutlined />
                    )}
                </IconButton>
            </Stack>

            {hasTopOccupations && (
                <Stack
                    direction="row"
                    sx={{
                        ...styles.parent,
                        paddingTop: "20px",
                    }}
                >
                    <Box
                        sx={{
                            ...styles.TShape,
                            borderColor: !hasTopOccupations && "white",
                        }}
                    />
                    <IconContent
                        icon={SubdirectoryArrowRightIcon}
                        sx={{ pr: 2 }}
                    />
                    <IconContent
                        tooltipText={"Occupations"}
                        iconStyles={styles.iconStyles}
                        icon={CheckOutlined}
                    >
                        <ShowMore
                            items={categorisedTopOccupations}
                            initialCount={5}
                            containerProps={{
                                display: "flex",
                                gap: "10px",
                                flexWrap: "wrap",
                            }}
                            render={(skill) => (
                                <Chip key={skill.id} label={skill.name} />
                            )}
                        />
                    </IconContent>
                </Stack>
            )}
            {hasUncategorisedTopOccupations && (
                <Stack
                    direction="row"
                    sx={{
                        ...styles.parent,
                        paddingTop: "20px",
                    }}
                >
                    <Box
                        sx={{
                            ...styles.TShape,
                            borderColor:
                                !hasUncategorisedTopOccupations && "white",
                        }}
                    />
                    <IconContent
                        icon={SubdirectoryArrowRightIcon}
                        sx={{ pr: 2 }}
                    />
                    <IconContent
                        tooltipText={"Occupations"}
                        iconStyles={styles.iconStyles}
                        icon={CheckOutlined}
                    >
                        <ShowMore
                            items={uncategorisedTopOccupations}
                            initialCount={5}
                            containerProps={{
                                display: "flex",
                                gap: "10px",
                                flexWrap: "wrap",
                            }}
                            render={(skill) => (
                                <Chip key={skill.id} label={skill.name} />
                            )}
                        />
                    </IconContent>
                </Stack>
            )}

            <Collapse in={isCollapsed}>
                <Stack pt={2.5} gap="20px">
                    <Stack
                        direction="row"
                        sx={{ ...styles.parent, background: "in" }}
                    >
                        <Box sx={styles.TShape} />
                        <IconContent
                            icon={SubdirectoryArrowRightIcon}
                            sx={{ pr: 2 }}
                        />
                        <IconContent
                            tooltipText={"Years of experience"}
                            text={
                                uncategorized
                                    ? data.uncategorized?.yearsOfExperience
                                    : yearsOfExperience
                            }
                            iconStyles={styles.iconStyles}
                            icon={StarBorderOutlined}
                        />
                    </Stack>

                    <>
                        {hasTopResponsibilites || hasTopSkills ? (
                            <Stack direction="row" sx={{ ...styles.parent }}>
                                <Box
                                    sx={{
                                        ...styles.TShape,
                                        borderColor:
                                            !hasTopResponsibilites &&
                                            !hasTopSkills &&
                                            "white",
                                    }}
                                />

                                <IconContent
                                    icon={SubdirectoryArrowRightIcon}
                                    sx={{ pr: 2 }}
                                />
                                <IconContent
                                    tooltipText={"Seniority"}
                                    text={seniority}
                                    iconStyles={styles.iconStyles}
                                    icon={LightbulbOutlined}
                                />
                            </Stack>
                        ) : null}

                        {hasTopSkills && (
                            <Stack
                                direction="row"
                                sx={{
                                    ...styles.parent,
                                }}
                            >
                                <Box
                                    sx={{
                                        ...styles.TShape,
                                        borderColor:
                                            !hasTopResponsibilites && "white",
                                    }}
                                />
                                <IconContent
                                    icon={SubdirectoryArrowRightIcon}
                                    sx={{ pr: 2 }}
                                />
                                <IconContent
                                    tooltipText={"Top hard skills"}
                                    iconStyles={styles.iconStyles}
                                    icon={CheckOutlined}
                                >
                                    <ShowMore
                                        items={categorisedSkills}
                                        initialCount={5}
                                        containerProps={{
                                            display: "flex",
                                            gap: "10px",
                                            flexWrap: "wrap",
                                        }}
                                        render={(skill) => (
                                            <Chip
                                                key={skill.id}
                                                label={skill.name}
                                            />
                                        )}
                                    />
                                </IconContent>
                            </Stack>
                        )}
                        {hasUncategorizedSkills && (
                            <Stack
                                direction="row"
                                sx={{
                                    ...styles.parent,
                                }}
                            >
                                <Box
                                    sx={{
                                        ...styles.TShape,
                                        borderColor: lineColor,
                                    }}
                                />
                                <IconContent
                                    icon={SubdirectoryArrowRightIcon}
                                    sx={{ pr: 2 }}
                                />
                                <IconContent
                                    tooltipText={"Uncategorised hard skills"}
                                    iconStyles={styles.iconStyles}
                                    icon={CheckOutlined}
                                >
                                    <ShowMore
                                        items={uncategorisedSkills}
                                        initialCount={5}
                                        containerProps={{
                                            display: "flex",
                                            gap: "10px",
                                            flexWrap: "wrap",
                                        }}
                                        render={(skill) => (
                                            <Chip
                                                key={skill.id}
                                                label={skill.name}
                                            />
                                        )}
                                    />
                                </IconContent>
                            </Stack>
                        )}
                    </>
                </Stack>
            </Collapse>
        </Box>
    );
};

export default SectorExperienceTree;
