import { Box, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import GeneralUtilityService from "services/GeneralUtilityService";

export const CompanyTopSection = ({ sx: parentStyles }) => {
    const companyData = useSelector((state) => state.companySlice);
    const backgroundColor = GeneralUtilityService.stringToColor(companyData?.companyName);
    return (
        <Box
            sx={{
                ...parentStyles,
            }}
        >
            <Box
                gap={2}
                sx={{
                    alignItems: "center",
                    height: "100px",
                    maxWidth: "1000px",
                    display: "flex",
                    position: "relative",
                }}
            >
                <Avatar
                    alt="company picture"
                    variant="rounded"
                    src={null}
                    sx={{
                        width: 75,
                        height: 75,
                        backgroundColor: backgroundColor,
                    }}
                >
                    {GeneralUtilityService.getStringInitals(
                        companyData?.companyName
                    )}
                </Avatar>
                <Typography variant="h4">{companyData?.companyName}</Typography>
            </Box>
        </Box>
    );
};

export default CompanyTopSection;
