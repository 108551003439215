import { SvgIcon } from "@mui/material";

export const LinkedinFilled = (props) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="100%"
                viewBox="0 0 10 10"
                preserveAspectRatio="xMidYMid meet"
            >
                <path d="M9.25977 0H0.738281C0.330078 0 0 0.322266 0 0.720703V9.27734C0 9.67578 0.330078 10 0.738281 10H9.25977C9.66797 10 10 9.67578 10 9.2793V0.720703C10 0.322266 9.66797 0 9.25977 0ZM2.9668 8.52148H1.48242V3.74805H2.9668V8.52148ZM2.22461 3.09766C1.74805 3.09766 1.36328 2.71289 1.36328 2.23828C1.36328 1.76367 1.74805 1.37891 2.22461 1.37891C2.69922 1.37891 3.08398 1.76367 3.08398 2.23828C3.08398 2.71094 2.69922 3.09766 2.22461 3.09766ZM8.52148 8.52148H7.03906V6.20117C7.03906 5.64844 7.0293 4.93555 6.26758 4.93555C5.49609 4.93555 5.37891 5.53906 5.37891 6.16211V8.52148H3.89844V3.74805H5.32031V4.40039H5.33984C5.53711 4.02539 6.02148 3.62891 6.74219 3.62891C8.24414 3.62891 8.52148 4.61719 8.52148 5.90234V8.52148V8.52148Z" />
            </svg>
        </SvgIcon>
    );
};

export default LinkedinFilled;
