import React, { useState, useEffect } from "react";
import axios from "utils/axiosConfig";
import { DataGrid } from "@mui/x-data-grid";
import { Stack, IconButton, Typography, Select, MenuItem } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useSelector } from "react-redux";
import { urlService } from "infra/envs";
import companiesColumnHeaders from "features/companies/companiesColumnHeaders";
import useNotifications from "hooks/useNotifications";
import TablePagination from "../candidate/candidate-listing/TablePagination";

export const CompaniesTable = ({ setFilterVisibility }) => {
    const RESULT_LENGTH_OPTIONS = [10, 25, 50, 75, 100];

    const [resultLength, setResultLength] = useState(RESULT_LENGTH_OPTIONS[1]);
    const [page, setPage] = useState(1);
    const [paginationData, setPaginationData] = useState({});
    const companiesFiltersData = useSelector(
        (state) => state.companiesSearchFilters
    );

    const getCompaniesUrl = urlService.COMPANIES_LISTING_PATH;

    const [isLoading, setIsLoading] = useState(false);
    const [companiesTableRows, setCompaniesTableRows] = useState([]);

    const { renderErrorNotification } = useNotifications();

    async function getCompanies(endpoint, filterData) {
        try {
            setIsLoading(true);
            const response = await axios.get(endpoint, {
                params: {
                    ...filterData,
                    subscription: companiesFiltersData?.subscription?.id,
                    page_size: resultLength,
                    page: page,
                },
            });

            const resData = response?.data;
            setPaginationData(resData?.meta);

            const fetchedCompanies = resData?.data;

            setCompaniesTableRows(fetchedCompanies);

            setIsLoading(false);
        } catch (error) {
            console.error("OOPS!" + error.message);
            renderErrorNotification(`${error?.response?.data?.message}`);
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        setPage(1);
    }, [companiesFiltersData, resultLength]);

    useEffect(() => {
        getCompanies(getCompaniesUrl, companiesFiltersData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultLength, page, companiesFiltersData]);

    const handleResultLengthChange = (event) => {
        setResultLength(event.target.value);
    };

    //cleanup
    useEffect(() => {
        return setIsLoading(false);
    }, []);

    const handleCellClick = (params) => {
        window.open(
            `${urlService.COMPANY_PROFILE_PATH}/${params.id}`,
            "_blank"
        );
    };

    return (
        <>
            <Stack px={2}>
                <Stack alignItems="flex-start">
                    <IconButton
                        aria-label="Collapse or expand list"
                        onClick={() => {
                            setFilterVisibility((visibility) => !visibility);
                        }}
                    >
                        <FilterListIcon />
                    </IconButton>
                </Stack>
                <Stack
                    pb={3}
                    direction="row"
                    alignItems="center"
                    gap={2}
                    justifyContent={"flex-end"}
                >
                    <Typography>Rows per page</Typography>
                    <Select
                        name="resultLength"
                        value={resultLength}
                        onChange={handleResultLengthChange}
                        label="results per page"
                        variant="standard"
                    >
                        {RESULT_LENGTH_OPTIONS.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>

                <DataGrid
                    onRowClick={(rowData) => handleCellClick(rowData)}
                    component="table"
                    rowSpacingType="margin"
                    autoHeight
                    rows={companiesTableRows}
                    columns={companiesColumnHeaders}
                    disableColumnMenu
                    hideFooter
                    loading={isLoading}
                    sx={{
                        border: 0,
                        "& .MuiDataGrid-cell:focus,& .MuiDataGrid-columnHeader:focus":
                            {
                                outline: "none",
                                borderColor: "rgba(0,0,0,0)",
                            },
                        "& .MuiDataGrid-row:focus-within": {
                            boxShadow: "none",
                        },
                    }}
                />
                <TablePagination
                    isLoading={isLoading}
                    setPage={setPage}
                    page={page}
                    paginationData={paginationData}
                />
            </Stack>
        </>
    );
};

export default CompaniesTable;
