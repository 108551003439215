import axios from "utils/axiosConfig";
import { useState } from "react";
import { pdfjs } from "react-pdf";
import { utcToZonedTime } from "date-fns-tz";
import { addMinutes, formatDistanceToNow } from "date-fns";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Parses a PDF date string into a JavaScript Date object.
 * The input should be in the format "D:YYYYMMDDHHMMSS±HH'MM'".
 * @param {string} pdfDate - The PDF date string.
 * @returns {Date | undefined} The parsed date as a JavaScript Date object or undefined if pdfDate is not provided
 */
export const parsePDFDate = (pdfDate) => {
    try {
        if (!pdfDate) return;
    // Remove the 'D:' prefix if it's there
        const dateString = pdfDate.startsWith("D:")
            ? pdfDate.substring(2)
            : pdfDate;

        // Extract year, month, day, hours, minutes, and seconds
        const year = parseInt(dateString.substring(0, 4), 10);
        const month = parseInt(dateString.substring(4, 6), 10) - 1; // Months are 0-indexed in JavaScript
        const day = parseInt(dateString.substring(6, 8), 10);
        const hours = parseInt(dateString.substring(8, 10), 10);
        const minutes = parseInt(dateString.substring(10, 12), 10);
        const seconds = parseInt(dateString.substring(12, 14), 10);

        // If the date string ends with 'Z' or lacks a timezone offset, it's assumed to be in UTC
        if (dateString.charAt(14) === "Z" || dateString.length === 14) {
            return new Date(
                Date.UTC(year, month, day, hours, minutes, seconds)
            );
        }

        // Determine the sign of the timezone offset
        const sign = dateString.charAt(14) === "+" ? 1 : -1;
        // Extract the offset hours and minutes, taking the sign into account
        const offsetHours = parseInt(dateString.substring(15, 17), 10) * sign;
        const offsetMinutes = parseInt(dateString.substring(18, 20), 10) * sign;

        // Construct the date object with the UTC date and time
        const date = new Date(
            Date.UTC(year, month, day, hours, minutes, seconds)
        );
        // Calculate the total timezone offset in minutes
        const timezoneOffsetMinutes = offsetHours * 60 + offsetMinutes;
        // Convert the date to the UTC timezone
        const zonedDate = utcToZonedTime(date, "Etc/UTC");
        // Apply the timezone offset and return the final date
        return addMinutes(zonedDate, -timezoneOffsetMinutes);
    } catch (error) {
        console.error("Error parsing PDF date:", error);
        // You can return a default value or handle the error in another way if needed
        return null;
    }
};

/**
 * Parses a PDF date string and returns the time distance as a string relative to now.
 * The input should be in the format "D:YYYYMMDDHHMMSS±HH'MM'".
 * Returns the time distance as a human-readable string, such as "2 days ago" or "in 3 months."
 * @param {string} pdfDate - The PDF date string.
 * @returns {string | undefined} The time distance as a string, or undefined if pdfDate is not provided.
 */
export const parsePDFDateTimeDistance = (pdfDate) => {
    if (!pdfDate) return;
    const date = parsePDFDate(pdfDate);
    return formatDistanceToNow(date, { addSuffix: true });
};

export const usePDF = () => {
    const [metadata, setMetadata] = useState(null);
    const [getPDFMetadataLoading, setGetPDFMetadataLoading] = useState(false);
    const [getPDFloading, setGetPDFloading] = useState(false);
    const [PDFBlobUrl, setPDFBlobUrl] = useState(null);

    /**
     * Fetches a PDF from a given URL and extracts its metadata.
     *
     * @param {string} pdfUrl - The URL to fetch the PDF from.
     * @param {Object} [headers] - Optional custom headers to include in the request (e.g., for authentication).
     * @returns {Promise<Object | undefined>} The metadata of the PDF, or undefined if an error occurs.
     */
    const getPDFMetadata = async (pdfUrl, headers = {}) => {
        if (!pdfUrl) return;

        setGetPDFMetadataLoading(true);
        try {
            const data = await getPDF(pdfUrl, headers);

            if (!data) {
                console.error("Error fetching the PDF");
                return undefined;
            }

            const pdfDocument = await pdfjs.getDocument({ data }).promise;
            const meta = await pdfDocument.getMetadata();

            setMetadata(meta.info);
            return meta.info;
        } catch (error) {
            console.error("Error fetching or parsing the PDF:", error);
            return undefined;
        } finally {
            setGetPDFMetadataLoading(false);
        }
    };

    /**
     * Fetches a PDF from a given URL and stores its Blob URL in the state.
     * @param {string} pdfUrl - The URL of the PDF to fetch.
     * @param {Object} [headers={}] - Optional HTTP headers for the request.
     * @returns {ArrayBuffer | undefined} The raw PDF data if the fetch is successful, otherwise undefined.
     */
    const getPDF = async (pdfUrl, headers = {}) => {
        setGetPDFloading(true);
        try {
            const response = await axios.get(pdfUrl, {
                responseType: "arraybuffer",
                headers: headers,
            });

            // Ensure that the response contains data before proceeding
            if (response.data) {
                const dataBlob = URL.createObjectURL(
                    new Blob([response.data], { type: "application/pdf" })
                );

                setPDFBlobUrl(dataBlob);
                return response.data;
            } else {
                console.warn("The PDF response is empty.");
                return undefined;
            }
        } catch (error) {
            console.error("Error fetching the PDF:", error);
            return undefined;
        } finally {
            setGetPDFloading(false);
        }
    };

    return {
        metadata,
        getPDFMetadataLoading,
        getPDFloading,
        PDFBlobUrl,
        parsePDFDate,
        getPDFMetadata,
        parsePDFDateTimeDistance,
    };
};

export default usePDF;
