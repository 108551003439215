import React, { useState, useRef } from "react";
import {
    Box,
    TextField,
    InputAdornment,
    Autocomplete,
    IconButton,
} from "@mui/material/";
import { GlobalAppDataStorageService } from "infra/storage";
import { useForm, Controller } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import FormHorizontalSection from "./FormHorizontalSection";
import FormActions from "./FormActions";
import SlideForm from "./SlideForm";
import useFetchCities from "hooks/useFetchCities";
import { useSelector, useDispatch } from "react-redux";
import { resetCitiesOptions } from "reducers/candidate/citiesSlice";
import GeneralUtilityService from "services/GeneralUtilityService";

export const StepTwo = (props) => {
    const countryOptions = GlobalAppDataStorageService.getCountries();
    const cityOptions = useSelector((state) => state.citiesSlice);

    const { fetchCities, citiesFetching } = useFetchCities();

    const dispatch = useDispatch();
    const { stepState } = props;
    const [cv, setCv] = useState("");
    const cvRef = useRef();
    const {
        control,
        register,
        setError,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm({
        defaultValues: {
            ...stepState.stepTwoData,
        },
    });
    const selectedCountry = watch("country");
    const hasCountry = GeneralUtilityService.isObjectEmpty(selectedCountry);

    /**
     * Validates the selected CV file to ensure that it meets the necessary requirements.
     *
     * TODO: Refactor and move this function to a reusable utilities directory. Also, error messages
     * should be stored in a centralized location, such as errors.json
     *
     * @param {File} cv - The selected CV file to validate.
     * @param {function} setError - The setError function from react-hook-form used to set the validation error.
     * @returns {void}
     */
    const validateCV = (cv) => {
        if (!cv) return;

        // Check if the file is a PDF
        if (cv.type !== "application/pdf") {
            setError("cv", {
                type: "manual",
                message: "CV should be in PDF format",
            });
            return;
        }

        // Check if the file size is less than or equal to 3 megabytes
        if (cv.size > 3 * 1024 * 1024) {
            setError("cv", {
                type: "manual",
                message: "CV size should be less than or equal to 3 MB",
            });
            return;
        }
    };

    const debouncedFetchCitiesOptions = GeneralUtilityService.debounce(
        async (country, q) => {
            await fetchCities(country, q);
        },
        1000
    );

    const handleInputChange = (event, value, reason) => {
        if (reason === "input") {
            const selectedCountryId = selectedCountry?.id;
            debouncedFetchCitiesOptions(selectedCountryId, value);
        }
    };

    const onSubmit = (formData, event) => {
        validateCV(cv);
        formData.step = 2;
        formData.cv = cv;
        stepState.formSubmit(formData, event);
    };

    return (
        <SlideForm stepState={{ ...stepState }}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <FormHorizontalSection
                    sx={{ my: 4 }}
                    title="Personal Information"
                >
                    {/* Upload CV */}
                    <Box sx={{ display: "flex", maxWidth: "600px" }} gap={2}>
                        <TextField
                            name="cv"
                            fullWidth
                            defaultValue={cv}
                            InputLabelProps={{ shrink: true }}
                            {...register("cv")}
                            error={Boolean(errors.cv)}
                            helperText={errors.cv ? errors.cv.message : ""}
                            InputProps={{
                                accept: "application/pdf",
                                inputMode: "file",
                                endAdornment: cv && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Clear"
                                            onClick={() => {
                                                setCv(null);
                                                cvRef.current.value = "";
                                            }}
                                        >
                                            <ClearIcon
                                                color="grey"
                                                fontSize="14px"
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            type="file"
                            label="Upload CV"
                            inputRef={cvRef}
                            onChange={(event) => setCv(event.target.files[0])}
                        />
                    </Box>

                    {/* Country - City */}
                    <Box sx={{ display: "flex", maxWidth: "600px" }} gap={2}>
                        <Controller
                            name="country"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                                <Autocomplete
                                    id="country"
                                    fullWidth
                                    autoComplete
                                    limitTags={1}
                                    filterSelectedOptions
                                    options={countryOptions || []}
                                    getOptionLabel={(option) =>
                                        option && option.hasOwnProperty("name")
                                            ? option.name
                                            : ""
                                    }
                                    value={field.value || undefined}
                                    isOptionEqualToValue={(option, value) =>
                                        option.name === value.name
                                    }
                                    {...field}
                                    onChange={(event, newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="city"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                                <Autocomplete
                                    id="city"
                                    fullWidth
                                    autoComplete
                                    options={cityOptions}
                                    loading={citiesFetching}
                                    disabled={hasCountry}
                                    onClose={() =>
                                        dispatch(resetCitiesOptions())
                                    }
                                    onInputChange={handleInputChange}
                                    getOptionLabel={(option) =>
                                        option && option.hasOwnProperty("name")
                                            ? option.name
                                            : ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.name === value.name
                                    }
                                    {...field}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue);
                                        dispatch(resetCitiesOptions());
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="City"
                                            placeholder="Start typing..."
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                </FormHorizontalSection>
                <FormActions stepState={{ ...stepState }} />
            </Box>
        </SlideForm>
    );
};

export default StepTwo;
