import { createTheme } from "@mui/material/styles";

export const MainTheme = createTheme({
    typography: {
        header1: {
            fontSize: "1.5rem",
            fontWeight: 600,
        },
        header2: {
            fontSize: "1.1rem",
            fontWeight: 500,
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 400,
        },
        subtitle2: {
            fontSize: ".9rem",
            fontWeight: 400,
            color: "gray",
        },
        paragraph2: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            fontWeight: 400,
        },
        rowTitle: {
            fontSize: "1rem",
            // lineHeight: "1.5rem",
            fontWeight: 400,
        },
        helperText: {
            fontSize: "0.8rem",
            fontWeight: 300,
        },
    },
    palette: {
        primary: {
            main: "#00314A",
            contrastText: "#fff",
        },
        navy: {
            main: "#00314A",
            contrastText: "#fff",
        },
        cyan: {
            main: "#0297CB",
            contrastText: "#fff",
        },
        greenCyan: {
            main: "#55E59E",
            contrastText: "#000",
        },
        gray: {
            main: "#606060",
            dark: "#606060",
            medium: "#C3C3C3",
            soft: "#dcdcdc",
            light: "#F8F8F8",
            contrastText: "#606060",
        },
        skillChipColor: {
            main: "#8c8c8c",
            contrastText: "#fff",
        },
        cool: {
            main: "#F8F8F8",
            contrastText: "#202020",
        },
        focus: {
            main: "#0297CB",
            contrastText: "#fff",
        },
        danger: {
            main: "#ff7961",
            contrastText: "#202020",
        },
        violet: {
            main: "#BEADFA",
            contrastText: "#fff",
        },
        orange: {
            main: "#F3AA60",
            contrastText: "#fff",
        },
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: "20px",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    height: "20px",
                    maxHeight: "20px",
                    minHeight: "unset",
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    height: "20px",
                },
                option: {
                    '&[aria-selected="true"].MuiAutocomplete-option': {
                        backgroundColor: "#DCDCDC",
                    },
                    "&.MuiAutocomplete-option:hover": {
                        backgroundColor: "#DCDCDC",
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: "1.1rem",
                },
                deleteIcon: {
                    height: "0.8rem",
                },
                label: {
                    fontSize: "0.8rem",
                    maxWidth: "180px",
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    // height: "15px",
                    "&.Mui-selected": {
                        backgroundColor: "#DCDCDC",
                    },
                    "&:hover": {
                        backgroundColor: "#DCDCDC",
                    },
                },
            },
        },
    },
});

export default MainTheme;
