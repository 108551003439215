import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Stack, Select, MenuItem, IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import TablePagination from "features/candidate/candidate-listing/TablePagination";
import { useSelector, useDispatch } from "react-redux";
import linqTableColumnHeaders from "features/linq-requests/linqTableComumnHeaders";
import {
    setIsDialogOpen,
    setResultLength,
    selectedLinqApplication,
} from "reducers/linq-requests/linqRequestsSlice";
import { useLinqRequests } from "hooks/useLinqRequests";
import LinqApplicationDialog from "./LinqApplicationDialog";

export const LinqTable = ({ setFilterVisibility }) => {
    const dispatch = useDispatch();

    const { linqRequests, filters, resultLength, resultLengthOptions } =
        useSelector((state) => state.linqRequestsSlice);

    const [page, setPage] = useState(1);
    const { isLinqRequestsFetchLoading, getLinqRequests } = useLinqRequests();

    const handleResultLengthChange = (event) => {
        dispatch(
            setResultLength(event?.target?.value || resultLengthOptions[0])
        );
    };

    const handleOpenDialog = (shouldOpen) => {
        dispatch(setIsDialogOpen(shouldOpen));
    };

    const handleCellClick = (event) => {
        if (event.field === "edit") {
            handleOpenDialog(true);

            const applicationData = {
                id: event.row.id,
                candidate_id: event.row.candidate_id,
                candidate_name: event.row.candidate_name,
                candidate_phone: event.row.candidate_phone,
                candidate_email: event.row.candidate_email,
                company: event.row.company,
                company_id: event.row.company_id,
                employer_email: event.row.employer_email,
                status: event.row.status,
                reason: event.row.reason,
            };

            dispatch(selectedLinqApplication(applicationData));
        }
    };

    const columns = linqTableColumnHeaders();

    // To update on both filter and pagination page change
    useEffect(() => {
        getLinqRequests({
            ...filters,
            page,
            page_size: resultLength,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filters, resultLength]);

    useEffect(() => {
        setPage(1);
    }, [filters]);

    let tableData = [];

    if (linqRequests?.data) {
        tableData = linqRequests?.data.map((value) => {
            return {
                id: value.linq_request_id,
                candidate_id: value.candidate_id,
                candidate_name: value.first_name + " " + value.last_name,
                candidate_phone: value.phone,
                candidate_email: value.email,
                company: value.company_name,
                company_id: value.company_id,
                employer_email: value.emp_email,
                status: value.cur_status,
                reason: value?.reason,
            };
        });
    }

    return (
        <>
            <Stack px={2}>
                <Stack alignItems="flex-start">
                    <IconButton
                        aria-label="Collapse or expand list"
                        onClick={() => {
                            setFilterVisibility((visibility) => !visibility);
                        }}
                    >
                        <FilterListIcon />
                    </IconButton>
                </Stack>
                <Stack
                    pb={3}
                    direction="row"
                    alignItems="center"
                    gap={2}
                    justifyContent={"flex-end"}
                >
                    <Typography>Rows per page</Typography>
                    <Select
                        name="page_size"
                        value={resultLength}
                        onChange={handleResultLengthChange}
                        label="results per page"
                        variant="standard"
                    >
                        {resultLengthOptions.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
                <DataGrid
                    onCellClick={handleCellClick}
                    component="table"
                    rowSpacingType="border"
                    autoHeight
                    rows={tableData}
                    columns={columns}
                    pageSize={resultLength}
                    disableColumnMenu
                    hideFooter
                    loading={isLinqRequestsFetchLoading}
                    sx={{
                        border: 0,
                        "& .MuiDataGrid-cell:focus,& .MuiDataGrid-columnHeader:focus":
                            {
                                outline: "none",
                                borderColor: "rgba(0,0,0,0)",
                            },
                        "& .MuiDataGrid-row:focus-within": {
                            boxShadow: "none",
                        },
                    }}
                />
                <TablePagination
                    isLoading={isLinqRequestsFetchLoading}
                    setPage={setPage}
                    page={page}
                    paginationData={linqRequests}
                />

                <LinqApplicationDialog />
            </Stack>
        </>
    );
};

export default LinqTable;
