import { useState } from "react";
import PageTitle from "components/layout/PageTitle";
import { Paper, Stack, Collapse } from "@mui/material";
import LinqFilters from "./LinqFilters";
import { LinqTable } from "./LinqTable";

export const LinqRequests = () => {
    const [filterVisibility, setFilterVisibility] = useState(true);


    return (
        <Stack direction="column">
            <PageTitle title="Search for Linq Requests" />
            <Stack
                px="20px"
                pb="20px"
                spacing={filterVisibility && 2}
                direction="row"
            >
                <Collapse orientation="horizontal" in={filterVisibility}
                    sx={{
                        width: "20%"
                    }}
                >
                    <Paper
                        sx={{
                            width: "100%",
                            borderRadius: "10px",
                            height: "fit-content",
                        }}
                    >
                        <LinqFilters />
                    </Paper>
                </Collapse>
                <Paper
                    sx={{
                        padding: "20px 0",
                        width: filterVisibility ? "80%" : "100%",
                        borderRadius: "10px ",
                        height: "fit-content",
                    }}
                >
                    <LinqTable setFilterVisibility={setFilterVisibility} />
                </Paper>
            </Stack>
        </Stack >
    );
};

export default LinqRequests;
