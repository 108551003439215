import { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Stack,
    Typography,
    Chip,
    Tooltip,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import IconBtn from "components/lib-ui/IconBtn";
import DialogBox from "components/lib-ui/DialogBox";
import SubscriptionForm from "./SubscriptionForm";
import GeneralUtilityService from "services/GeneralUtilityService";
import StripeIcon from "assets/icons/StripeIcon";
import MainTheme from "assets/theme/MainTheme";
import { Check, Close } from "@mui/icons-material";

export default function SubscriptionsTable({ rows }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const subscriptionRows = rows || [];

    const hasActiveSubscription =
        subscriptionRows?.filter(
            (subscription) => subscription.currentStatus === true
        ).length > 0;

    const handleOpenDialog = () => {
        setSubscriptionDetails(null);
        setIsDialogOpen(true);
    };

    const handleOpenDialogWithDetails = (row) => {
        setSubscriptionDetails({
            startAt: row?.startAt,
            endAt: row?.endAt,
            planId: row?.plan.id,
            subscriptionId: row?.id,
            id: row?.id,
        });

        setIsDialogOpen(true);
    };

    const renderChipStatus = (status) => {
        const isActive = Boolean(status);
        const color = isActive ? "success" : null;
        const variant = isActive ? "filled" : "outlined";
        const label = isActive ? "Active" : "Inactive";
        const icon = isActive ? <Check /> : <Close />;

        return (
            <Chip
                sx={{ lineHeight: "normal" }}
                icon={icon}
                variant={variant}
                label={label}
                size="small"
                color={isActive ? color : undefined}
            />
        );
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Stack
                    p={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%", height: "75px" }}
                >
                    <Typography variant="h6" fontWeight="bold">
                        Subscription History
                    </Typography>
                    <Tooltip
                        title="Cannot add a new subscription while there is an active one."
                        disableHoverListener={!hasActiveSubscription}
                    >
                        <span>
                            <Button
                                sx={{
                                    borderRadius: "50px",
                                    padding: "10px 30px",
                                    textTransform: "none",
                                }}
                                disabled={hasActiveSubscription}
                                size="small"
                                color="primary"
                                startIcon={<Add />}
                                variant="contained"
                                onClick={handleOpenDialog}
                                aria-label="Add new subscription"
                            >
                                Add Subscription
                            </Button>
                        </span>
                    </Tooltip>
                </Stack>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: "3rem" }}>#</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Current Status</TableCell>
                            <TableCell>Plan</TableCell>
                            <TableCell>Source</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {subscriptionRows.map((row, index) => {
                            const isActive = Boolean(row?.isCurrent);
                            return (
                                <TableRow
                                    key={`${row.id}/${index}`}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        {GeneralUtilityService.formattedDate(
                                            row?.startAt
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {GeneralUtilityService.formattedDate(
                                            row?.endAt
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {renderChipStatus(row?.isCurrent)}
                                    </TableCell>
                                    <TableCell>{row?.plan?.name}</TableCell>
                                    <TableCell>
                                        {row?.stripeSubscription?.id && (
                                            <StripeIcon
                                                fill={
                                                    MainTheme.palette.grey[500]
                                                }
                                                fontSize="large"
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ position: "relative" }}>
                                        {isActive &&
                                            !row?.stripeSubscription?.id && (
                                                <IconBtn
                                                    sx={{
                                                        left: "50%",
                                                        top: "50%",
                                                        position: "absolute",
                                                        transform:
                                                            "translate(-50%, -50%)",
                                                    }}
                                                    icon={EditIcon}
                                                    onClick={() =>
                                                        handleOpenDialogWithDetails(
                                                            row
                                                        )
                                                    }
                                                />
                                            )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title={`${
                    Boolean(subscriptionDetails) ? "Edit" : "Create"
                } Subscription`}
                floatCancel
                showActionButtons={false}
            >
                <SubscriptionForm
                    subscriptionDetails={subscriptionDetails}
                    setIsDialogOpen={setIsDialogOpen}
                />
            </DialogBox>
        </>
    );
}
