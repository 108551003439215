import { useState } from "react";
import PageTitle from "components/layout/PageTitle";
import { Paper, Stack, Collapse } from "@mui/material";
import CompaniesFilters from "features/companies/CompaniesFilters";
import CompaniesTable from "features/companies/CompaniesTable";

export const CompaniesList = () => {
    const [filterVisibility, setFilterVisibility] = useState(true);
    return (
        <Stack direction="column">
            <PageTitle title="Search for companies" />
            <Stack
                px="20px"
                pb="20px"
                spacing={filterVisibility && 2}
                direction="row"
            >
                <Collapse orientation="horizontal" in={filterVisibility}>
                    <Paper
                        sx={{
                            width: "280px",
                            borderRadius: "10px",
                            height: "fit-content",
                        }}
                    >
                        <CompaniesFilters />
                    </Paper>
                </Collapse>
                <Paper
                    sx={{
                        padding: "20px 0",
                        width: "80%",
                        borderRadius: "10px ",
                        height: "fit-content",
                    }}
                >
                    <CompaniesTable setFilterVisibility={setFilterVisibility} />
                </Paper>
            </Stack>
        </Stack>
    );
};

export default CompaniesList;
