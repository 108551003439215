import { InputAdornment, TextField, Box, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const SearchBar = () => {
    const handleSubmit = (e, data) => {
        e.preventDefault();
    };

    return (
        <Stack direction="row" justifyContent="center" alignItems="center">
            <Box component="form" onSubmit={handleSubmit}>
                <TextField
                    inputProps={{
                        "aria-label": "Search: name, surname or email",
                        placeholder: "Search: name, surname or email",
                    }}
                    disabled={true}
                    name="search"
                    sx={{
                        backgroundColor: "gray.light",
                        height: "55px",
                        width: "700px",
                        padding: "0",
                        border: "none !important",
                        outline: "none",
                        borderRadius: "15px",
                        "& .MuiOutlinedInput-root": {
                            transition: "all 0.3s ease",
                            borderRadius: "15px",
                            "& fieldset": {
                                borderColor: "transparent",
                                borderWidth: 0,
                            },
                            "&:hover fieldset": {
                                borderColor: "transparent",
                                borderWidth: 0,
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "transparent",
                                borderWidth: 0,
                                boxShadow: (theme) => theme.shadows[4],
                            },
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Stack>
    );
};

export default SearchBar;
