import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import Summary from "./tabs/Summary";
import Cv from "./tabs/Cv";
import PersonalDetails from "./tabs/PersonalDetails";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import IconText from "components/lib-ui/IconContent";
import Experience from "./tabs/Experience";
import Education from "./tabs/Education";
import Notes from "./tabs/Notes";
import RecruitmentProcesses from "./tabs/RecruitmentProcesses";
import History from "./tabs/History";
import GeneralUtitilityService from "services/GeneralUtilityService";

export const Tabs = ({ sx: parentStyles }) => {
    const { hash } = useLocation();
    const navigate = useNavigate();
    const getTabFromUrl = hash.split("#")[1];

    const [activeTab, setActiveTab] = useState(getTabFromUrl || "Summary");

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        navigate(`#${newValue}`);
    };

    const candidateTabsContent = [
        {
            value: GeneralUtitilityService.replaceSpacesWithHyphens("Summary"),
            label: (
                <IconText
                    icon={SmartToyOutlinedIcon}
                    text="Summary"
                    typographyStyles={{ fontSize: "1rem", fontWeight: "500" }}
                />
            ),
            component: <Summary />,
        },
        {
            value: GeneralUtitilityService.replaceSpacesWithHyphens("Cv"),
            label: "CV",
            component: <Cv />,
        },
        {
            value: GeneralUtitilityService.replaceSpacesWithHyphens(
                "Personal Details & Preferences"
            ),
            label: "Personal Details & Preferences",
            component: <PersonalDetails />,
        },
        {
            value: GeneralUtitilityService.replaceSpacesWithHyphens(
                "Experience"
            ),
            label: "Experience",
            component: <Experience />,
        },
        {
            value: GeneralUtitilityService.replaceSpacesWithHyphens(
                "Education"
            ),
            label: "Education",
            component: <Education />,
        },
        {
            value: GeneralUtitilityService.replaceSpacesWithHyphens("Notes"),
            label: "Notes",
            component: <Notes />,
        },
        {
            value: GeneralUtitilityService.replaceSpacesWithHyphens(
                "Recruitment Processes"
            ),
            label: "Recruitment Processes",
            component: <RecruitmentProcesses />,
        },
        {
            value: GeneralUtitilityService.replaceSpacesWithHyphens("History"),
            label: "History",
            component: <History />,
        },
    ];

    return (
        <Box
            sx={{
                background: "inherit",
                ...parentStyles,
            }}
        >
            <TabContext value={`${activeTab}`}>
                <Box
                    px={4}
                    sx={{
                        background: "white",
                        width: "100%",
                    }}
                >
                    <TabList
                        variant="scrollable"
                        onChange={handleChange}
                        aria-label="Candidate tabs"
                    >
                        {candidateTabsContent.map((item, index) => {
                            return (
                                <Tab
                                    sx={{
                                        textTransform: "none",
                                        fontSize: "1rem",
                                        fontWeight: "500",
                                    }}
                                    key={index}
                                    value={`${item.value}`}
                                    label={item.label}
                                />
                            );
                        })}
                    </TabList>
                </Box>

                <Divider />

                <Box
                    sx={{
                        height: "100%",
                        minHeight: "60vh",
                        width: "100%",
                        backgroundColor: "#F8F8F8",
                    }}
                >
                    {candidateTabsContent.map((item, index) => {
                        return (
                            <TabPanel key={index} value={`${item.value}`}>
                                {React.cloneElement(item.component, {
                                    activeTab: item.value,
                                })}
                            </TabPanel>
                        );
                    })}
                </Box>
            </TabContext>
        </Box>
    );
};

export default Tabs;
