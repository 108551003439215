import Slide from "@mui/material/Slide";

export const SlideForm = (props) => {
    const { activeStep, previousStep } = props.stepState;
    return (
        <Slide
            in={true}
            direction={activeStep > previousStep ? "left" : "right"}
            timeout={previousStep === null ? 0 : 800}
            mountOnEnter
            unmountOnExit
        >
            {props.children}
        </Slide>
    );
};

export default SlideForm;
