import { useState } from "react";
import axios from "utils/axiosConfig";
import { useDispatch } from "react-redux";
import { EnvService } from "infra/envs";
import { fetchCitiesOptions } from "reducers/candidate/citiesSlice";

export const useFetchCities = () => {
    const [citiesFetching, setCitiesFetching] = useState(false);
    const [allCitiesFetching, setAllCitiesFetching] = useState(false);
    const [fetchSuccessful, setFetchSuccessful] = useState(false);
    const dispatch = useDispatch();

    /**
     * Fetches the city options based on the country selected
     *
     * @param {number} countryID - The selected country id
     * @param {string} q - The city search query
     * @returns {void}
     */
    const fetchCities = async (countryID, q) => {
        const url =
            EnvService.getBackEndBaseUrl() +
            `/api/countries/${countryID}/cities?name=${q}`;
        setCitiesFetching(true);
        try {
            const response = await axios.get(url);
            setFetchSuccessful(true);
            dispatch(fetchCitiesOptions(response.data.data));
        } catch (error) {
            console.log(error);
        } finally {
            setCitiesFetching(false);
        }
    };

    /**
     * Fetches the city options based on the country selected
     * @param {string} q - The city search query
     * @returns {array} - array of cities
     */
    const fetchCitiesWithoutCountry = async (q) => {
        if (!q) return;
        const url = EnvService.getBackEndBaseUrl() + `/api/cities?name=${q}`;
        setAllCitiesFetching(true);
        try {
            const response = await axios.get(url);
            setFetchSuccessful(true);
            const responseData = response.data.data;
            return responseData && Array.isArray(responseData)
                ? responseData
                : [];
        } catch (error) {
            console.log(error);
        } finally {
            setAllCitiesFetching(false);
        }
    };

    return {
        fetchCities,
        fetchCitiesWithoutCountry,
        citiesFetching,
        allCitiesFetching,
        fetchSuccessful,
    };
};

export default useFetchCities;
