import { Avatar } from "@mui/material";
import GeneralUtilityService from "services/GeneralUtilityService";

/**
 * UserAvatar Component
 * Displays an avatar for a user. If an image (src) is provided, it will display the image.
 * Otherwise, it will display the initials of the provided text with a background color.
 *
 * @param {string} src - URL of the user's image.
 * @param {string} name - Text used to generate initials and background color if no image is provided.
 * @param {...object} props - Additional props for the Avatar component.
 */
export const UserAvatar = ({ src, name, ...props }) => {
    // Get initials from the provided name
    const initials = GeneralUtilityService.getStringInitals(name);

    // Determine background color based on the image and name
    const backgroundColor = src || GeneralUtilityService.stringToColor(name);

    return (
        <Avatar
            alt={`${name}'s avatar`}
            src={src}
            loading="lazy"
            sx={{
                width: 35,
                height: 35,
                backgroundColor: backgroundColor,
            }}
            {...props}
        >
            {initials}
        </Avatar>
    );
};

export default UserAvatar;
