import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { MenuItem, ListItemText, ListItemIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const LinkButton = ({ to, text, onClick, icon, activeIcon = icon }) => {
    const theme = useTheme();
    const currentLocation = useLocation().pathname;
    const isActive = to === currentLocation;

    return (
        <Link onClick={onClick} to={to} key={to}>
            <MenuItem>
                <ListItemIcon
                    sx={{
                        color: isActive && theme.palette.primary.main,
                    }}
                >
                    {isActive ? activeIcon : icon}
                </ListItemIcon>

                <ListItemText
                    primaryTypographyProps={{
                        sx: {
                            color: isActive && theme.palette.primary.main,
                            transition: "all .3s",
                        },
                    }}
                >
                    {text}
                </ListItemText>
            </MenuItem>
        </Link>
    );
};

export default LinkButton;
