import { useEffect, useState } from "react";
import { ThemeProvider } from "@emotion/react";
import { Box, Stack } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import mainTheme from "assets/theme/MainTheme";
import rappLogo from "assets/images/rapp_logo_solid.svg";
import Content from "./Content";
import Loader from "./Loader";
import Main from "./Main";
import SearchBar from "./SearchBar";
import SidebarContainer from "./SidebarContainer";
import Top from "./Top";
import EnvIndicator from "./EnvIndicator";
import TopLogoContainer from "./TopLogoContainer";
import { Link } from "react-router-dom";
import ProfileMenuButton from "components/layout/profile-navigation/ProfileMenuButton";
import MainNavigation from "./navigation/MainNavigation";
import MenuButton from "components/layout/profile-navigation/MenuButton";
import { Dialog } from "@mui/material";
import { Login } from "features/login";
import { openModal, closeModal } from "reducers/authModal";
import useToggleHiddenInfo from "hooks/useToggleHiddenInfo";

/**
 * Wrapper component for the entire page.
 */
export const Layout = ({ children }) => {
    const dispatch = useDispatch();
    useToggleHiddenInfo();
    const appLoader = useSelector((state) => state.appLoader);
    const modalState = useSelector((state) => state.authModal?.showModal);
    const [isModalOpen, setIsModalOpen] = useState(modalState);

    const setModalOpen = () => {
        setIsModalOpen(true);
        dispatch(openModal());
    };

    const setModalClose = () => {
        setIsModalOpen(false);
        dispatch(closeModal());
    };

    useEffect(() => {
        if (modalState === true) {
            setModalOpen();
        }
        if (modalState === false) {
            setModalClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalState]);

    return (
        <ThemeProvider theme={mainTheme}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    width: "100%",
                    minHeight: "100vh",
                }}
            >
                <EnvIndicator currentEnv={window.location.hostname} />
                <Top>
                    <TopLogoContainer>
                        <Box
                            sx={{
                                pl: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Link to={`/`}>
                                <img
                                    src={rappLogo}
                                    alt="Rapp logo"
                                    style={{
                                        width: "100%",
                                        maxWidth: "160px",
                                        height: "auto",
                                        objectFit: "contain",
                                    }}
                                />
                            </Link>
                        </Box>
                    </TopLogoContainer>
                    <Stack
                        direction="row"
                        spacing={0}
                        px={3}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: "100%" }}
                    >
                        <SearchBar />
                        <Stack direction="row" spacing={2} alignItems="center">
                            <MenuButton text="Add new candidate" icon add />
                            <ProfileMenuButton />
                        </Stack>
                    </Stack>
                </Top>
                <Content>
                    <SidebarContainer>
                        <MainNavigation />
                    </SidebarContainer>
                    <Main>{appLoader ? <Loader /> : children}</Main>
                </Content>
            </Box>
            <Dialog open={isModalOpen} sx={{ height: "100vh" }}>
                <Login isModal />
            </Dialog>
        </ThemeProvider>
    );
};

export default Layout;
