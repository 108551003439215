import IconButton from "@mui/material/IconButton";

export const IconBtn = ({
    defaulted = false,
    id,
    text,
    icon: Icon,
    onClick,
    sx: parentStyles,
    iconStyles,
    ...props
}) => {
    return (
        <IconButton
            {...props}
            id={id}
            className="icon-btn"
            sx={{
                "&:hover svg": {
                    color: "inherit",
                },
                ...(defaulted
                    ? {}
                    : {
                          height: "35px",
                          width: "35px",
                          border: "1px solid",
                          borderColor: "gray.soft",
                          "&:hover": {
                              boxShadow: 3,
                              borderColor: "primary.main",
                          },
                      }),
                transition: "all .2s",
                ...parentStyles,
            }}
            onClick={onClick}
        >
            <Icon
                sx={{
                    fontSize: "1rem",
                    ...iconStyles,
                }}
            />
        </IconButton>
    );
};

export default IconBtn;
