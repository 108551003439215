import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

const steps = ["Basic Information", "Personal & Experience"];

export const FormStepper = (props) => {
    const { activeStep, navigate } = props.stepState;

    return (
        <Box component="nav" sx={{ p: 3, borderBottom: "1px solid lightgray" }}>
            <Box sx={{ maxWidth: "450px" }}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label} color="navy" completed={false}>
                            <StepButton
                                disabled
                                onClick={() => {
                                    navigate.to(index);
                                }}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </Box>
    );
};

export default FormStepper;
