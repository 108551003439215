import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MainNavigation from "./navigation/MainNavigation";
import { StorageService } from "infra/storage";
import IconButton from "@mui/material/IconButton";

const defaultSidebarState = StorageService.getSidebarOpenState();

export const SidebarContainer = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(defaultSidebarState);

    useEffect(() => {
        StorageService.setSidebarOpenState(isSidebarOpen);
    }, [isSidebarOpen]);

    const handleToggle = () => {
        setIsSidebarOpen((state) => !state);
    };

    return (
        <Box
            component="aside"
            sx={{
                display: "flex",
                flexDirection: "column",
                width: isSidebarOpen ? "100%" : "60px",
                maxWidth: "250px",
                minWidth: isSidebarOpen ? "200px" : "60px",
                position: "relative",
                transition: "all .3s ease-in-out",
            }}
        >
            <IconButton
                id="sidebar-toggler"
                sx={{
                    position: "absolute",
                    top: "22px",
                    right: 0,
                    transform: "translateX(50%)",
                    background: "white",
                    border: "unset",
                    zIndex: "1",
                    "&:hover": {
                        border: "1px solid",
                        borderColor: "gray.soft",
                        background: "white",
                    },
                    svg: {
                        fontSize: "1rem",
                    },
                }}
                size="small"
                color="primary"
                aria-label="Toggle sidebar"
                onClick={handleToggle}
            >
                {isSidebarOpen ? (
                    <ArrowBackIosIcon
                        sx={{
                            transform: "translateX(3px)",
                            transition: "all .5s ease-in-out",
                        }}
                    />
                ) : (
                    <ArrowBackIosIcon
                        sx={{
                            transform: "rotate(180deg) translateX(3px)",
                            transition: "all .3s",
                        }}
                    />
                )}
            </IconButton>

            <Box sx={{ overflow: "hidden" }}>
                <MainNavigation isSidebarOpen={isSidebarOpen} />
            </Box>
        </Box>
    );
};

export default SidebarContainer;
