import { Controller, useForm } from "react-hook-form";
import {
    Typography,
    Button,
    Stack,
    Autocomplete,
    TextField,
} from "@mui/material";
import { filters } from "reducers/linq-requests/linqRequestsSlice";
import { useDispatch } from "react-redux";
import { GlobalAppDataStorageService } from "infra/storage";

export const LinqFilters = () => {
    const { handleSubmit, control, register, reset } = useForm({
        defaultValues: {
            candidate_id: "",
            candidate_first_name: "",
            candidate_phone: "",
            candidate_email: "",
            company_id: "",
            company_name: "",
            employer_email: "",
            application_status: null,
        },
    });

    const dispatch = useDispatch();

    const onSubmit = (filterData) => {
        filterData = {
            ...filterData,
            linq_request_status_id: filterData?.application_status?.id,
        };
        dispatch(filters(filterData));
    };

    const handleDynamicSubmitForm = (event) => {
        event.preventDefault();
        handleSubmit(onSubmit)();
        event.target.blur();
        if (document.activeElement) document.activeElement.blur();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
                direction="column"
                px={3}
                py={3}
                spacing={2}
                sx={{ position: "relative" }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={6}
                >
                    <Typography variant="header1" component="h3">
                        Filters
                    </Typography>
                    <Button
                        sx={{
                            textTransform: "none",
                            padding: 0,
                        }}
                        variant="text"
                        onClick={(event) => {
                            reset();
                            handleDynamicSubmitForm(event);
                        }}
                    >
                        Clear all
                    </Button>
                    <Button sx={{ display: "none" }} type="submit"></Button>
                </Stack>

                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <TextField
                            {...register("candidate_id")}
                            name="candidate_id"
                            id="candidate_id"
                            label="Candidate ID"
                            onBlur={handleDynamicSubmitForm}
                            variant="standard"
                        />
                        <TextField
                            {...register("candidate_first_name")}
                            name="candidate_first_name"
                            id="candidate_first_name"
                            label="Candidate first name"
                            onBlur={handleDynamicSubmitForm}
                            variant="standard"
                        />
                        <TextField
                            {...register("candidate_last_name")}
                            name="candidate_last_name"
                            id="candidate_last_name"
                            label="Candidate last name"
                            onBlur={handleDynamicSubmitForm}
                            variant="standard"
                        />
                        <TextField
                            {...register("candidate_phone")}
                            name="candidate_phone"
                            id="candidate_phone"
                            label="Candidate phone"
                            type="text"
                            onBlur={handleDynamicSubmitForm}
                            variant="standard"
                        />
                        <TextField
                            {...register("candidate_email")}
                            name="candidate_email"
                            id="candidate_email"
                            type="email"
                            label="Candidate Email"
                            onBlur={handleDynamicSubmitForm}
                            variant="standard"
                        />
                        <TextField
                            {...register("company_id")}
                            name="company_id"
                            id="company_id"
                            label="Company ID"
                            onBlur={handleDynamicSubmitForm}
                            variant="standard"
                        />
                        <TextField
                            {...register("company_name")}
                            name="company_name"
                            id="company_name"
                            label="Company Name"
                            onBlur={handleDynamicSubmitForm}
                            variant="standard"
                        />
                        <TextField
                            {...register("employer_email")}
                            name="employer_email"
                            id="employer_email"
                            label="Employer email"
                            onBlur={handleDynamicSubmitForm}
                            variant="standard"
                        />
                    </Stack>

                    <Controller
                        name="application_status"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={
                                    GlobalAppDataStorageService.getLinqStatuses() ||
                                    []
                                }
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(event, value) => {
                                    field.onChange(value);
                                    handleDynamicSubmitForm(event);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Status"
                                        variant="standard"
                                    />
                                )}
                            />
                        )}
                    />
                </Stack>
            </Stack>
        </form>
    );
};

export default LinqFilters;
