import { useEffect } from "react";
import { Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TopSection from "./TopSection";
import Tabs from "./Tabs";
import { useRecruitmentProcess } from "hooks/useRecruitmentProcess";
import { useEducation } from "hooks/useEducation";
import { useExperiences } from "hooks/useExperiences";
import { useHistory } from "hooks/useHistory";
import { useSelector } from "react-redux";

export const View = ({ candidateId, isInDrawer }) => {
    const version = useSelector((state) => state.currentCandidate?.version);

    const { fetchExperiences } = useExperiences();
    const { getRecruitmentProcesses } = useRecruitmentProcess();
    const theme = useTheme();
    const { getEducations } = useEducation();
    const { getCandidateHistory } = useHistory();

    useEffect(() => {
        getRecruitmentProcesses(candidateId);
        getEducations(candidateId);
        fetchExperiences(candidateId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candidateId]);

    useEffect(() => {
        if (version) {
            getCandidateHistory(candidateId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [version]);

    return (
        <Box
            sx={{
                background: theme.palette.gray.light,
                height: "100%",
            }}
        >
            <TopSection
                sx={{
                    padding: "1rem 2rem",
                    width: "100%",
                    background: "white",
                }}
                isInDrawer={isInDrawer}
            />
            <Divider />
            <Tabs />
        </Box>
    );
};

export default View;
