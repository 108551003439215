import { createSlice } from "@reduxjs/toolkit";

const recruitersSlice = createSlice({
    name: "recruiters",
    initialState: {
        isLoading: false,
        data: null,
    },

    reducers: {
        recruitersAccounts: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { recruitersAccounts } = recruitersSlice.actions;
export default recruitersSlice.reducer;
